/*
    Sangbleu Empire
  */

@font-face {
  font-family: 'sangbleu-empire-black';
  src: url('./sangbleu-empire/sangbleuempire-black.woff2') format('woff2');
  src: url('./sangbleu-empire/sangbleuempire-black.woff') format('woff');
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: 'sangbleu-empire-black-italic';
  src: url('./sangbleu-empire/sangbleuempire-black-italic.woff2') format('woff2');
  src: url('./sangbleu-empire/sangbleuempire-black-italic.woff') format('woff');
  font-weight: bolder;
  font-style: italic;
}
