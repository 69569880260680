@import 'component-library/dist/scss/alloy';
.bottom-bar-section {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 1.5rem 0;
  background-color: $WHITE_COLOR;
  z-index: $BAR_Z_INDEX;
  border-top: 1px solid $PRIMARY_BORDER_COLOR;

  @include media-breakpoint-down(md) {
    padding: toRem(14) 0;
  }

  .rs-uploader-trigger-btn {
    font-family: $FONT_FUTURA_BOLD;
    font-weight: 700 !important;
    font-size: 0.8125rem !important;
    letter-spacing: 0.09rem !important;
    padding: 1rem 2rem !important;
    text-transform: uppercase;
    transition: 0.2s ease-out all;
    background-color: $SECONDARY_ORANGE_COLOR;
    color: $WHITE_COLOR;

    border: none;
    border-radius: 0;

    &:after {
      content: ' a photo';
    }

    @include media-breakpoint-down(md) {
      &:after {
        content: ' or take a photo';
      }
    }

    &:hover {
      background-color: $SECONDARY_ORANGE_HOVER_COLOR;
      color: $WHITE_COLOR;
    }

    @include media-breakpoint-down(md) {
      padding: 1.5rem 2rem !important;
      width: 100%;
    }
  }

  .buttons-list-wrapper {
    display: flex;
    justify-content: center;

    .primary-button {
      &:last-child {
        margin-left: 1rem;
      }
    }
  }
}
