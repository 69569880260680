.required-field {
  text-transform: capitalize;
  font-weight: normal;
  font-size: 1rem;
  font-family: $FONT_FUTURA_BOOK;
}

.required-field-error {
  text-transform: capitalize;
  font-weight: normal;
  font-size: 1rem;
  font-family: $FONT_FUTURA_BOOK;
  color: $RED_COLOR;
}
