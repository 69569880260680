@import 'component-library/dist/scss/alloy';
.footer-bg {
  max-width: 100%;
  background-color: $PRIMARY_DARK_BLUE_COLOR;

  .content-divider {
    margin: toRem(24) 0;
    border: 0.5px solid rgba(255, 255, 255, 0.875);
  }

  @include media-breakpoint-down(md) {
    .col-6 {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
      padding: 10px 30px;
      margin: 0 !important;
    }
  }
}

.footer-subtitle {
  font-size: 0.85rem;
  font-weight: 600;
  color: #7b815a;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.footer-links {
  padding: 0;
  list-style: none;

  li {
    display: block;

    a {
      display: block;
      font-size: 1rem;
      text-transform: uppercase;
      padding: 5px 0;
      letter-spacing: 0.8px;
      color: rgba(255, 255, 255, 0.875);

      &:hover {
        color: $WHITE_COLOR;
      }
    }
  }
}

.copyright {
  font-size: toRem(12);
}

.footer-copyright {
  color: rgba(255, 255, 255, 0.875) !important;
  font-size: 1rem;
  margin: 0;

  a {
    cursor: pointer;
  }

  &:hover {
    color: $WHITE_COLOR;
  }
}

.social {
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  gap: 1.5rem;

  .btn-icon {
    padding: 0;
    display: block;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }

  @include media-breakpoint-down(md) {
    float: none !important;
    justify-content: center;
    padding-top: 20px;

    li {
      margin: 0 10px !important;
    }
  }
}
