@import 'component-library/dist/scss/alloy';
// Variables for padding
$padding-desktop: toRem(24);
$padding-tablet: 1.5rem;
$padding-mobile-large: 4rem;
$padding-mobile-small: 1rem;

// Mixin for responsive padding
@mixin responsive-padding($top: 0, $right: $padding-desktop, $bottom: 0, $left: $padding-desktop) {
  padding: $top $right $bottom $left;

  @include media-breakpoint-down(md) {
    padding: $top $padding-tablet $bottom $padding-tablet;
  }
  @include media-breakpoint-down(sm) {
    padding: $top $padding-mobile-large $bottom $padding-mobile-large;
  }
  @include media-breakpoint-down(xs) {
    padding: $top $padding-mobile-small $bottom $padding-mobile-small;
  }
}

.tp-review {
  margin: 0;
  padding: 0;
  text-align: left;
  background-color: $PRIMARY_DARK_BLUE_COLOR;
  color: $WHITE_COLOR;
  border: none;
  border-color: $WHITE_COLOR;

  &.light {
    background-color: $WHITE_COLOR !important;
    color: $PRIMARY_DARK_BLUE_COLOR !important;
    border-color: $PRIMARY_BORDER_COLOR;
    margin: 1.5rem 0;
  }

  .trustpilot-widget {
    @include responsive-padding($top: toRem(12));
    border-bottom: 1px solid;
    border-color: inherit;

    .tp-widget-wrapper {
      margin: 0;
    }
  }

  .review-title {
    @include responsive-padding($top: toRem(16), $bottom: toRem(8));
    font-family: $FONT_FUTURA_MEDIUM;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    font-size: 1.5rem;
    margin: 0;
    color: inherit;
  }

  .review-body {
    @include responsive-padding;
    font-family: $FONT_FUTURA_LIGHT;
    font-weight: 500;
    color: inherit;
  }

  .review-source {
    @include responsive-padding($bottom: $padding-desktop);
    display: flex;
    flex-direction: row;
    gap: toRem(6);
    line-height: 1;
    font-weight: 500;
    color: inherit;

    a {
      text-decoration: underline;
      color: inherit;
    }
  }
}
