@import 'component-library/dist/scss/alloy';
.loader-section {
  padding-top: 8rem;
  padding-bottom: 4rem;
  min-height: 100vh;
  background-color: inherit;

  @include media-breakpoint-down(md) {
    padding-top: 100px;

    &.sm {
      padding-top: 0;
    }
  }

  &.sm {
    padding: 2rem;
    min-height: 0;
  }
}
