.ce-single-page-product-perks-wrapper {
  background-color: $PRIMARY_LIGHT_BLUE_COLOR;
  padding: 22px 0;

  .perks-list {
    color: $PRIMARY_DARK_BLUE_COLOR;
    display: flex;
    justify-content: space-between;
  }

  .product-perk {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 128px;

    .perk-text {
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      line-height: 16px;
    }
  }

  @include media-breakpoint-up(sm) {
    padding: 24px 0;

    .perks-list {
      margin: 0 auto;
      max-width: 580px;
    }

    .product-perk {
      max-width: 110px;

      .perk-text {
        font-size: 16px;
        line-height: 23px;
      }
    }
  }
}
