@import 'component-library/dist/scss/alloy';
.header-state-block {
  position: relative;

  background-color: $WHITE_COLOR;
  padding: toRem(40);

  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: toRem(64);

  &.highlight-border {
    border: 1px solid $SECONDARY_ORANGE_COLOR;
  }

  .state-content-wrapper {
    .content-full-img {
      width: 100%;

      margin-bottom: toRem(24);
    }

    .content-hug-wrapper {
      display: flex;
      align-items: center;
      max-width: fit-content;

      background-color: $SECONDARY_LIGHT_BLUE_COLOR;
      border-radius: 4px;

      margin-bottom: toRem(24);
      padding: 0.25rem 0.5rem;

      .hug-text {
        font-family: $FONT_FUTURA_BOLD;
        font-weight: 700;
        font-size: toRem(12);
        text-transform: uppercase;

        color: $PRIMARY_DARK_BLUE_COLOR;
      }

      .hug-alert-icon {
        background-color: $SECONDARY_ORANGE_COLOR;

        width: 8px;
        height: 8px;
        border-radius: 50%;

        margin-left: toRem(8);
      }

      &.hug-required {
        background-color: $PRIMARY_DARK_BLUE_COLOR;

        .hug-text {
          color: $WHITE_COLOR;
        }
      }
    }

    .content-title {
      font-family: $FONT_SANGBLEU_BOLD;
      font-weight: 700;
      font-size: toRem(48);
      line-height: toRem(64);

      word-break: break-word;

      color: $PRIMARY_DARK_BLUE_COLOR;

      margin-bottom: toRem(8);
      padding: 0;

      @include media-breakpoint-down(sm) {
        font-size: toRem(40);
        line-height: toRem(56);
      }
    }

    .content-subtitle {
      font-family: $FONT_FUTURA_BOLD;
      font-weight: 600;
      font-size: toRem(22);
      line-height: toRem(26);

      color: $PRIMARY_DARK_BLUE_COLOR;

      margin-bottom: toRem(24);
      padding: 0;
    }

    .content-text {
      font-size: toRem(18);

      color: $PRIMARY_DARK_BLUE_COLOR;

      margin: 0;
      padding: 0;
    }

    .content-list-wrapper {
      margin: 0 0 1.5rem;
      padding-inline-start: 20px;

      .list-text {
        font-size: toRem(18);

        color: $PRIMARY_DARK_BLUE_COLOR;
      }
    }

    .progress-block {
      margin: 1.5rem 0 0;
      padding: 0;

      .progress-bar-text-wrapper {
        .bar-text {
          font-size: toRem(14);
        }
      }
    }

    .content-inner-wrapper {
      max-width: 85%;
      margin-top: toRem(16);

      @include media-breakpoint-down(md) {
        max-width: 100%;
      }
    }
  }

  .state-icon-wrapper {
    display: block;
    justify-content: flex-end;

    margin-right: 1.5rem;

    .content-icon {
      width: 125px;

      max-width: 100%;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .state-products-wrapper {
    display: flex;
    justify-content: flex-end;

    flex-wrap: nowrap;
    gap: 1rem;
    flex: 1;

    .state-product {
      width: 175px;
      max-width: 100%;

      display: inline-block;

      .product-skeleton {
        width: 175px;
        height: 175px;

        @include media-breakpoint-down(md) {
          width: 125px;
          height: 125px;
        }
      }

      .product-expired-text {
        font-family: $FONT_FUTURA_BOLD;
        font-weight: bold;
        font-size: toRem(14);
        line-height: 24px;

        text-align: center;

        background-color: $PRIMARY_DARK_BLUE_COLOR;
        color: $WHITE_COLOR;

        margin: 0;
        padding: 0;
      }

      .product-img {
        width: 175px;
        height: 175px;
        object-fit: cover;

        @include media-breakpoint-down(md) {
          width: 90px;
          height: 90px;
        }
      }

      .product-text {
        font-family: $FONT_FUTURA_BOOK;
        font-weight: normal;
        font-size: toRem(20);
        line-height: toRem(28);

        text-align: center;

        margin: toRem(8) 0 0;
        padding: 0;
      }

      &.product-extra {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;

        width: 175px;
        height: 175px;

        background-color: $SECONDARY_LIGHT_BLUE_COLOR;

        .product-text {
          font-family: $FONT_FUTURA_MEDIUM;
          font-weight: 500;
          font-size: toRem(22);
          line-height: toRem(26);

          color: $PRIMARY_DARK_BLUE_COLOR;

          margin: 0;
          padding: 0;
        }

        @include media-breakpoint-down(md) {
          width: 90px;
          height: 90px;
        }
      }

      @include media-breakpoint-down(md) {
        margin: 0;
        max-width: 90px;
      }
    }

    @include media-breakpoint-down(md) {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      margin: toRem(24) 0;
      gap: toRem(8);
    }
  }

  .state-close-btn {
    position: absolute;
    top: toRem(40);
    right: toRem(40);

    display: inline-flex;

    background-color: inherit;

    padding: 0;

    .close-icon {
      width: 15px;
      height: 15px;
    }

    @include media-breakpoint-down(md) {
      top: toRem(24);
      right: toRem(24);
    }
  }

  @include media-breakpoint-down(md) {
    display: block;

    padding: toRem(24);

    .primary-button,
    .secondary-border-button {
      width: 100%;
    }
  }
}
