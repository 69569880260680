@import 'component-library/dist/scss/alloy';
.notif-row {
  border-bottom: 1px solid $PRIMARY_BORDER_COLOR;
  padding-bottom: 8px;
  font-family: $FONT_FUTURA_BOOK;

  &:hover {
    cursor: pointer;
  }

  &:last-of-type {
    border-bottom: none;
  }

  .notif-top-line {
    display: flex;
    justify-content: space-between;
    margin: 12px 0 4px 0;
  }

  .notif-info {
    display: flex;
    align-items: center;

    .notif-message {
      font-size: 1rem;
      color: $PRIMARY_TEXT_COLOR;
      line-height: 26px;
      margin: 0;
    }

    .notif-cta {
      text-decoration: underline;
      font-size: 1rem;
      line-height: 20px;
      margin: 0 8px;
    }
  }

  &:first-of-type {
    .notif-title,
    .notif-cta {
      margin-top: 0;
    }
  }

  .time-from-now {
    font-size: 0.875rem;
    color: $PRIMARY_DARK_GREY_COLOR;
  }

  .count-badge {
    padding: 0 4px;
    margin: 0 8px;
    border-radius: 2px;
    background-color: $PRIMARY_DARK_GREEN_COLOR;
    color: $WHITE_COLOR;
    font-size: 1rem;
    text-wrap: nowrap;
  }
}

.empty-notif {
  font-size: 1rem;
  font-family: $FONT_FUTURA_BOOK;
  color: $PRIMARY_TEXT_COLOR;
  line-height: 26px;
  margin-top: 8px;
}
