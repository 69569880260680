@import 'component-library/dist/scss/alloy';
.manage-discount-wrapper {
  .discount-link {
    color: $PRIMARY_TEXT_COLOR;
    background-color: inherit;

    font-size: toRem(16);
    line-height: toRem(24);

    text-decoration-line: underline;
    margin: 0;
    padding: 0;
  }

  .discount-content {
    display: flex;
    align-items: center;

    .discount-close-btn {
      background-color: inherit;
      margin-right: toRem(16);

      .close-icon {
        width: toRem(16);
        height: toRem(16);
      }
    }

    .discount-text {
      font-size: toRem(18);
      line-height: toRem(24);

      color: $PRIMARY_TEXT_COLOR;

      margin: 0;
      padding: 0;
    }

    .dicount-input-wrapper {
      flex: 1;

      form {
        display: flex !important;

        .discount-input {
          text-transform: uppercase;
          height: 100%;
          border-radius: 0;
        }
      }
    }
  }

  .MuiCircularProgress-root {
    color: $PRIMARY_TEXT_COLOR;
  }
}
