@import 'component-library/dist/scss/alloy';
.messaging-content-block {
  height: 375px;

  overscroll-behavior: contain;
  overflow: auto;

  display: flex;
  flex-direction: column-reverse;
  gap: toRem(24);

  padding: toRem(32);

  @include media-breakpoint-down(sm) {
    padding: toRem(20) toRem(12);

    overscroll-behavior: unset;
  }

  .message-bubble-section {
    display: flex;
    justify-content: flex-start;

    &.patient-message {
      justify-content: flex-end;
    }
  }

  .message-empty-wrapper {
    margin: auto;

    .empty-title {
      font-size: toRem(24);
      line-height: toRem(28);

      color: $PRIMARY_TEXT_COLOR;

      margin: 0 0 toRem(6);
      padding: 0;

      text-align: center;
    }

    .empty-text {
      font-size: toRem(18);
      line-height: toRem(24);

      color: $PRIMARY_DARK_GREY_COLOR;

      margin: 0;
      padding: 0;

      text-align: center;
    }
  }
}
