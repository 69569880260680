.order-confirmation-section {
  background-color: $SECONDARY_LIGHT_BLUE_COLOR;
  padding: toRem(24) 0;

  &.extra-bottom-padding {
    padding: 1.5rem 0 6rem;

    @include media-breakpoint-down(md) {
      padding: 1.5rem 0;
    }
  }

  .confirmation-text {
    color: $PRIMARY_TEXT_COLOR;

    &.text-bold,
    span {
      font-family: $FONT_FUTURA_MEDIUM;
      font-weight: 500;

      text-decoration: none;
    }
  }
}
