@import 'component-library/dist/scss/alloy';
.ce-summary-wrapper {
  background-color: $WHITE_COLOR;
  border: 1px solid $PRIMARY_BORDER_COLOR;
  padding: 2rem 4rem;

  margin: 0 0 1.5rem;

  .ce-wrapper-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $PRIMARY_BORDER_COLOR;

    padding: 0 0 1rem;

    .header-title {
      color: $BLACK_COLOR;
      font-family: $FONT_FUTURA_BOLD;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 1rem;

      margin: 0;
      padding: 0;
    }
  }

  // TOTALS
  .ce-totals-block {
    color: $PRIMARY_TEXT_COLOR;

    .ce-total-summary-wrapper {
      padding: 0.5rem 0 0;

      .total-summary-row {
        display: flex;
        justify-content: space-between;

        margin: 0 0 0.5rem;

        .total-summary-title-bold {
          font-family: $FONT_FUTURA_MEDIUM;
          font-weight: 500;
          font-size: 1.125rem;

          margin: 0;
          padding: 0;

          span {
            font-family: $FONT_FUTURA_BOOK;
            font-weight: 400;
            font-size: 1rem;
          }
        }

        .total-price-text {
          font-family: $FONT_FUTURA_MEDIUM;
          font-weight: 500;
          font-size: toRem(18);

          margin: 0;
          padding: 0;
        }

        .total-summary-title {
          margin: 0;
          padding: 0;
        }

        .total-summary-content {
          font-family: $FONT_FUTURA_MEDIUM;
          font-weight: 500;
          font-size: 1rem;

          margin: 0;
          padding: 0;
        }

        .total-summary-content-tax {
          font-family: $FONT_FUTURA_MEDIUM;
          font-weight: 500;
          font-size: 1rem;

          color: rgba($PRIMARY_TEXT_COLOR, 0.5);

          margin: 0;
          padding: 0;
        }
      }
    }

    .ce-total-wrapper {
      display: flex;
      justify-content: space-between;

      margin: 0 0 0.5rem;

      .total-title {
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 500;
        font-size: 1.125rem;

        margin: 0;
        padding: 0;
      }

      .total-amount {
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 500;
        font-size: 1.125rem;

        margin: 0;
        padding: 0;
      }

      &.top-padding {
        padding: 0.5rem 0 0;
      }

      &.with-loader {
        padding: 1rem 0;

        .rs-loader-wrapper {
          margin: 0 auto;
        }
      }
    }

    .primary-button {
      width: 100%;
    }
  }

  @include media-breakpoint-down(xs) {
    padding: 1.5rem 1rem;

    border-left: none;
    border-right: none;
  }
}
