@import 'component-library/dist/scss/alloy';
.re-shipping-method-block {
  .re-shipping-method-wrapper {
    padding: 0.5rem 0 1rem;

    cursor: pointer;

    &.re-hide-border {
      @include media-breakpoint-down(md) {
        padding-bottom: 0.5rem;
        border-bottom: none;
      }
    }

    .shipping-content-header {
      .shipping-title {
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 500;
        font-size: 1.125rem;

        margin: 0 0 0.5rem;
        padding: 0;
      }

      .shipping-chevron {
        background-color: inherit;
        text-align: right;
        transition: 0.6s ease;
        width: toRem(10);
      }
    }

    .shipping-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      min-height: toRem(40);
      padding: toRem(10);

      &.closed {
        border: 1px solid $PRIMARY_BORDER_COLOR;
      }

      &.open {
        border-top: 1px solid $PRIMARY_BORDER_COLOR;
      }

      .shipping-text {
        font-size: 1rem;

        margin: 0;
        padding: 0;
      }

      .shipping-text-bold {
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 500;
        font-size: 1rem;

        text-transform: uppercase;

        margin: 0;
        padding: 0;
      }

      .shipping-dropdown {
        display: flex;
        gap: toRem(10);
      }
    }
  }

  .re-shipping-options-wrapper {
    .shipping-header-title {
      color: $BLACK_COLOR;
      font-family: $FONT_FUTURA_BOLD;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 1rem;

      margin: 1rem 0;
      padding: 0;
    }

    &.shipping-method-options {
      display: none;

      border-bottom: 1px solid $PRIMARY_BORDER_COLOR;
    }

    .shipping-option {
      display: flex;
      align-items: center;

      border-radius: 0.375rem;

      margin-bottom: 1rem;

      cursor: pointer;

      .option-content {
        flex: 1;
        display: flex;
        margin: 0 0 0 0.5rem;
        justify-content: space-between;

        .option-title {
          font-size: 1rem;
          line-height: 1.625rem;

          margin: 0;
          padding: 0;

          .next-shipment-discount-tag {
            background: $PRIMARY_DARK_BLUE_COLOR;
            color: $WHITE_COLOR;
            padding: toRem(5);
            margin-left: toRem(10);
            text-wrap: nowrap;
          }
        }

        .option-price {
          font-family: $FONT_FUTURA_MEDIUM;
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.625rem;
          text-transform: uppercase;

          margin: 0;
          padding: 0;
        }
      }

      .option-outer-circle {
        width: 1.5rem;
        height: 1.5rem;
        border: 1px solid $SECONDARY_BORDER_COLOR;
        border-radius: 50%;
      }

      &.selected {
        .option-outer-circle {
          width: 1rem;
          height: 1rem;

          border: 1px solid $SECONDARY_ORANGE_COLOR;
          background-color: $SECONDARY_ORANGE_COLOR;

          box-shadow:
            0px 0px 0px 4px $WHITE_COLOR,
            0px 0px 0px 6px $SECONDARY_ORANGE_COLOR;

          position: relative;
          left: 0.25rem;
        }

        .option-content {
          position: relative;

          padding-left: 0.5rem;
        }
      }
    }
  }

  &.re-active-shipping {
    .re-shipping-method-wrapper {
      border-bottom: none;

      .shipping-chevron {
        transform: rotate(180deg);
      }
    }

    .re-shipping-options-wrapper {
      display: block;
    }
  }
}
