@import 'component-library/dist/scss/alloy';
.message-bubble-wrapper {
  max-width: 70%;
  word-wrap: break-word;

  display: flex;
  align-items: flex-end;

  gap: toRem(12);

  @include media-breakpoint-down(sm) {
    max-width: 95%;
  }

  .message-bubble-avatar {
    width: 44px;
    height: 44px;

    border-radius: 50%;
  }

  .message-bubble-block {
    word-wrap: break-word;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: toRem(8);

    .message-bubble-text-content {
      background-color: $PRIMARYT_LIGHT_GREY_COLOR;
      color: $PRIMARY_TEXT_COLOR;

      padding: toRem(12);

      font-size: toRem(18);

      text-align: left;
      max-width: 100%;
      word-break: break-word;

      .text-content-markdown {
        p,
        span {
          margin: 0;
          padding: 0;
        }

        a {
          color: $WHITE_COLOR;

          text-decoration: underline;
        }
      }
    }

    .message-bubble-file-block {
      display: grid;
      gap: toRem(8);

      .file-content-link {
        max-height: 150px;

        color: $PRIMARY_TEXT_COLOR;

        .content-link {
          margin-left: toRem(6);

          @include hover-focus {
            text-decoration: underline;
          }
        }

        .content-img {
          height: 100%;
        }
      }

      .file-content-audio {
        background-color: $PRIMARYT_LIGHT_GREY_COLOR;

        max-width: 300px;
      }

      .file-content-video {
        background-color: $PRIMARYT_LIGHT_GREY_COLOR;

        max-width: 175px;
      }
    }

    .message-bubble-text {
      font-size: toRem(14);
      line-height: toRem(20);
    }
  }

  &.patient-bubble {
    .message-bubble-block {
      align-items: end;
      gap: toRem(4);

      .message-bubble-text-content {
        background-color: $PRIMARY_LIGHT_BLUE_COLOR;
        color: $PRIMARY_TEXT_COLOR;

        border-top-left-radius: toRem(12);
        border-top-right-radius: toRem(12);
        border-bottom-left-radius: toRem(12);
      }

      .message-bubble-file-block {
        .file-content-link {
          .content-img {
            border-top-left-radius: toRem(12);
            border-top-right-radius: toRem(12);
            border-bottom-left-radius: toRem(12);
          }
        }

        .file-content-audio {
          border-top-left-radius: toRem(12);
          border-top-right-radius: toRem(12);
          border-bottom-left-radius: toRem(12);
        }

        .file-content-video {
          border-top-left-radius: toRem(12);
          border-top-right-radius: toRem(12);
          border-bottom-left-radius: toRem(12);
        }
      }

      .message-bubble-text {
        text-align: right;
        white-space: nowrap;
      }
    }
  }

  &.other-bubble {
    .message-bubble-block {
      .message-bubble-text-content {
        background-color: $PRIMARY_DARK_GREEN_COLOR;
        color: $WHITE_COLOR;

        border-top-left-radius: toRem(12);
        border-top-right-radius: toRem(12);
        border-bottom-right-radius: toRem(12);

        .file-content-link {
          color: $WHITE_COLOR;
        }
      }

      .message-bubble-file-block {
        .file-content-link {
          .content-img {
            border-top-left-radius: toRem(12);
            border-top-right-radius: toRem(12);
            border-bottom-right-radius: toRem(12);
          }
        }

        .file-content-audio {
          border-top-left-radius: toRem(12);
          border-top-right-radius: toRem(12);
          border-bottom-right-radius: toRem(12);
        }

        .file-content-video {
          border-top-left-radius: toRem(12);
          border-top-right-radius: toRem(12);
          border-bottom-right-radius: toRem(12);
        }
      }

      .message-bubble-text {
        text-align: left;
        word-wrap: break-word;
      }
    }
  }
}
