@import 'component-library/dist/scss/alloy';
.messaging-header-block {
  display: grid;
  grid-template-columns: 3fr 1fr;

  padding: toRem(32) toRem(32) toRem(16);

  align-items: center;

  border-bottom: 1px solid $PRIMARY_CARD_BORDER_COLOR;

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;

    gap: toRem(16);

    padding: toRem(12);
  }

  .doctor-info-block {
    display: flex;
    gap: toRem(24);

    .doctor-photo {
      width: 64px;
      height: 64px;

      border-radius: 50%;
    }

    .doctor-content {
      .content-title {
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 500;
        font-size: toRem(16);
        line-height: toRem(24);

        color: $PRIMARY_TEXT_COLOR;

        margin: 0;
        padding: 0;
      }

      .content-subtitle {
        font-family: $FONT_FUTURA_BOLD;
        font-weight: bold;
        font-size: toRem(18);
        line-height: toRem(26);

        color: $PRIMARY_TEXT_COLOR;

        margin: 0;
        padding: 0;
      }

      .content-text {
        font-family: $FONT_FUTURA_BOOK;
        font-weight: normal;
        font-size: toRem(18);
        line-height: toRem(26);

        color: $PRIMARY_TEXT_COLOR;

        border-top: 1px solid $WHITE_COLOR;
        border-radius: toRem(4);

        margin: 0;
        padding: 0;
      }
    }
  }

  .actions-block {
    .actions-text {
      color: $PRIMARY_TEXT_COLOR;
      font-size: toRem(16);

      text-align: right;

      margin: 0;

      .text-link {
        color: $PRIMARY_TEXT_COLOR;

        text-decoration: underline;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        text-align: left;
      }
    }
  }
}
