@import 'component-library/dist/scss/alloy';
.order-summary-block {
  background-color: $WHITE_COLOR;
  border: 1px solid $SECONDARY_BORDER_COLOR;

  padding: 1.5rem;

  .summary-title {
    font-family: $FONT_FUTURA_BOLD;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1rem;
    color: $PRIMARY_TEXT_COLOR;

    margin: 0 0 1rem;
    padding: 0;
  }

  .summary-text {
    font-size: 1rem;

    margin: 0;
    padding: 0;

    .summary-checkmark {
      height: 1.25rem;
      width: 1.25rem;
      margin-right: 0.5rem;
      margin-bottom: 0.25rem;
    }

    &.bottom-padding {
      margin: 0 0 1rem;
    }
  }

  .summary-products {
    .summary-products-wrapper {
      display: flex;
      justify-content: space-between;

      margin: toRem(8) 0;

      .summary-product-with-badge {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
      }

      .summary-product-text {
        font-size: toRem(18);
        color: $PRIMARY_TEXT_COLOR;

        margin: 0;
        padding: 0;
      }

      .summary-product-img {
        width: 16px;
        height: 16px;
      }

      .summary-product-price-text {
        font-size: toRem(18);
        color: $PRIMARY_TEXT_COLOR;

        margin: 0;
        padding: 0;
      }

      .summary-text-orange {
        color: $SECONDARY_ORANGE_COLOR;
      }
    }
  }

  .messaging-info-banner {
    background-color: $PRIMARY_LIGHT_BLUE_COLOR;
    color: $PRIMARY_TEXT_COLOR;
    font-size: toRem(18);

    padding: toRem(8) toRem(16);
    margin-bottom: toRem(16);
  }

  @include media-breakpoint-up(md) {
    .show-mobile-border {
      margin: 0;
      padding: 0 0 1rem;

      border-bottom: 1px solid $PRIMARY_BORDER_COLOR;
    }
  }

  @include media-breakpoint-down(sm) {
    .summary-text {
      .btn-primary {
        display: none;
      }
    }
  }

  .btn-primary {
    width: 100%;

    margin: 0 0 0.5rem;
  }
}
