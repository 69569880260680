@import 'component-library/dist/scss/alloy';
.subscriptions-action-btns-content {
  display: flex;
  gap: toRem(16);

  @include media-breakpoint-down(md) {
    button {
      padding: 0.75rem 1rem;
    }
  }

  @include media-breakpoint-down(sm) {
    display: grid;
  }
}
