@import 'component-library/dist/scss/alloy';
.bundled-savings-block {
  background-color: $PRIMARY_LIGHT_BLUE_COLOR;

  margin: 0 0 toRem(16);
  padding: toRem(12) toRem(8);

  .bundled-savings-text {
    font-family: $FONT_FUTURA_MEDIUM;
    font-weight: 500;

    color: $PRIMARY_TEXT_COLOR;

    margin: 0;
    padding: 0;
  }
}
