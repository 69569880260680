.rs-toast-container {
  z-index: 100050;
}

.MuiAlert-root {
  padding: 1rem 1.5rem !important;

  @include media-breakpoint-down(md) {
    padding: 6px 16px !important;
  }
}

.MuiAlert-standard {
  + .MuiAlert-standard {
    margin-top: 1rem;
  }
}

.rs-message {
  padding: 0;
  min-width: 75%;

  @include media-breakpoint-down(md) {
    padding: 0;
    margin: 1rem;
    min-width: 55%;
  }

  .rs-message-container {
    padding: 0;
  }
}

.MuiAlert-message {
  font-family: $FONT_FUTURA_MEDIUM;
  font-size: 1rem;
  font-weight: 500;
}
