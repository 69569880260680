@import 'component-library/dist/scss/alloy';
.assessment-top-title {
  font-size: 1.1rem;
  font-family: $FONT_FUTURA_BOLD;
  margin: 0.75rem 0 0.5rem;
}

.assessment-wrapper {
  color: $PRIMARY_TEXT_COLOR !important;
  padding: 0 2.5rem;

  @include media-breakpoint-down(md) {
    padding: 0 1rem;
  }

  &.bg-white {
    padding: 2rem 4rem;
    background-color: $WHITE_COLOR;
  }

  .assessment-group {
    padding: 2rem 4rem;
    margin: 0.5rem 0 1.5rem;
    background-color: $WHITE_COLOR;
  }

  .assessment-group:not(:first-child) {
    margin-top: 1.5rem;
  }

  .assessment-question-block {
    padding: 0 0 1rem;
  }

  .assessment-title {
    text-transform: none;
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 0;
    font-family: $FONT_FUTURA_BOLD;
  }

  .assessment-title + .assessment-title {
    margin-top: 1rem;
  }

  .assessment-desc {
    margin-top: 0;
    margin-bottom: 0.5rem;

    &.light {
      font-family: $FONT_FUTURA_MEDIUM;
      color: rgba($PRIMARY_TEXT_COLOR, 0.6);
    }
  }

  .assessment-list {
    padding-left: 1rem;
  }

  .text-link {
    color: $SECONDARY_ORANGE_COLOR !important;
    text-decoration: underline !important;
  }

  .rs-checkbox {
    margin: 0.35rem 0rem;
    border: 1px solid $PRIMARY_DARK_BORDER_COLOR;
    border-radius: 4px;

    &:hover {
      border: 1px solid $SECONDARY_ORANGE_HOVER_COLOR;
    }

    .rs-checkbox-checker {
      padding-top: 0;
      padding-bottom: 0;

      label {
        width: 100%;
        padding: 1.5rem 0;

        .rs-checkbox-wrapper {
          top: 25px;
        }
      }
    }
  }

  .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before {
    background-color: $SECONDARY_ORANGE_COLOR !important;
    border-color: $SECONDARY_ORANGE_COLOR !important;
  }

  .rs-checkbox .rs-checkbox-inner::before {
    border-color: $PRIMARY_DARK_BORDER_COLOR !important;
  }

  .rs-checkbox-checked {
    border-color: $SECONDARY_ORANGE_COLOR !important;
  }

  @include media-breakpoint-down(md) {
    &.bg-white,
    .assessment-group {
      padding: 2rem;
    }

    .cancel-group {
      padding: 0;
    }
  }

  .rs-checkbox.email-consent {
    border: 0;

    .rs-checkbox-checker {
      padding-left: 20px;
    }

    label {
      padding: 0.25rem 0;

      .rs-checkbox-wrapper {
        top: unset;
        left: 0;
      }
    }
  }
}
