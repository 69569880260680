@import 'component-library/dist/scss/alloy';
.callout-tag-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 4px 0px;
  gap: 6px;

  .callout-tag-text {
    font-size: 0.875rem;
    letter-spacing: 1.2px;
    color: $SECONDARY_ORANGE_COLOR;
    font-family: $FONT_FUTURA_BOLD;
    margin-bottom: 0;
  }

  .callout-tag-img {
    width: 16px;
    height: 16px;
  }
}
