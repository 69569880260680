@import 'component-library/dist/scss/alloy';
.manage-calendar-block {
  padding: toRem(16);

  background-color: $WHITE_COLOR;
  border: 1px solid $SECONDARY_BORDER_COLOR;

  display: grid;
  gap: toRem(4);

  .content-title {
    text-align: center;

    font-family: $FONT_FUTURA_MEDIUM;
    font-weight: 600;

    color: $PRIMARY_TEXT_COLOR;

    margin: 0;
    padding: 0;
  }

  .content-text {
    text-align: center;
    font-size: toRem(16);

    color: $PRIMARY_TEXT_COLOR;

    margin: 0;
    padding: 0;
  }

  // MARK: The calendar
  .MuiDayCalendar-slideTransition {
    min-height: 200px;
  }

  // MARK: The selected cell in calendar
  .MuiPickersDay-root.Mui-selected,
  .MuiPickersDay-root.Mui-selected {
    background-color: $SECONDARY_ORANGE_COLOR !important;
  }

  // MARK: Today cell in calendar
  .MuiPickersDay-root:not(.Mui-selected),
  .MuiPickersDay-root:not(.Mui-selected) {
    border: none;
  }

  .MuiPickersDay-root:hover {
    background-color: rgba($SECONDARY_ORANGE_COLOR, 0.1);
  }

  .MuiPickersDay-today {
    background-color: inherit;
  }

  .MuiPickersDay-root.Mui-selected:hover {
    color: $PRIMARY_TEXT_COLOR;
    background-color: $SECONDARY_ORANGE_COLOR;
  }

  .MuiPickersCalendarHeader-label {
    font-family: $FONT_FUTURA_MEDIUM;
    font-weight: 600;
    line-height: normal;
    letter-spacing: normal;
  }

  .MuiPickersDay-root {
    font-family: $FONT_FUTURA_BOOK;
  }

  @include media-breakpoint-down(sm) {
    padding: toRem(16) 0;
  }
}
