@import 'component-library/dist/scss/alloy';
.content-divider-wrapper {
  display: flex;
  justify-content: space-between;

  align-items: center;

  .divider-line {
    background-color: $PRIMARY_BORDER_COLOR;
    height: 1px;
    width: 100%;
  }

  .divider-text {
    color: $PRIMARY_TEXT_COLOR;
    font-family: $FONT_FUTURA_BOLD;
    font-weight: 600;
    font-size: 1rem;

    text-transform: uppercase;

    max-width: 1.5rem;

    margin: 0 1rem;
    padding: 0;
  }
}
