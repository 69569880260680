@import 'component-library/dist/scss/alloy';
.progress-block {
  background-color: $WHITE_COLOR;

  margin: 0;
  padding: 1.5rem;

  .progress-title {
    color: $PRIMARY_DARK_BLUE_COLOR;
    font-family: $FONT_FUTURA_BOLD;
    font-weight: bold;
    font-size: 1.375rem;

    margin: 0;
    padding: 0;
  }

  .progress-text {
    color: $PRIMARY_TEXT_COLOR;
    font-size: 1.25rem;

    margin: 0 0 1.5rem;
    padding: 0;

    .text-underline {
      text-decoration: underline;
    }
  }

  .progress-bar-outer {
    height: 0.5rem;

    background-color: $SECONDARY_LIGHT_BLUE_COLOR;
    border: 1px solid rgba(190, 189, 188, 0.5);
    border-radius: 1.25rem;

    margin: 0 0 0.5rem;

    .progress-bar-inner {
      position: relative;
      height: 100%;
      border-radius: 1.25rem;
      max-width: 100%;
      background-color: $SECONDARY_ORANGE_COLOR;

      .progress-bar-circle {
        position: relative;
        background-color: $SECONDARY_ORANGE_COLOR;

        width: 0.75rem;
        height: 0.75rem;
        border-radius: 50%;
        right: 0;
        top: -3px;
        float: right;
      }
    }
  }

  .progress-bar-text-wrapper {
    display: flex;
    justify-content: space-evenly;

    margin: 0 0 2rem;

    .bar-text {
      flex: 1;
      text-align: center;
      margin: 0;
      padding: 0;

      &:first-child {
        margin-right: auto;
        text-align: left;
      }

      &:last-child {
        margin-left: auto;
        text-align: right;
      }
    }
  }
}
