@import 'component-library/dist/scss/alloy';
.video-block {
  margin: 1.5rem 0;

  .video-title {
    color: $PRIMARY_DARK_BLUE_COLOR;
    font-family: $FONT_SANGBLEU_REGULAR !important;
    font-weight: 400;
    font-size: 2rem;
  }

  .video-text {
    color: $PRIMARY_DARK_BLUE_COLOR;
    font-size: 1rem;
    line-height: 1.625rem;
  }

  .video-info-wrapper {
    border: 1px solid $PRIMARY_LIGHT_BLUE_COLOR;
    padding: 1rem;

    margin: 0 0 1rem;

    .info-title {
      color: $PRIMARY_DARK_BLUE_COLOR;
      font-family: $FONT_FUTURA_MEDIUM;
      font-size: 1.125rem;
      font-style: normal;

      margin: 0;
      padding: 0;
    }

    .info-list {
      padding-inline-start: 30px;
      margin: 0;

      .info-text {
        color: $PRIMARY_DARK_BLUE_COLOR;
      }
    }
  }

  .video-example-wrapper {
    position: relative;
    margin: 0 auto 1rem;
    width: 10rem;
    text-align: center;

    cursor: pointer;

    .video-example-tag {
      position: absolute;
      top: 0.75rem;
      left: 15%;
      padding: 0.35rem 1rem;
      background-color: $WHITE_COLOR;
      color: black;
      text-transform: uppercase;
      font-family: $FONT_FUTURA_BOLD;
    }

    .video-example-img {
      width: 100%;
      border-radius: 1.875rem;
    }

    .video-example-play-img {
      width: 2.5rem;
      height: 2.5rem;
      position: absolute;
      top: 45%;
      left: 40%;
      border-radius: 1.5rem;
      padding: 6px;
      background-color: rgba(#000, 0.25);
    }
  }

  .video-btn-wrapper {
    display: block;
    text-align: center;

    .btn-video {
      display: block;
      font-family: $FONT_FUTURA_BOLD;
      font-weight: 700;
      font-size: 0.8125rem;
      letter-spacing: 0.09rem;
      padding: 0.75rem 2rem;
      text-transform: uppercase;
      transition: 0.2s ease-out all;

      margin: 0 auto 1rem;
    }

    .btn-video-primary {
      color: $WHITE_COLOR;
      background-color: $SECONDARY_ORANGE_COLOR;

      @include hover-focus {
        color: $WHITE_COLOR;
        background-color: $SECONDARY_DARK_ORANGE_HOVER_COLOR;
      }

      img {
        width: 1rem;
        height: 1rem;
      }
    }

    .btn-video-link {
      color: $SECONDARY_ORANGE_COLOR !important;
      transition: 0.2s ease-out all;
      background-color: inherit !important;
      cursor: pointer;

      padding: 0 0 0.25rem;
      border-bottom: 2px solid $SECONDARY_ORANGE_COLOR;

      @include hover-focus {
        color: $SECONDARY_ORANGE_HOVER_COLOR !important;
      }
    }
  }
}
