@import 'component-library/dist/scss/alloy';
.messages-iframe {
  border: 1px solid $SECONDARY_BORDER_COLOR;
  width: 100%;
  height: 500px;
  margin-bottom: 100px;

  @include media-breakpoint-down(md) {
    height: 60vh;
  }

  &.hide-iframe {
    border: none;
    height: 0;
    margin: 0;
  }
}

.c-message-bubble__content {
  background-color: $PRIMARY_DARK_BLUE_COLOR !important;
}

.messages-video {
  &.rs-modal {
    margin: 25px auto 0;
  }
}

.messages-video .rs-modal-content {
  background-color: $WHITE_COLOR;
  border-radius: 0;

  .rs-modal-header {
    padding-bottom: 15px;
  }

  .rs-modal-body {
    padding: 0px 5px;
    background-color: $WHITE_COLOR;

    max-height: calc(100vh - 40px) !important;

    .title {
      font-family: $FONT_SANGBLEU_LIGHT;
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }

    video {
      height: 65vh;
    }

    .messages-video-thumbnail {
      text-align: center;

      .messages-video-block {
        margin: 0 auto;
        max-width: 400px;
        position: relative;

        .messages-thumbnail-img {
          min-height: 400px;

          @include media-breakpoint-down(md) {
            min-height: 0;
            width: 100%;
          }
        }

        img {
          cursor: pointer;
          transition: 0.4s all;

          &:hover {
            filter: brightness(75%);
          }
        }

        .play-icon {
          position: absolute;
          bottom: 25px;
          left: 25px;
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}
