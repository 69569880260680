.select-image-field {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0;

  .select-image-inner-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  .inner-content-wrapper {
    display: flex;
    align-items: start;
    flex-direction: row;
    min-height: 100px;

    @include media-breakpoint-down(md) {
      min-height: unset;
    }

    .content-block {
      margin: 0 0 toRem(10) toRem(12);

      display: grid;
      gap: toRem(8);

      .content-title {
        font-family: $FONT_FUTURA_BOLD;
        font-size: toRem(16);
        line-height: 1.5;

        margin: 0;
        padding: 0;
      }

      .content-text {
        font-size: toRem(18);
        line-height: 1.5;
        color: $PRIMARY_TEXT_COLOR;

        margin: 0;
        padding: 0;
      }

      &.short {
        margin: 0 0 0 toRem(12);
      }
    }

    &.short {
      min-height: 0px !important;
    }
  }

  .rx-icon {
    position: absolute;
    bottom: 6px;
    left: 8px;
    height: 38px;

    @include media-breakpoint-down(lg) {
      height: 23px;
    }
  }

  .avatar {
    height: 80%;
    width: auto;
    border: 2px solid white;
    border-radius: 100%;
    @include media-breakpoint-down(md) {
      max-height: 80px;
    }
  }

  .bottom {
    height: 80%;
    flex: 1;
    object-fit: scale-down;
    width: auto;
    position: absolute;
    bottom: 0;
  }

  .fullWidth {
    height: auto;
    width: 100%;
    object-fit: cover;
    object-position: 50% 80%;

    @include media-breakpoint-down(lg) {
      height: 100%;
    }

    @include media-breakpoint-down(md) {
      max-height: 100px;
    }
  }

  .extra-tall {
    height: 150%;
    width: auto;
    position: absolute;
    top: 10%;
    @include media-breakpoint-down(md) {
      max-height: unset;
    }
  }

  .blue-white-gradient {
    background: linear-gradient(103.36deg, #f8fbff 11.33%, #eef4fd 23.61%, #c5d8f8 93.21%);
  }

  .background {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    max-height: 212px;
    height: 212px;
    overflow: hidden;
    background-color: $PRIMARY_LIGHT_BLUE_COLOR;

    @include media-breakpoint-down(lg) {
      max-height: 150px;
    }

    @include media-breakpoint-down(md) {
      height: unset;
      min-height: 100px;
    }
  }

  @include media-breakpoint-down(md) {
    height: unset;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
