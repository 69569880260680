@import 'component-library/dist/scss/alloy';
.messaging-footer-block {
  display: flex;
  gap: toRem(16);

  justify-content: space-between;

  padding: toRem(16) toRem(32);

  border-top: 1px solid $PRIMARY_CARD_BORDER_COLOR;

  @include media-breakpoint-down(xs) {
    flex-direction: column-reverse;
    gap: toRem(8);

    padding: toRem(12);
  }

  .footer-text {
    font-size: toRem(18);
    line-height: toRem(24);

    color: $PRIMARY_DARK_GREY_COLOR;

    margin: 0;
    padding: 0;
  }

  .input-content-block {
    width: 100%;

    display: grid;
    gap: toRem(12);

    .input-field-wrapper {
      width: 100%;

      .field-input {
        padding: 0;

        border: none;

        outline: none;

        font-size: toRem(18);
      }
    }

    .new-message-files-list {
      display: flex;
      gap: toRem(12);
      flex-wrap: wrap;

      .MuiChip-deleteIcon {
        margin: 0 10px 0 -6px;
      }
    }
  }

  .actions-block {
    display: flex;
    gap: toRem(12);

    align-items: start;

    @include media-breakpoint-down(xs) {
      justify-content: end;

      border-bottom: 1px solid $PRIMARY_CARD_BORDER_COLOR;

      padding-bottom: toRem(8);
    }

    .action-button {
      cursor: pointer;

      background-color: inherit;
      color: $PRIMARY_DARK_BLUE_COLOR;

      transition: 0.2s ease-out all;

      margin: 0;

      &.send-button {
        color: $SECONDARY_ORANGE_COLOR;

        @include hover-focus {
          color: $SECONDARY_DARK_ORANGE_HOVER_COLOR;
        }
      }
    }
  }
}
