.questionnaire-wrapper {
  opacity: 0.01;
  transition: opacity 0.5s;

  &.fade-in-wrapper {
    opacity: 1;
  }

  &.fade-out-wrapper {
    opacity: 0.01;
  }
}
