@import 'component-library/dist/scss/alloy';
.subscriber-benefits-block {
  display: grid;
  gap: toRem(24);

  border: 1px solid $PRIMARY_LIGHT_BLUE_COLOR;

  padding: toRem(24) toRem(16) toRem(16);

  .benefits-title {
    font-family: $FONT_SANGBLEU_REGULAR;
    font-weight: normal;
    font-size: toRem(22);
    line-height: toRem(30);

    color: $PRIMARY_DARK_BLUE_COLOR;
    text-align: center;

    margin: 0;
    padding: 0;
  }

  .benefits-perks-list {
    display: flex;
    justify-content: space-between;

    .perk-item {
      text-align: center;

      .perk-text {
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 450;
        font-size: toRem(14);
        line-height: toRem(16);

        color: $PRIMARY_DARK_BLUE_COLOR;

        margin: 0;
        padding: 0;
      }
    }
  }
}
