@import 'component-library/dist/scss/alloy';
.rs-modal-wrapper {
  z-index: 10000;
}

.alloy-modal {
  .rs-modal-content {
    border-radius: 0;
    padding: 0;

    .rs-modal-body {
      padding: 0;
      max-height: 90vh !important;

      @include media-breakpoint-down(md) {
        max-height: 560px !important;
      }
    }
  }

  .modal-header {
    margin: 0;
    padding: 1.5rem 1.5rem 0 1.5rem;
    border: none;
    text-align: right;
    position: sticky;
    top: 0;

    .header-btn-close {
      background-color: inherit;
      padding: 0;
      margin: 0;
      font-size: 1rem;
      line-height: 0;

      .close-icon {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .modal-body {
    margin: 0;
    padding: 1rem 4rem 2rem;
    text-align: center;

    .modal-title {
      font-family: $FONT_FUTURA_MEDIUM;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 2.5rem;

      margin: 0 0 0.5rem;
      padding: 0;
      .text-underline {
        text-decoration: underline;
      }
    }

    .modal-text {
      font-size: 1.1rem;

      margin: 0 0 1em;
      padding: 0;
    }

    .modal-select-options-list {
      margin-bottom: 1.5rem;
      text-align: left;

      .option-radio {
        .rs-radio-checker {
          padding-left: 26px;

          label {
            font-size: 1.125rem;
            font-style: normal;

            .rs-radio-wrapper {
              left: 0;
              top: 12px;
            }
          }
        }
      }

      .field-input {
        margin-top: 1rem;
      }
    }

    .modal-photo-wrapper {
      margin: 1.5rem 0;
    }

    .modal-btn-wrapper {
      .btn-cancel,
      .btn-confirm {
        font-family: $FONT_FUTURA_BOLD;
        font-size: 0.8125rem;
        letter-spacing: 0.08125rem;
        text-transform: uppercase;
        opacity: 1;

        margin: 0 0.5rem;
        padding: 0.75rem 2rem;

        &:disabled {
          opacity: 0.3;
        }
      }

      .btn-cancel {
        background-color: $WHITE_COLOR;
        color: $SECONDARY_ORANGE_COLOR;
        border: 1px solid $SECONDARY_BORDER_COLOR;
      }

      .btn-confirm {
        background-color: $SECONDARY_ORANGE_COLOR;
        color: $WHITE_COLOR;
        border: 1px solid $SECONDARY_ORANGE_COLOR;
      }
    }

    @include media-breakpoint-down(md) {
      padding: 1.5rem 1.5rem 4rem;

      .modal-text {
        margin: 0 0 2rem;
      }

      .btn-cancel,
      .btn-confirm {
        margin: 1rem 0 0;
        width: 100%;
        padding: 1rem 2rem;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    max-width: 100vw;
    max-height: 100vh;
    width: 100vw;
    height: 100vh;

    margin: 0;

    .rs-modal-content {
      height: 100vh;

      .rs-modal-body {
        margin: 0;
      }
    }
  }
}
