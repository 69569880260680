@import 'component-library/dist/scss/alloy';
.top-banner-container {
  z-index: $BAR_Z_INDEX;

  .top-banner-section {
    background-color: $WHITE_COLOR;
    text-align: center;
    padding: 1rem 12rem;
    border-bottom: 1px solid $LIGHT_BANNER_BORDER_COLOR;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .banner-left-content {
      text-align: center;

      .content-back-button {
        background-color: inherit;

        .back-text {
          color: $PRIMARY_DARK_BLUE_COLOR;
          font-family: $FONT_FUTURA_BOLD;
          text-transform: uppercase;
          font-size: 1rem;

          padding: 0;
        }

        .back-icon {
          width: 15px;
          margin-right: 10px;
          margin-bottom: 4px;

          padding: 0;
        }

        &.hide-content {
          opacity: 0;
          pointer-events: none;
        }
      }

      @include media-breakpoint-down(md) {
        text-align: left;
      }
    }

    .banner-center-button {
      width: 88px;
      height: 53px;
      display: block;
      margin: 0 auto;

      .banner-icon {
        width: 100%;
        height: 100%;
        background-image: url('../../../shared/assets/svg/logos/logo.svg');
        background-repeat: no-repeat;
      }

      @include media-breakpoint-down(sm) {
        width: 32px;
        height: 32px;

        .banner-icon {
          background-image: url('../../../shared/assets/svg/logos/logo-icon.svg');
        }
      }
    }

    .banner-help-button {
      background-color: inherit;

      .help-text {
        color: $PRIMARY_DARK_BLUE_COLOR;
        font-family: $FONT_FUTURA_BOLD;
        text-transform: uppercase;
        font-size: 1rem;

        padding: 0;
      }

      .help-icon {
        width: 24px;
        margin-left: 8px;
        margin-bottom: 4px;

        padding: 0;
      }
    }

    @include media-breakpoint-down(md) {
      padding: 1rem 5rem;
    }

    @include media-breakpoint-down(sm) {
      padding: 1rem;
    }
  }

  .progress-bar-wrapper {
    background-color: $PRIMARY_LIGHT_BLUE_COLOR;
    height: 0.25rem;
    width: 100%;

    .progress-bar-inner {
      background-color: $PRIMARY_DARK_BLUE_COLOR;
      height: 100%;
      transition: all ease 1s;
    }
  }
}
