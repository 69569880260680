@import 'component-library/dist/scss/alloy';
.title-text-wrapper {
  margin-bottom: toRem(32);

  .content-title {
    font-family: $FONT_SANGBLEU_REGULAR !important;
    font-weight: 400;
    line-height: toRem(38);
    font-size: toRem(38);
    color: $PRIMARY_DARK_BLUE_COLOR;
    margin: 0;

    @include media-breakpoint-down(md) {
      font-size: 2rem;
    }
  }

  .content-text {
    font-family: $FONT_FUTURA_BOOK;
    font-size: 1rem;
    line-height: 1.625rem;
    color: $PRIMARY_TEXT_COLOR;
    margin: toRem(16) 0 0;
    padding-right: 15px;

    width: 60%;

    span {
      color: $SECONDARY_ORANGE_COLOR;
    }

    &.bold-orange {
      color: $SECONDARY_ORANGE_COLOR;
      font-family: $FONT_FUTURA_BOLD;
    }

    .bold {
      font-family: $FONT_FUTURA_BOLD;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}
