@import 'component-library/dist/scss/alloy';
.doctor-wrapper {
  display: flex;
  justify-content: space-between;

  .doctor-info-block {
    display: flex;
    align-items: center;
    align-items: center;

    .doctor-photo {
      width: 88px;
      height: 88px;
      margin-right: toRem(24);

      position: relative;
      margin-top: toRem(5);
      margin-left: toRem(5);
      box-shadow: -6px -6px 0px 0px $PRIMARY_DARK_GREEN_COLOR;
    }

    .doctor-content {
      .doctor-title {
        color: $PRIMARY_DARK_BLUE_COLOR;
        font-family: $FONT_FUTURA_BOLD;

        margin: 0;
        padding: 0;
      }

      .doctor-name {
        color: $PRIMARY_TEXT_COLOR;
        font-family: $FONT_FUTURA_MEDIUM;

        margin: 0;
        padding: 0;
      }

      .doctor-text {
        color: $PRIMARY_TEXT_COLOR;
        font-size: 0.8125rem;
        font-family: $FONT_FUTURA_MEDIUM;

        margin: 0;
        padding: 0;
      }
    }
  }

  .btn-message {
    font-family: $FONT_FUTURA_BOLD;
    font-size: 0.8125rem;
    letter-spacing: 0.08125rem;
    text-transform: uppercase;
    opacity: 1;

    display: inline-block;

    margin: 0;
    padding: 0.75rem 2rem;

    background-color: $WHITE_COLOR;
    color: $SECONDARY_ORANGE_COLOR;
    border: 1.5px solid $SECONDARY_BORDER_COLOR;
  }
}
