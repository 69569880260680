.info-input-number,
.field-select {
  width: 100%;
  text-align: center;
  font-weight: bold;
  border: 1px solid $SECONDARY_BORDER_COLOR !important;
  background-color: $WHITE_COLOR;
  border-radius: 0;
  color: $PRIMARY_DARK_BLUE_COLOR;
  line-height: 1.5;

  .rs-input-group,
  .rs-picker-default .rs-picker-toggle {
    border-radius: 0;
    border: 0;
  }

  .rs-picker-toggle-wrapper {
    display: inline;
    border: 0;
    border-radius: 0;
  }

  .rs-input-number-btn-group-vertical:last-child .rs-input-number-touchspin-up,
  .rs-input-number-btn-group-vertical:last-child .rs-input-number-touchspin-down {
    border-radius: 0;
  }

  .rs-form-vertical .rs-form-group .rs-input-group {
    width: 100% !important;
  }

  .rs-picker-toggle-textbox {
    color: $PRIMARY_DARK_BLUE_COLOR;
  }
}

.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  border-radius: 0;
  border: none;

  padding-bottom: 10.5px;
  padding-top: 10.5px;

  .rs-picker-toggle-caret {
    top: 10.5px;
  }
}
