// ---
// Input
// ---

.rs-input-group:focus,
.rs-input-group:hover:not(:disabled),
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:not(.rs-input-group-disabled):hover .rs-input:focus,
.rs-input:hover:not(:disabled),
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus,
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:hover:not(:disabled) {
  border-color: $PRIMARY_DARK_BORDER_COLOR;
}

.rs-input-group:focus-within,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
  outline: 0;
}

// ---
// Checkbox
// ---

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
  background-color: $PRIMARY_DARK_BLUE_COLOR !important;
  border-color: $PRIMARY_DARK_BLUE_COLOR !important;
}

.rs-checkbox .rs-checkbox-inner::before {
  border-color: $PRIMARY_DARK_BLUE_COLOR !important;
}

// ---
// Form
// ---

.rs-form-vertical .rs-form-group .rs-input-group {
  width: 100%;
}

.form-control:disabled,
.form-control[readonly],
.rs-input:disabled,
.rs-input[readonly] {
  background-color: $WHITE_COLOR !important;
  color: $PRIMARY_TEXT_COLOR;
}

// ---
// Popup / toaster
// ---

.rs-toast-container.rs-toast-container-top-end {
  z-index: 100010;
}

// ---
// Picker menu
// ---

.rs-picker-menu {
  border-radius: 0 !important;
  border: 1px solid $SECONDARY_BORDER_COLOR;
  z-index: 100000;

  .rs-picker-select-menu-item.rs-picker-select-menu-item-focus,
  .rs-picker-select-menu-item:focus,
  .rs-picker-select-menu-item:hover {
    background-color: rgba($PRIMARY_DARK_BLUE_COLOR, 0.15);
    color: $PRIMARY_TEXT_COLOR;
  }

  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: $PRIMARY_TEXT_COLOR;
  }

  .rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    background-color: rgba($PRIMARY_DARK_BLUE_COLOR, 0.15) !important;
    color: $PRIMARY_TEXT_COLOR !important;
  }

  .rs-btn-primary {
    background-color: $SECONDARY_ORANGE_COLOR;
    border-radius: 0;
  }

  .rs-btn-primary:focus,
  .rs-btn-primary:hover {
    background-color: $SECONDARY_ORANGE_HOVER_COLOR;
  }
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
  background-color: rgba($SECONDARY_ORANGE_COLOR, 0.15);
  color: $SECONDARY_ORANGE_COLOR;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: $PRIMARY_TEXT_COLOR;
}

// ---
// Steps
// ---

.rs-steps-item-status-finish .rs-steps-item-tail,
.rs-steps-item-status-finish .rs-steps-item-title:after {
  border-color: $PRIMARY_DARK_BLUE_COLOR;
}

.rs-steps-item-icon-wrapper > .rs-steps-item-icon .rs-icon {
  vertical-align: text-top;
  font-size: 20px;
}

// ---
// Notification
// ---

.rs-notification {
  border-radius: 0;
}

.rs-notification-content {
  color: $PRIMARY_TEXT_COLOR;
  background-color: $WHITE_COLOR;
}

.rs-notification-info .rs-notification-content .rs-icon {
  color: $PRIMARY_TEXT_COLOR !important;
}

// ---
// Table
// ---

.rs-table-cell-content {
  justify-content: center;
  text-align: left;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

// ---
// Drawer
// ---

.rs-drawer-left.rs-drawer-sm,
.rs-drawer-right.rs-drawer-sm {
  max-width: 90%;
}

// ---
// Picker removing icon
// ---

.rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  display: none !important;
}

// ---
// The close button inside modal
// ---

.rs-modal-header .rs-modal-header-close {
  right: 30px;
  font-size: 20px;
}

// ---
// Wrapper
// ---
.rs-drawer-wrapper {
  z-index: 100000;

  @include media-breakpoint-down(md) {
    .rs-drawer-body {
      padding: 0 10px;
    }
  }
}

// ---
// Radio
// ---
.rs-radio-inner {
  background-color: transparent !important;

  &:before:hover {
    border: 1px solid $SECONDARY_ORANGE_COLOR !important;
  }

  &:before {
    background-color: transparent !important;
  }

  &:after {
    width: 10px !important;
    height: 10px !important;
    background-color: $SECONDARY_ORANGE_COLOR !important;
    border: 1px solid $SECONDARY_ORANGE_COLOR !important;
    margin-top: 3px !important;
    margin-left: 3px !important;
    border-radius: 50% !important;
  }
}

.rs-radio.rs-radio-checked .rs-radio-inner:before,
.rs-radio.rs-radio-checked .rs-radio-inner:hover {
  border-color: $SECONDARY_ORANGE_COLOR !important;
}
