@import 'component-library/dist/scss/alloy';
.product-info-drawer {
  &.rs-drawer-right.rs-drawer-sm {
    @include media-breakpoint-down(md) {
      max-width: 100% !important;
      width: 100% !important;
    }
  }

  .rs-drawer-body {
    padding: 0;
    background-color: $SECONDARY_LIGHT_BLUE_COLOR;

    .rs-drawer-body-close {
      display: none;
    }

    .drawer-header {
      background-color: $WHITE_COLOR;
      display: flex;
      align-items: center;
      padding: 1rem 1.5rem;

      top: 0;
      right: 0;
      z-index: 20;

      .header-title {
        flex: 1;
        font-family: $FONT_SANGBLEU_REGULAR !important;
        font-weight: normal;
        font-size: 2rem;
        color: $PRIMARY_DARK_BLUE_COLOR;

        padding: 0;
        margin: 0;
      }

      .header-btn-close {
        text-align: right;

        background-color: inherit;
        padding: 0;
        margin: 0;
        font-size: 1rem;
        line-height: 0;

        .close-icon {
          width: 1rem;
          height: 1rem;
        }
      }

      @include media-breakpoint-down(md) {
        padding: 2rem 1.5rem;
      }
    }

    .drawer-body {
      padding: 0 1.5rem;

      .drawer-title {
        font-family: $FONT_FUTURA_BOLD !important;
        font-weight: bold;
        font-size: 1.25rem;
        color: $PRIMARY_DARK_BLUE_COLOR;

        padding: 0;
        margin: 1rem 0 0;
      }

      .drawer-list {
        list-style: none;
        display: flex;

        margin: 1.5rem 0 1rem;
        padding: 0;

        border-bottom: 1px solid $PRIMARY_BORDER_COLOR;

        .drawer-list-text {
          color: $PRIMARY_DARK_GREY_COLOR;
          font-family: $FONT_FUTURA_BOLD;
          font-size: 1.125rem;

          cursor: pointer;

          padding: 0 0 0.5rem;
          margin: 0 1rem 0 0;

          &.selected {
            color: $PRIMARY_DARK_BLUE_COLOR;
            border-bottom: 3px solid $PRIMARY_DARK_BLUE_COLOR;
          }

          @include media-breakpoint-down(md) {
            font-size: 1rem;
            margin: 0 1.1rem 0 0;
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      padding: 0 !important;
    }
  }
}
