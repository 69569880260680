@import 'component-library/dist/scss/alloy';
.doctor-card-block {
  background-color: $PRIMARY_LIGHT_BLUE_COLOR;

  padding: toRem(16);

  display: flex;
  justify-content: space-between;

  .doctor-photo {
    width: 88px;
    height: 88px;
    margin-right: toRem(24);

    position: relative;
    margin-top: toRem(5);
    margin-left: toRem(5);
    box-shadow: -6px -6px 0px 0px $PRIMARY_DARK_GREEN_COLOR;

    @include media-breakpoint-down(sm) {
      width: 60px;
      height: 60px;
    }
  }

  .doctor-content {
    .content-title {
      font-family: $FONT_FUTURA_MEDIUM;
      font-weight: 500;
      font-size: toRem(18);
      line-height: toRem(26);

      color: $PRIMARY_TEXT_COLOR;

      margin: 0;
      padding: 0;
    }

    .content-subtitle {
      font-family: $FONT_FUTURA_BOLD;
      font-weight: bold;
      font-size: toRem(12);
      line-height: toRem(24);

      color: $PRIMARY_TEXT_COLOR;

      margin: 0;
      padding: 0;
    }

    .content-text {
      font-family: $FONT_FUTURA_BOOK;
      font-weight: normal;
      font-size: toRem(18);
      line-height: toRem(26);

      color: $PRIMARY_TEXT_COLOR;

      border-top: 1px solid $WHITE_COLOR;
      border-radius: toRem(4);

      margin: toRem(8) 0 0;
      padding: toRem(8) 0 0;
    }
  }
}
