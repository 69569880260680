@import 'component-library/dist/scss/alloy';
.subscriptions-bundle-container {
  background-color: $PRIMARY_LIGHT_BLUE_COLOR;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: toRem(32);
  padding: toRem(16);

  .bundle-content-wrapper {
    display: grid;
    gap: toRem(4);

    .content-title {
      font-family: $FONT_FUTURA_BOLD;
      font-weight: bold;
      font-size: toRem(22);
      line-height: toRem(26);

      color: $PRIMARY_TEXT_COLOR;

      margin: 0;
      padding: 0;
    }

    .content-text {
      font-size: toRem(20);
      line-height: toRem(26);

      color: $PRIMARY_TEXT_COLOR;

      margin: 0;
      padding: 0;
    }
  }

  @include media-breakpoint-down(md) {
    display: block;

    margin-bottom: toRem(16);

    .bundle-content-wrapper {
      margin-bottom: toRem(16);
    }

    .primary-button {
      width: 100%;
    }
  }
}

.subscriptions-container {
  display: grid;
  gap: toRem(32);

  + .subscriptions-container {
    margin-top: toRem(48);
  }

  .container-title {
    font-family: $FONT_SANGBLEU_REGULAR !important;
    font-weight: 400;
    line-height: toRem(32);
    font-size: toRem(38);

    color: $PRIMARY_DARK_BLUE_COLOR;

    margin: 0;
    padding: 0;
  }

  .subscription-wrapper {
    background-color: $WHITE_COLOR;
    border: 1px solid $PRIMARY_CARD_BORDER_COLOR;

    padding: toRem(32);

    @include media-breakpoint-down(md) {
      padding: toRem(24) toRem(16);
    }
  }
}
