@import 'component-library/dist/scss/alloy';
.cancel-wrapper {
  .content-block {
    display: grid;
    gap: toRem(16);

    .content-title {
      font-family: $FONT_SANGBLEU_REGULAR;
      font-weight: normal;
      font-size: toRem(32);
      line-height: toRem(38);

      color: $PRIMARY_DARK_BLUE_COLOR;

      margin: 0;
      padding: 0;
    }

    .content-text {
      font-family: $FONT_FUTURA_BOOK;
      font-weight: normal;
      font-size: toRem(20);
      line-height: toRem(26);

      color: $PRIMARY_DARK_BLUE_COLOR;

      margin: 0;
      padding: 0;
    }
  }

  .btns-stack-block {
    display: grid;
    gap: toRem(24);

    .primary-button {
      width: 100%;
    }
  }
}
