.shared-button {
  font-family: $FONT_FUTURA_BOLD;
  font-size: toRem(14);
  letter-spacing: 0.08125rem;
  text-transform: uppercase;
  opacity: 1;

  text-align: center;

  transition: 0.2s ease-out all;

  display: inline-block;

  margin: 0;
  padding: toRem(12) toRem(42);

  .button-arrow,
  .btn-arrow {
    width: 12px;
    margin-left: toRem(8);
    margin-bottom: toRem(2);
  }
}

.primary-button {
  @extend .shared-button;

  background-color: $SECONDARY_ORANGE_COLOR;
  color: $WHITE_COLOR;

  @include hover-focus {
    background-color: $SECONDARY_DARK_ORANGE_HOVER_COLOR;
    color: $WHITE_COLOR;
  }

  &:disabled {
    background-color: rgba($SECONDARY_ORANGE_COLOR, 0.5);
    color: $WHITE_COLOR;

    @include hover-focus {
      background-color: rgba($SECONDARY_ORANGE_COLOR, 0.5);
      color: $WHITE_COLOR;
    }
  }

  &.add-bottom-margin {
    margin: 0 0 toRem(24);
  }
}

.secondary-button {
  @extend .shared-button;

  background-color: $WHITE_COLOR;
  color: $SECONDARY_ORANGE_COLOR;

  @include hover-focus {
    background-color: $WHITE_COLOR;
    color: $SECONDARY_ORANGE_COLOR;
  }
}

.secondary-border-button {
  @extend .secondary-button;

  border: 1.5px solid $SECONDARY_BORDER_COLOR;
}

.primary-link {
  font-family: $FONT_FUTURA_BOLD;
  font-weight: bold;
  font-size: toRem(15);
  line-height: toRem(17);
  letter-spacing: toRem(1);

  width: fit-content;

  background-color: inherit;
  color: $PRIMARY_DARK_BLUE_COLOR;
  border-bottom: 2px solid $PRIMARY_DARK_BLUE_COLOR;

  text-transform: uppercase;

  margin: 0 auto;
  padding: 0 0 toRem(4);
}

.primary-link-button {
  background-color: inherit;
  color: $PRIMARY_TEXT_COLOR;

  font-size: 1rem;
  text-decoration-line: underline;

  margin: 0;
  padding: 0;

  @include hover-focus {
    text-decoration-line: underline;
  }
}

.full-width-button {
  width: 100% !important;
}

/*
 * End of new buttons
 */

.rs-ripple-pond {
  display: none !important;
}

.btn-icon {
  @extend .shared-button;

  color: $WHITE_COLOR !important;
}

.anchor-link {
  text-decoration: underline;
  color: $SECONDARY_ORANGE_COLOR;
}
