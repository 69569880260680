@import 'component-library/dist/scss/alloy';
.cancel-follow-up-container {
  .cancel-content-wrapper {
    display: grid;
    gap: toRem(16);

    .content-blurb {
      color: $PRIMARY_DARK_BLUE_COLOR;
      font-size: toRem(20);
      line-height: toRem(26);

      border: 1px solid $PRIMARY_LIGHT_BLUE_COLOR;

      margin: toRem(12) 0 0;
      padding: toRem(16);
    }
  }
}
