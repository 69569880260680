.dashboard-header-wrapper {
  position: sticky;
  top: 0;
  z-index: 999;

  width: 100%;
  max-width: 100%;

  .logo-wrapper {
    height: 65px;

    .logo {
      width: 90px;
      margin: 8px 0;
    }
  }
}

.bg-content {
  background-color: $SECONDARY_LIGHT_BLUE_COLOR;
}
