.ce-sticky-button-block {
  background-color: $WHITE_COLOR;

  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;

  display: block;

  z-index: 1000;

  .primary-button {
    width: 100%;

    padding: 1.25rem 2.5rem;
  }

  @include media-breakpoint-up(sm) {
    display: none;
  }
}
