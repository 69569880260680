@import 'component-library/dist/scss/alloy';
.order-wrapper {
  background-color: $WHITE_COLOR;
  border: 1px solid $PRIMARY_CARD_BORDER_COLOR;

  padding: toRem(32);

  + .order-wrapper {
    margin-top: 1.5rem;
  }

  @include media-breakpoint-down(md) {
    padding: toRem(24) toRem(16);
  }

  // ------------------------------
  // Shipments-Order.Active
  // ------------------------------
  &.active {
    .chevron-btn {
      transform: rotate(180deg);
    }

    .order-content-block {
      margin-top: toRem(32);

      .order-content {
        max-height: max-content;

        + .order-content {
          margin-top: toRem(32);

          border-top: 1px solid rgb(#000, 0.16);

          @include media-breakpoint-down(sm) {
            margin-top: 0;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        margin-top: toRem(24);
      }
    }
  }
}
