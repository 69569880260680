@import 'component-library/dist/scss/alloy';
.subscription-container {
  display: grid;
  gap: toRem(32);

  + .subscription-container {
    margin-top: toRem(48);
  }

  .subscription-content-wrapper {
    background-color: $WHITE_COLOR;
    border: 1px solid $PRIMARY_CARD_BORDER_COLOR;

    padding: toRem(32);

    .products-card-block {
      .card-title {
        font-family: $FONT_SANGBLEU_REGULAR;
        font-size: toRem(24);
        line-height: toRem(32);

        color: $PRIMARY_DARK_BLUE_COLOR;

        margin: 0;
        padding: 0;

        display: flex;
        align-items: center;

        .title-hug {
          font-family: $FONT_FUTURA_BOLD;
          font-weight: bold;
          font-size: toRem(14);
          line-height: toRem(26);

          background-color: $SECONDARY_LIGHT_BLUE_COLOR;
          border-radius: 4px;

          margin: 0 0 0 toRem(18);
          padding: toRem(2) toRem(8);
        }

        @include media-breakpoint-down(md) {
          display: grid;

          .title-hug {
            width: fit-content;
            margin: toRem(16) 0 0 0;
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      padding: toRem(24) toRem(16);
    }
  }
}
