@import 'component-library/dist/scss/alloy';
.confirm-change-drawer {
  &.show-footer {
    padding-bottom: 5rem !important;
  }

  .drawer-body {
    .request-content-wrapper {
      padding: 1.5rem 0;

      .content-blurb {
        padding: 0.75rem;
        border: 1px solid $PRIMARY_LIGHT_BLUE_COLOR;
      }

      .request-products-wrapper {
        .viewable-product {
          margin: 0 0 1.25rem;
        }

        .request-text {
          color: $PRIMARY_TEXT_COLOR;
          margin: 1.5rem 0 0.5rem;
        }
      }
    }
  }

  .drawer-footer {
    position: fixed;
    background-color: $WHITE_COLOR;
    border-top: 1px solid $PRIMARY_BORDER_COLOR;
  }
}
