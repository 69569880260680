/**
 * = Sections
 */

.section {
  position: relative;
  padding-top: 9rem;
  padding-bottom: 4rem;
}

.intro-section {
  padding-top: 8rem;
  padding-bottom: 4rem;
  min-height: calc(100vh - 120px);

  background-color: $PRIMARY_LIGHT_BLUE_COLOR;

  @include media-breakpoint-down(md) {
    padding-top: 100px;
  }
}

.dashboard-section {
  padding: toRem(48) 0;

  background-color: $SECONDARY_LIGHT_BLUE_COLOR;

  @include media-breakpoint-down(sm) {
    padding: toRem(24) 0;
  }

  @include media-breakpoint-down(sm) {
    padding: toRem(32) 0;
  }
}

.auth-section {
  padding-top: 1rem;
  padding-bottom: 8rem;
  min-height: 100vh;

  &.lg {
    padding-top: 8rem;
    padding-bottom: 4rem;
  }

  background-color: $SECONDARY_LIGHT_BLUE_COLOR;
  color: $PRIMARY_TEXT_COLOR;

  @include media-breakpoint-down(md) {
    padding-top: 0px;

    &.lg {
      padding-top: 100px;
    }
  }

  &.bg-light-blue {
    background-color: $SECONDARY_LIGHT_BLUE_COLOR;
  }
}

@include media-breakpoint-up(sm) {
  .section {
    position: relative;
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .section-xl {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .section-lg {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .section-md {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .section-sm {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.bg-dashboard {
  background-color: $WHITE_COLOR;
}
