@import 'component-library/dist/scss/alloy';
// DISCOUNT
.ce-discount-block {
  padding: 1rem 0;
  border-bottom: 1px solid $PRIMARY_BORDER_COLOR;

  .discount-link {
    color: $PRIMARY_TEXT_COLOR;
    background-color: inherit;
    font-size: 1rem;
    text-decoration: none;
    margin: 0;
    padding: 0;
  }

  .discount-content {
    display: flex;
    align-items: center;

    .discount-close-btn {
      background-color: inherit;
      margin-right: 1rem;

      .close-icon {
        width: 1rem;
        height: 1rem;
      }
    }

    .discount-text {
      font-size: 1.1rem;
      line-height: normal;

      margin: 0;
      padding: 0;
    }

    .dicount-input-wrapper {
      flex: 1;

      form {
        display: flex !important;

        .discount-input {
          text-transform: uppercase;
          height: 100%;
          border-radius: 0;
        }

        .discount-btn {
          background-color: $SECONDARY_ORANGE_COLOR;
          color: $WHITE_COLOR;
          font-family: $FONT_FUTURA_BOLD;
          font-size: 0.8125rem;
          line-height: 1.3125rem;
          letter-spacing: 0.0625rem;
          text-transform: uppercase;

          padding-left: 1.5rem;
          padding-right: 1.5rem;

          &:disabled {
            color: $WHITE_COLOR;
            background-color: rgba($SECONDARY_ORANGE_COLOR, 0.5);

            @include hover-focus {
              color: $WHITE_COLOR;
              background-color: rgba($SECONDARY_ORANGE_COLOR, 0.5);
            }
          }
        }
      }
    }
  }
}
