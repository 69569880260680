.field-input {
  margin: 0;

  color: $PRIMARY_TEXT_COLOR;
  background-color: $WHITE_COLOR;
  border: 1px solid $SECONDARY_BORDER_COLOR;
  border-radius: 0;
  width: 100%;

  display: block;
  width: 100%;
  padding: 0.6rem 1rem;
  font-size: toRem(16);
  line-height: 1.5;
  background-clip: padding-box;
}

.rs-input-group {
  color: $PRIMARY_TEXT_COLOR;
  background-color: $WHITE_COLOR;
  border: 1px solid $SECONDARY_BORDER_COLOR;
  border-radius: 0;
  width: 100%;

  padding: 0;
}

.col-12 > .field,
.rs-input-group {
  margin: 0 0 0.5rem 0;
}

.rs-input-group .rs-input-group-addon,
.rs-input-group .rs-input-group-btn,
.rs-input-group .rs-input-number,
.rs-input-group .rs-picker-date .rs-picker-toggle,
.rs-input-group:not(.rs-input-group-inside) .rs-input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.field-password-check {
  list-style-type: none;
  padding: 0 0 0 10px;

  img {
    width: 15px;
    margin-right: 5px;
  }
}

.shipping-col,
.billing-col {
  width: 100%;
  .field-input {
    margin-top: 0.25rem;
    margin-bottom: 1.1rem;
  }
}

.form-control:disabled,
.form-control[readonly],
.rs-input:disabled {
  cursor: default;
}
