@import 'component-library/dist/scss/alloy';
.header-actions-wrapper {
  display: flex;
  grid-gap: 10px;

  margin: toRem(10) 0 0;

  .header-action-block {
    background-color: $SECONDARY_LIGHT_BLUE_COLOR;
    text-decoration: none;
    padding: toRem(24);

    width: 25%;

    cursor: pointer;

    .action-icon {
      margin: 0 0 toRem(16);
    }

    .action-title {
      font-family: $FONT_FUTURA_BOLD;
      font-weight: 600;
      font-size: toRem(20);
      color: $PRIMARY_DARK_BLUE_COLOR;

      margin: 0;
      padding: 0;

      display: flex;
      justify-content: space-between;

      gap: toRem(8);

      .title-arrow {
        margin-left: toRem(8);
      }
    }
  }

  @include media-breakpoint-down(md) {
    display: block;

    .header-action-block {
      display: flex;
      width: 100%;

      margin: toRem(8) 0 0;

      align-items: center;

      .action-icon {
        margin: 0;
      }

      .action-title {
        display: flex;
        margin-left: toRem(16);
        flex: 1;
        gap: auto;

        .title-arrow {
          width: 24px;
        }
      }
    }
  }
}
