/*
  Futura
*/

@font-face {
  font-family: 'futura-pt-bold';
  src:
    url('./futura-pt/futura-pt-bold.woff2') format('woff2'),
    url('./futura-pt/futura-pt-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'futura-pt-book';
  src:
    url('./futura-pt/futura-pt-book.woff2') format('woff2'),
    url('./futura-pt/futura-pt-book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'futura-pt-light';
  src:
    url('./futura-pt/futura-pt-light.woff2') format('woff2'),
    url('./futura-pt/futura-pt-light.woff') format('woff');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'futura-pt-medium';
  src:
    url('./futura-pt/futura-pt-medium.woff2') format('woff2'),
    url('./futura-pt/futura-pt-medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
