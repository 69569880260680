@import 'component-library/dist/scss/alloy';
.ce-account-wrapper {
  background-color: $WHITE_COLOR;
  border: 1px solid $PRIMARY_BORDER_COLOR;
  padding: 2rem 4rem;

  margin: 0 0 1.5rem;

  .ce-account-block {
    display: flex;
    justify-content: space-between;

    margin: 0.5rem 0 0;

    .account-text {
      font-size: 1rem;

      margin: 0;
      padding: 0;
    }

    .primary-link-button {
      color: $SECONDARY_ORANGE_COLOR;
    }
  }

  @include media-breakpoint-down(xs) {
    padding: 1.5rem 1rem;

    border-left: none;
    border-right: none;
  }
}
