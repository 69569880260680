@import 'component-library/dist/scss/alloy';
.doctor-consult-viewable-product {
  margin: 0 0 1rem;
  box-shadow: $CARD_BOX_SHADOW;

  .product-img-wrapper {
    background-color: $PRIMARY_LIGHT_BLUE_COLOR;

    justify-content: center;
    align-items: center;
    text-align: center;

    padding: 1rem;

    .product-img {
      max-width: 150px;
    }
  }

  .product-content-wrapper {
    background-color: $WHITE_COLOR;

    padding: 1.5rem;

    .product-title {
      color: $PRIMARY_DARK_BLUE_COLOR;
      font-family: $FONT_FUTURA_BOLD;
      font-weight: 600;
      font-size: 1.375rem;

      padding: 0;
      margin: 0;
    }

    .product-subtitle {
      color: $PRIMARY_DARK_BLUE_COLOR;
      font-family: $FONT_FUTURA_MEDIUM;
      font-weight: 500;
      font-size: 1.125rem;

      padding: 0;
      margin: 0 0 0.5rem;
    }

    .product-text {
      color: $PRIMARY_TEXT_COLOR;
      font-size: 1.125rem;

      padding: 0;
      margin: 0;
    }

    .product-list-wrapper {
      color: $PRIMARY_TEXT_COLOR;

      padding-inline-start: 25px;

      .list-text {
        font-size: 1.125rem;

        margin: 0;
        padding: 0;
      }
    }
  }
}
