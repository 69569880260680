@import 'component-library/dist/scss/alloy';
.ce-shipping-wrapper {
  background-color: $WHITE_COLOR;
  border: 1px solid $PRIMARY_BORDER_COLOR;
  padding: 2rem 4rem;

  margin: 0 0 1.5rem;

  .ce-shipping-footer-block {
    margin: 1rem 0 0;

    .field-checkbox-group {
      .field-checkbox {
        border: none;
        padding: 0;
      }
    }

    .footer-text {
      font-size: 1.125rem;
      color: $PRIMARY_DARK_GREY_COLOR;
    }

    .primary-button {
      width: 100%;
    }
  }

  @include media-breakpoint-down(xs) {
    padding: 1.5rem 1rem;

    border-left: none;
    border-right: none;
  }
}
