@import 'component-library/dist/scss/alloy';
.empty-state-wrapper {
  background-color: $WHITE_COLOR;
  border: 1px solid $SECONDARY_BORDER_COLOR;
  border-radius: 0.375rem;
  text-align: center;

  padding: 3.5rem 2.5rem;

  .empty-state-title {
    color: $PRIMARY_TEXT_COLOR;
    font-family: $FONT_FUTURA_MEDIUM !important;
    font-weight: 500;
    font-size: 2rem;

    margin: 0 0 1.5rem;
    padding: 0;
  }

  .empty-state-icon {
    width: 5.5rem;
    height: 5.5rem;

    margin: 0 0 1.5rem;
  }

  .empty-state-text {
    color: $PRIMARY_TEXT_COLOR;
    font-size: 1.15rem;

    margin: 0 0 1.5rem;
    padding: 0 16rem;

    @include media-breakpoint-down(md) {
      padding: 0 1rem;
    }
  }
}
