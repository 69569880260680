@import 'component-library/dist/scss/alloy';
.ce-terms-wrapper {
  background-color: $WHITE_COLOR;
  border: 1px solid $PRIMARY_BORDER_COLOR;
  padding: 2rem 4rem;

  margin: 0 0 1.5rem;

  .ce-terms-block {
    padding: 1rem 0 0;

    .input-field-wrapper {
      .field-checkbox {
        .checkbox-select-icon {
          &.error {
            outline: 1px solid $RED_COLOR;
            outline-offset: -2px;
          }
        }

        .checkbox-text {
          &.error {
            color: $RED_COLOR;

            .primary-link-button {
              color: $RED_COLOR;
            }
          }
        }
      }
    }
  }

  .ce-sms-block {
    margin-top: 1rem;

    .ce-checkbox {
      margin: 0.5rem 0;
    }

    .ce-terms-text {
      font-size: 1rem;
      color: $PRIMARY_TEXT_COLOR;

      span {
        font-weight: bold;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    padding: 1.5rem 1rem;

    border-left: none;
    border-right: none;
  }
}

.consent-modal .rs-modal-content {
  background-color: $SECONDARY_BACKGROUND_COLOR;
  border-radius: 0;

  .rs-modal-header {
    padding-bottom: 15px;
  }

  .rs-modal-body {
    padding: 10px 20px;
    background-color: $WHITE_COLOR;
    border: 1px solid $PRIMARY_DARK_BORDER_COLOR;

    ul {
      margin-bottom: 1rem;
    }
  }
}

.rs-modal-md {
  @include media-breakpoint-down(md) {
    max-width: 90%;
  }
}
