.selectable-product {
  width: 100%;
  background-color: $WHITE_COLOR;

  padding: 1rem;
  margin: 0;

  display: grid;
  gap: toRem(16);

  text-align: left;
  border: 1px solid $WHITE_COLOR;
  transition: 0.2s ease-out all;
  box-shadow: $CARD_BOX_SHADOW;

  cursor: pointer;

  position: relative;

  @include media-breakpoint-down(sm) {
    gap: toRem(8);
  }

  &:hover {
    border: 1px solid $SECONDARY_ORANGE_COLOR;
    transition: 0.2s ease-in all;
  }

  &.no-hover:hover {
    border: 1px solid $WHITE_COLOR;
  }

  .product-rx-tag {
    position: absolute;
    top: -1px;
    left: -1px;

    padding: 0.125rem 0.5rem;
    color: $PRIMARY_DARK_BLUE_COLOR;
    background-color: $PRIMARY_LIGHT_BLUE_COLOR;

    text-transform: uppercase;
    z-index: 10;

    font-family: $FONT_FUTURA_BOLD;
    font-size: 1rem;
  }

  .product-header {
    display: flex;
    justify-content: space-between;

    &.no-margin {
      margin: 0;
    }

    .header-content {
      display: flex;

      .header-img {
        width: 5.5rem;
        height: 5.5rem;
        object-fit: cover;

        @include media-breakpoint-down(sm) {
          width: 4rem;
          height: 4rem;
        }

        &.border-circle {
          border-radius: 50%;
        }
      }

      .header-text-wrapper {
        margin-left: 1rem;

        &.no-margin {
          margin: 0;
        }

        .header-tag {
          background-color: $PRIMARY_LIGHT_BLUE_COLOR;
          color: $PRIMARY_DARK_BLUE_COLOR;

          margin: 0;
          padding: 0 0.5rem;
        }

        .header-name-dosage {
          display: flex;
          align-items: center;
          gap: 10px;

          @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
            gap: 4px;
            margin-bottom: 4px;
          }

          .header-title {
            font-family: $FONT_FUTURA_MEDIUM;
            font-weight: 600;
            font-size: 1.25rem;
            letter-spacing: 0.9px;

            color: $PRIMARY_TEXT_COLOR;

            margin: 0;
            padding: 0;

            @include media-breakpoint-down(sm) {
              font-size: 1rem;
            }
          }

          .header-dosage {
            color: $PRIMARY_DARK_BLUE_COLOR;
            font-family: $FONT_FUTURA_BOLD;
            font-size: 0.875rem;
            line-height: 1.5rem;
            letter-spacing: 1.2px;

            background: $SECONDARY_BACKGROUND_COLOR;
            padding: 2px 8px 2px 8px;
            border-radius: 0.25rem;
            text-transform: uppercase;

            @include media-breakpoint-down(sm) {
              font-size: 0.7rem;
            }
          }
        }

        .header-blurb {
          background-color: $SECONDARY_LIGHT_BLUE_COLOR;
          color: $PRIMARY_DARK_BLUE_COLOR;

          font-family: $FONT_FUTURA_BOLD;
          font-weight: 600;
          font-size: 1rem;

          text-transform: uppercase;

          padding: 0.1rem 0.5rem;
          margin: 0.25rem 0;
        }

        .header-text {
          color: $PRIMARY_TEXT_COLOR;
          font-size: 1.1rem;

          margin: 0;
          padding: 0;

          .text-discounted-price {
            color: $SECONDARY_ORANGE_COLOR;
          }

          .text-price {
            &.price-strikethrough {
              text-decoration: line-through;
            }
          }

          @include media-breakpoint-down(md) {
            font-size: 1rem;
          }
        }

        .header-discount-badge {
          font-size: 0.7rem;
          font-family: $FONT_FUTURA_BOLD;
          line-height: 1.5rem;
          letter-spacing: 1.2px;

          color: $PRIMARY_DARK_BLUE_COLOR;
          background-color: $SECONDARY_LIGHT_BLUE_COLOR;
          border-radius: 4px;
          padding: 0.125rem 0.5rem;
          text-transform: uppercase;

          // badge should have a fix width
          max-width: 17.5rem;

          @include media-breakpoint-down(sm) {
            font-size: 0.6rem;
            letter-spacing: 1.1px;
          }

          // if not mobile, let it be %
          @include media-breakpoint-up(sm) {
            width: 100%;
            text-wrap: nowrap;
          }
        }
      }
    }

    .header-box-btn {
      width: 1.5rem;
      height: 1.5rem;

      padding: 0;
    }

    .header-outer-circle {
      width: 1.25rem;
      height: 1.25rem;
      border: 1px solid $SECONDARY_BORDER_COLOR;
      border-radius: 50%;

      @include media-breakpoint-down(md) {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .product-hug {
    display: inline-block;

    background-color: $PRIMARY_DARK_BLUE_COLOR;
    color: $WHITE_COLOR;
    font-family: $FONT_FUTURA_BOLD;
    font-weight: 600;
    font-size: 0.9rem;

    text-transform: uppercase;

    margin-bottom: toRem(16);
    padding: 0.25rem 0.5rem;
  }

  .progress-block {
    padding: 0;
    margin-top: toRem(16);

    .progress-bar-text-wrapper {
      margin: 0 0 1rem;
    }
  }

  .product-details-list {
    margin: 0;
    padding: 0 0 0 1.5rem;

    .list-text {
      color: $PRIMARY_TEXT_COLOR;
      font-size: 1.25rem;

      margin: 0;
      padding: 0;
    }
  }

  .product-text-blurb {
    background-color: $SECONDARY_LIGHT_BLUE_COLOR;
    color: $PRIMARY_DARK_BLUE_COLOR;

    font-family: $FONT_FUTURA_BOLD;
    font-weight: 600;
    font-size: 1rem;

    text-transform: uppercase;
    border-radius: 4px;

    padding: 0.1rem 0.5rem;
    margin: 0 0 0.25rem;
  }

  .product-text {
    font-size: 1rem;

    margin: 0;
    padding: 0;
  }

  .product-info-btn-link {
    background-color: inherit;
    color: $PRIMARY_DARK_BLUE_COLOR;
    font-size: 1rem;

    text-decoration: underline;

    padding: 0;

    justify-self: start;
    align-self: start;
    width: auto;
  }

  &.selected {
    border: 1px solid $SECONDARY_ORANGE_COLOR;

    .header-outer-circle {
      width: 0.85rem;
      height: 0.85rem;

      border: 1px solid $SECONDARY_ORANGE_COLOR;
      background-color: $SECONDARY_ORANGE_COLOR;

      box-shadow:
        0px 0px 0px 4px $WHITE_COLOR,
        0px 0px 0px 6px $SECONDARY_ORANGE_COLOR;

      position: relative;
      right: 0.25rem;
      top: 0.2rem;

      @include media-breakpoint-down(md) {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  &.edit-product {
    padding: 0.9rem;

    .product-header {
      display: block;

      .header-content {
        display: flex;
        justify-content: start;

        .header-img {
          @include media-breakpoint-down(sm) {
            width: 4rem;
            height: 4rem;
          }
        }

        .header-text-wrapper {
          width: 100%;

          @include media-breakpoint-down(sm) {
            margin-left: 0.6rem;
          }

          .header-select {
            display: flex;
            justify-content: space-between;

            .header-checkbox {
              width: 2rem;
              height: 2rem;
              position: absolute;
              right: 0.6rem;
              top: 0.6rem;

              .header-box-btn {
                width: 100%;
                height: 100%;
              }
            }

            .header-name-dosage {
              display: flex;
              align-items: center;
              gap: 10px;

              @include media-breakpoint-down(sm) {
                flex-wrap: wrap;
                gap: 4px;
                margin-bottom: 4px;
              }

              .header-title {
                font-family: $FONT_FUTURA_MEDIUM !important;
                font-size: 1.25rem !important;
                letter-spacing: 0.9px;

                color: $PRIMARY_TEXT_COLOR;

                margin: 0;
                padding: 0;
              }

              .header-dosage {
                color: $PRIMARY_DARK_BLUE_COLOR;
                font-family: $FONT_FUTURA_BOLD;
                font-size: 0.875rem;
                line-height: 1.5rem;
                letter-spacing: 1.2px;

                background: $SECONDARY_BACKGROUND_COLOR;
                padding: 2px 8px 2px 8px;
                border-radius: 0.25rem;
                text-transform: uppercase;
              }
            }
          }

          .header-text {
            @include media-breakpoint-down(sm) {
              font-size: 0.85rem;
            }
          }

          .product-info-btn-link {
            @include media-breakpoint-down(sm) {
              text-align: left;
              font-size: 0.85rem;
            }
          }
        }
      }
    }
  }

  .product-category-wrapper {
    margin: 1rem 0 0.5rem;

    .category-list-wrapper {
      display: flex;
      align-items: center;

      margin: 0 0 0.5rem;

      .list-icon {
        width: 13px;
        height: 13px;
      }

      .list-text {
        color: $PRIMARY_TEXT_COLOR;
        font-size: 1rem;

        margin: 0 0 0 0.5rem;
        padding: 0;
      }
    }
  }
}
