@import 'component-library/dist/scss/alloy';
// TODO: migrate to component library one day
.banner-section {
  padding: 0.5rem 0;
  background-color: $PRIMARY_LIGHT_BLUE_COLOR;
  position: relative;
  z-index: 1;

  .banner-text {
    margin: 0;
    padding: 0;
    font-size: 1.1rem;
    text-align: center;
    color: $PRIMARY_DARK_BLUE_COLOR;

    a {
      text-decoration: underline;
    }
  }

  p {
    margin: 0;
  }

  p + p {
    margin-top: toRem(8);
  }
}
