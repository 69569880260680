/*
    Sangbleu Kingdom
  */

@font-face {
  font-family: 'sangbleu-kingdom-regular';
  src: url('./sangbleu-kingdom/sangbleukingdom-regular.woff2') format('woff2');
  src: url('./sangbleu-kingdom/sangbleukingdom-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sangbleu-kingdom-bold';
  src: url('./sangbleu-kingdom/sangbleukingdom-bold.woff2') format('woff2');
  src: url('./sangbleu-kingdom/sangbleukingdom-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'sangbleu-kingdom-bold-italic';
  src: url('./sangbleu-kingdom/sangbleukingdom-bold-italic.woff2') format('woff2');
  src: url('./sangbleu-kingdom/sangbleukingdom-bold-italic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'sangbleu-kingdom-light';
  src: url('./sangbleu-kingdom/sangbleukingdom-light.woff2') format('woff2');
  src: url('./sangbleu-kingdom/sangbleukingdom-light.woff') format('woff');
  font-weight: lighter;
  font-style: normal;
}
