@import 'component-library/dist/scss/alloy';
.drawer-questionnaire-wrapper {
  display: grid;
  gap: toRem(24);

  .questionaire-form-wrapper {
    display: grid;
    gap: toRem(28);

    .content-question-wrapper {
      display: grid;
      gap: toRem(12);

      .input-field-wrapper {
        .field-checkbox-group {
          display: grid;
          grid-template-columns: 1fr;
          gap: toRem(8);

          .field-checkbox {
            margin: 0;
          }
        }

        .field-checkbox-group:has(> .field-checkbox-wrapper:nth-child(2):nth-last-child(1)) {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
}
