.generic-help {
  .container {
    padding-top: 3em;
  }

  .help-text {
    background-color: white;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 1.8em 0 1.8em 0;
    text-align: center;

    h2 {
      font-size: 1.1em;
      font-weight: bold;
    }

    p {
      margin: 5px 0 5px 0;
    }
  }
}
