@import 'component-library/dist/scss/alloy';
.checkout-section {
  padding-top: toRem(48);
  padding-bottom: 8rem;
  min-height: 100vh;

  .checkout-col-container {
    @include media-breakpoint-down(xs) {
      padding: 0;
    }
  }

  .ce-block-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $PRIMARY_BORDER_COLOR;

    padding: 0 0 1rem;

    &.no-padding {
      border-bottom: none;
      padding: 0;
    }

    .header-title {
      color: $BLACK_COLOR;
      font-family: $FONT_FUTURA_BOLD;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 1rem;

      margin: 0;
      padding: 0;
    }

    .header-number {
      background-color: $PRIMARY_DARK_BLUE_COLOR;
      color: $WHITE_COLOR;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
    }

    .primary-link-button {
      color: $SECONDARY_ORANGE_COLOR;
    }

    &.notification {
      margin-top: 2.5rem;
    }
  }

  .fade-wrapper {
    opacity: 0.01;
    transition: opacity 0.5s;

    &.fade-in-wrapper {
      opacity: 1;
    }

    &.fade-out-wrapper {
      opacity: 0.01;
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: 1.5rem;
    padding-bottom: 10rem;
  }
}
