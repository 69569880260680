@import 'component-library/dist/scss/alloy';
.review-drawer {
  .drawer-body {
    padding: 0 !important;

    .review-products-wrapper {
      padding: 1.5rem 1.5rem 0;

      .review-text {
        font-family: $FONT_SANGBLEU_REGULAR;
        font-size: 1.125rem;
        color: $PRIMARY_DARK_BLUE_COLOR;

        margin: 0 0 1.5rem;
        padding: 0;
      }
    }

    .review-divider {
      border-top: 1px solid $PRIMARY_LIGHT_BLUE_COLOR;
    }
  }
}
