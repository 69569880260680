@import 'component-library/dist/scss/alloy';
.product-review-card-block {
  margin: 0 0 2.5rem;

  box-shadow: $CARD_BOX_SHADOW;

  .product-img-wrapper {
    background-color: $PRIMARY_LIGHT_BLUE_COLOR;

    justify-content: center;
    align-items: center;
    text-align: center;

    padding: 1rem;

    .product-img {
      max-width: 180px;
    }
  }

  .product-rx-img-wrapper {
    position: relative;

    .product-img {
      max-width: 100%;
    }

    .rx-icon-img {
      position: absolute;
      top: 10px;
      right: 10px;

      z-index: 10;
    }
  }

  .product-content-wrapper {
    background-color: $WHITE_COLOR;

    padding: 1.5rem;

    .product-title {
      color: $PRIMARY_DARK_BLUE_COLOR;
      font-family: $FONT_FUTURA_BOLD;
      font-weight: 600;
      font-size: 1.375rem;

      padding: 0;
      margin: 0;
    }

    .product-subtitle {
      color: $PRIMARY_DARK_BLUE_COLOR;
      font-family: $FONT_FUTURA_MEDIUM;
      font-weight: 500;
      font-size: 1.125rem;

      padding: 0;
      margin: 0 0 0.5rem;
    }

    .product-text {
      color: $PRIMARY_TEXT_COLOR;
      font-size: 1.25rem;

      padding: 0;
      margin: 0 0 1rem;
    }

    .product-category-wrapper {
      .category-list-wrapper {
        display: flex;
        align-items: center;

        margin: 0 0 0.25rem;

        .list-icon {
          width: 13px;
          height: 13px;
        }

        .list-text {
          color: $PRIMARY_TEXT_COLOR;
          font-size: 1rem;

          margin: 0 0 0 0.5rem;
          padding: 0;
        }
      }
    }

    .product-button-wrapper {
      text-align: center;

      .primary-button {
        width: 100%;

        margin-bottom: 1rem;
      }

      .primary-link-button {
        font-size: 1.125rem;
      }
    }
  }
}
