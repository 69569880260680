@import 'component-library/dist/scss/alloy';
.manage-totals-block {
  .total-wrapper {
    padding: 0 0 1.5rem;
    border-bottom: 1px solid $SECONDARY_BORDER_COLOR;
    display: flex;
    align-items: center;

    cursor: pointer;

    .total-title {
      font-family: $FONT_FUTURA_MEDIUM;
      font-size: 1rem;
      font-weight: 500;
      flex: 1;

      color: $PRIMARY_TEXT_COLOR;

      margin: 0;
      padding: 0;
    }

    .total-amount {
      font-family: $FONT_FUTURA_MEDIUM;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;

      color: $PRIMARY_TEXT_COLOR;

      margin: 0;
      padding: 0;

      img {
        width: 1rem;
        margin-left: 0.5rem;
        transition: 0.6s ease;
      }
    }
  }

  .total-summary-wrapper {
    display: none;

    padding-bottom: 0.75rem;
    border-bottom: 1px solid $SECONDARY_BORDER_COLOR;

    .summary-row {
      align-items: center;
      display: flex;

      margin-bottom: 0.5rem;

      .summary-title {
        flex: 1;
      }

      .summary-title,
      .summary-content {
        margin: 0;
        padding: 0;

        color: $PRIMARY_TEXT_COLOR;

        font-size: 1rem;
      }
    }
  }

  &.active-summary {
    .total-wrapper {
      border-bottom: none;
    }

    .total-amount img {
      transform: rotate(180deg);
    }

    .total-summary-wrapper {
      display: block;
    }
  }
}
