.checkout-experience-section {
  padding-top: 4rem;
  padding-bottom: 8rem;

  .checkout-experience-content-wrapper {
    padding: 0 2.5rem;

    .content-title {
      color: $PRIMARY_DARK_BLUE_COLOR;
      font-family: $FONT_SANGBLEU_REGULAR;
      font-weight: 400;
      font-size: 2rem;
      line-height: 2.375rem;

      margin: 0 0 0.5rem;
      padding: 0;

      @include media-breakpoint-down(md) {
        font-size: 1.375rem;
        line-height: 1.875rem;
      }
    }

    .content-text {
      color: $PRIMARY_DARK_BLUE_COLOR;
      font-family: $FONT_FUTURA_BOOK;
      font-size: 1rem;

      margin: 0 0 1rem;
      padding: 0;
    }

    .content-text-md {
      @extend .content-text;

      font-size: 1.25rem;
    }

    .content-text-bold {
      @extend .content-text;

      font-family: $FONT_FUTURA_MEDIUM;
      font-weight: 500;
      font-size: 1.125rem;
    }

    .content-link {
      @extend .content-text;

      text-decoration: underline;
    }

    .content-blurb {
      background-color: $PRIMARY_LIGHT_BLUE_COLOR;
      color: $PRIMARY_DARK_BLUE_COLOR;
      font-size: 1rem;

      margin: 1rem 0;
      padding: 1rem;
    }

    .input-field-wrapper {
      margin: 0 0 1rem;

      // used for relief type atm
      .field-image-checkbox-group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: toRem(12);

        .field-checkbox {
          margin: 0;
        }

        @include media-breakpoint-down(md) {
          grid-template-columns: 1fr;
        }
      }

      .field-label {
        color: $PRIMARY_TEXT_COLOR;
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 500;
        font-size: 0.9rem;
        letter-spacing: 0.0625rem;
        text-transform: uppercase;
        line-height: normal;

        margin-bottom: 0.5rem;
        padding: 0;
      }

      .field-password-check {
        color: $PRIMARY_DARK_BLUE_COLOR;
        font-size: 1rem;
      }
    }

    .content-consent-wrapper {
      display: flex;
      margin: 0 0 1rem;

      .consent-checkbox-btn {
        background-color: inherit;

        .checkbox-icon {
          width: 1.5rem;
          height: 1.5rem;

          padding: 0;
          margin: 0;
        }
      }

      .consent-text {
        color: $PRIMARY_DARK_GREY_COLOR;
        font-family: $FONT_FUTURA_BOOK;
        font-size: 1rem;

        margin: 0;
        padding: 0.4rem 0 0;
      }

      .consent-link {
        @extend .consent-text;

        text-decoration: underline;
      }
    }

    .content-resend-code-wrapper {
      border: 1px solid $PRIMARY_LIGHT_BLUE_COLOR;
      padding: 1rem;
      margin: 0 0 1rem;

      .resend-code-title {
        color: $PRIMARY_DARK_BLUE_COLOR;
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 500;
        font-size: 1.125rem;

        margin: 0;
        padding: 0;
      }

      .resend-code-text {
        color: $PRIMARY_DARK_BLUE_COLOR;
        font-family: $FONT_FUTURA_BOOK;
        font-size: 1rem;

        margin: 0;
        padding: 0;
      }

      .primary-link-button {
        color: $SECONDARY_ORANGE_COLOR;
        font-size: 1rem;
      }
    }

    .content-header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 1rem;

      .header-title {
        color: $PRIMARY_DARK_BLUE_COLOR;
        font-family: $FONT_SANGBLEU_REGULAR;
        font-weight: 400;
        font-size: 2rem;

        line-height: 1.1;

        margin: 0;
        padding: 0;
      }
    }

    .content-product-list {
      margin-bottom: 2rem;
    }

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  .content-video-wrapper {
    video {
      width: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: 3rem;
  }
}
