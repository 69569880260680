@import 'component-library/dist/scss/alloy';
.referral-block {
  background-color: $PRIMARY_DARK_BLUE_COLOR;
  color: $WHITE_COLOR;
  padding: 1.5rem;

  margin: 0 0 1.5rem;

  .referral-tag {
    color: $PRIMARY_LIGHT_BLUE_COLOR;
    font-family: $FONT_FUTURA_MEDIUM;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 0.8125rem;

    margin: 0 0 1rem;
    padding: 0;
  }

  .referral-title {
    font-family: $FONT_SANGBLEU_EMPIRE_BLACK_ITALIC;
    font-weight: bold;
    font-style: italic;
    font-size: 1.9375rem;

    margin: 0 0 1rem;
    padding: 0;
  }

  .referral-text {
    margin: 0 0 1.5rem;
    padding: 0;
  }

  .referral-code-field {
    background-color: $WHITE_COLOR;
    border: 1px solid $PRIMARY_BORDER_COLOR;
    color: $PRIMARY_DARK_GREY_COLOR;

    margin: 0 0 1rem;
    padding: 0.75rem 1rem;
  }

  .secondary-button {
    width: 100%;
  }
}
