.field-checkbox {
  display: flex;
  background-color: $WHITE_COLOR;
  border: 1px solid $PRIMARY_BORDER_COLOR;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
  margin: 0 0 0.5rem;

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  padding: 1.25rem 1rem;

  transition: 0.2s ease-out all;

  @include hover-focus {
    border: 1px solid $SECONDARY_ORANGE_HOVER_COLOR;
  }

  &.markdown {
    border: 0px;
  }

  &.selected {
    border: 1px solid $SECONDARY_ORANGE_HOVER_COLOR;
  }

  &.checkbox-single {
    border: none;
    border-radius: 0;

    align-items: normal;

    margin: 0;
    padding: 0;

    @include hover-focus {
      border: none;
    }
  }

  .checkbox-select-icon {
    width: 1.3rem;
    height: 1.3rem;
    padding: 0;
  }

  .checkbox-text {
    color: $PRIMARY_TEXT_COLOR;
    font-family: $FONT_FUTURA_BOOK;
    font-size: toRem(16);
    margin: 0 0 0 toRem(12);
    padding: 0;
  }

  &.with-title {
    align-items: start;
  }

  .checkbox-title {
    color: $PRIMARY_TEXT_COLOR;
    font-family: $FONT_FUTURA_BOLD;
    font-size: toRem(16);
    margin: 0 0 0 toRem(12);
    padding: 0;
  }
}
