@import 'component-library/dist/scss/alloy';
.new-products-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  padding: toRem(16) toRem(32);
  gap: toRem(12);
  color: $WHITE_COLOR;

  @include media-breakpoint-up(md) {
    padding: toRem(36) toRem(48);
    gap: toRem(8);
  }

  &.new-products-theme-dark {
    background-image: url('../../assets/images/swirl-bg-dark-mobile.png');
    background-size: cover;
    @include media-breakpoint-up(md) {
      background-image: url('../../assets/images/swirl-bg-dark-desktop.png');
    }
  }
  &.new-products-theme-light {
    background-image: url('../../assets/images/swirl-bg-light-mobile.png');
    background-size: cover;
    background-position: center;
    color: $PRIMARY_TEXT_COLOR;
    margin-top: toRem(10);
    .new-products-title {
      color: $PRIMARY_TEXT_COLOR;
    }
    @include media-breakpoint-up(md) {
      background-image: url('../../assets/images/swirl-bg-light-desktop.png');
    }
  }

  .new-products-title {
    color: $WHITE_COLOR;
    font-family: $FONT_FUTURA_BOLD;
    font-size: toRem(28);

    @include media-breakpoint-up(md) {
      font-size: toRem(38);
    }
  }

  .new-products-link {
    font-family: $FONT_FUTURA_BOLD;
    background-color: transparent;
    font-size: toRem(14);
    text-decoration: underline;
    text-underline-offset: 4px;
    @include media-breakpoint-up(lg) {
      font-size: toRem(16);
    }
  }
}
