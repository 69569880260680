@import 'component-library/dist/scss/alloy';
.edit-product-wrapper {
  display: grid;
  gap: toRem(24);

  .product-btns-block {
    display: flex;
    gap: toRem(16);

    button {
      width: 100%;
    }

    .primary-button {
      border: 1px solid $SECONDARY_ORANGE_COLOR;
    }
  }

  .product-discount-block {
    display: grid;
    gap: toRem(8);

    border: 1px solid $PRIMARY_LIGHT_BLUE_COLOR;

    padding: toRem(16);

    .discount-title {
      font-family: $FONT_FUTURA_MEDIUM;
      font-weight: 500;
      font-size: toRem(20);
      line-height: toRem(28);

      color: $PRIMARY_TEXT_COLOR;

      margin: 0;
      padding: 0;

      display: flex;
      align-items: center;

      .title-icon {
        margin-right: toRem(8);
      }
    }

    .discount-text {
      font-size: toRem(20);
      line-height: toRem(28);

      color: $PRIMARY_TEXT_COLOR;

      margin: 0;
      padding: 0;
    }
  }
}
