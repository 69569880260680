@import 'component-library/dist/scss/alloy';
.ce-payment-options-block {
  display: grid;
  gap: toRem(16);

  padding: 1rem 0 0;

  .payment-other-options-wrapper {
    display: grid;
    gap: toRem(16);
  }
}
