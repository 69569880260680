.notistack-SnackbarContainer {
  z-index: 100010 !important;
}

.MuiIconButton-root[aria-label='Close'] .MuiSvgIcon-root {
  fill: $PRIMARY_TEXT_COLOR;
}

.alert-icon {
  width: 24px;
  height: 24px;
}
