@import 'component-library/dist/scss/alloy';
.review-carousel-wrapper {
  width: 100%;

  @include media-breakpoint-down(md) {
    text-align: center;
  }
}

.review-carousel-title {
  margin-bottom: toRem(8);
  line-height: toRem(72);

  @include media-breakpoint-down(md) {
    font-size: toRem(40);
    line-height: toRem(56);
  }
}
