@import 'component-library/dist/scss/alloy';
.selectable-option-product {
  .product-option-header {
    display: flex;
    justify-content: space-between;

    margin: 0 0 1rem;

    .option-content-wrapper {
      .option-title {
        color: $PRIMARY_TEXT_COLOR;
        font-family: $FONT_FUTURA_BOLD;
        font-weight: 600;
        font-size: 1.375rem;

        margin: 0;
        padding: 0;
      }

      .option-text {
        color: $PRIMARY_TEXT_COLOR;
        font-size: 1.25rem;

        margin: 0;
        padding: 0;
      }
    }

    .header-box-btn {
      width: 1.5rem;
      height: 1.5rem;
      padding: 0;
    }
  }

  .product-options-wrapper {
    margin: 0 0 1.5rem;

    .option-btn {
      background-color: inherit;
      border: 1px solid $PRIMARY_BORDER_COLOR;
      border-radius: 2px;

      color: $PRIMARY_TEXT_COLOR;

      font-family: $FONT_FUTURA_MEDIUM;
      font-weight: 500;
      font-size: 1.25rem;

      margin: 0 0.5rem 0 0;
      padding: 0.25rem 0.5rem;

      &.selected {
        border: 2px solid $SECONDARY_ORANGE_COLOR;
      }
    }
  }

  .product-content-wrapper {
    position: relative;
  }
}
