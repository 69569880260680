@import 'component-library/dist/scss/alloy';
.payment-drawer {
  .payment-method-wrapper {
    padding: 0.5rem 0;
    border-bottom: 1px solid $SECONDARY_BORDER_COLOR;

    .payment-method-text {
      font-size: 1.2rem;
      margin: 0;
      padding: 0;

      &.default {
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 500;
      }
    }
  }

  .payment-element-wrapper {
    padding: 1.5rem 0 0;
  }

  .primary-link-button {
    font-size: 1.2rem;

    &.secondary {
      display: flex;
      text-align: center;

      margin: 1rem auto 0;
    }
  }
}
