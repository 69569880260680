// stylelint-disable declaration-no-important, selector-list-comma-newline-after

//
// Headings
//

h1,
h2,
.h1,
.h2 {
  margin-bottom: 0;
  font-weight: bold;
  font-family: $FONT_SANGBLEU_BOLD;
  line-height: 1.2;
  color: $PRIMARY_DARK_BLUE_COLOR;
}

h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6 {
  font-family: $FONT_FUTURA_BOOK;
  font-weight: normal;
}

// .neonBlue {
//   color: #fff;
//   // animation: blueBlue 1.5s ease-out infinite;
//   text-shadow: 0px 0px 10px $neonBlue, 0px 0px 14px $neonBlue;
// }

.neonBlue {
  padding: 25px 0;
  font-size: 8rem !important;
  color: #228dff;
  // line-height: 1;
  animation: neon 1.5s ease-in-out infinite alternate;
}

@keyframes blueBlue {
  from {
    text-shadow:
      0px 0px 5px #fff,
      0px 0px 5px #fff,
      0px 0px 17px #fff,
      0px 0px 17px #fff,
      0px 0px 17px #fff,
      0px 0px 17px #fff,
      0px 0px 17px #fff,
      0px 0px 17px #fff,
      0px 0px 25px #fff,
      0px 0px 25px #fff,
      0px 0px 25px #0070ff,
      0px 0px 75px #0070ff,
      0px 5px 50px #0070ff,
      0px 5px 50px #0070ff,
      0px 5px 50px #0070ff,
      0px 5px 50px #0070ff,
      0px -5px 50px #0070ff,
      0px -5px 50px #0070ff;
  }
}

@keyframes neon {
  from {
    text-shadow:
      0 0 2px #fff,
      0 0 4px #fff,
      0 0 8px #fff,
      0 0 16px #228dff,
      0 0 25px #228dff,
      0 0 35px #228dff,
      0 0 40px #228dff,
      0 0 50px #228dff;
  }
  to {
    text-shadow:
      0 0 0px #fff,
      0 0 2px #fff,
      0 0 8px #fff,
      0 0 8px #228dff,
      0 0 12px #228dff,
      0 0 20px #228dff,
      0 0 25px #228dff,
      0 0 40px #228dff;
  }
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
  color: $PRIMARY_TEXT_COLOR;
}
