@import 'component-library/dist/scss/alloy';
.profile-content-block {
  background-color: $WHITE_COLOR;
  border: 1px solid rgba(#000000, 0.16);

  padding: toRem(36) toRem(38);
  margin: 0 0 toRem(24);

  .content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-title {
      color: $PRIMARY_TEXT_COLOR;
      font-family: $FONT_FUTURA_BOLD;
      font-size: toRem(22);
      font-weight: 600;

      margin: 0;
      padding: 0;
    }
  }

  .content-row {
    padding: toRem(8) 0;
    color: $PRIMARY_TEXT_COLOR;

    .content-title {
      font-family: $FONT_FUTURA_MEDIUM;
      font-size: toRem(18);
      font-weight: 500;

      margin: 0;
      padding: 0;
    }

    .content-text {
      font-size: toRem(18);

      margin: 0;
      padding: 0;
    }
  }

  @include media-breakpoint-down(md) {
    padding: toRem(24) toRem(16);
  }
}
