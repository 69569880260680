@import 'component-library/dist/scss/alloy';
.referrals-credits-block {
  text-align: center;

  border: 1px solid $PRIMARY_LIGHT_BLUE_COLOR;

  margin-top: toRem(24);
  padding: toRem(40);

  .credits-title {
    font-family: $FONT_FUTURA_BOLD;
    font-weight: bold;
    font-size: toRem(18);
    line-height: toRem(20);

    color: $PRIMARY_DARK_BLUE_COLOR;

    text-transform: uppercase;

    margin: 0 0 toRem(32);
    padding: 0;
  }

  .credits-wrapper {
    display: flex;

    justify-content: space-between;

    width: 350px;
    max-width: 100%;

    text-align: left;

    margin: 0 auto toRem(32);

    .wrapper-col {
      .wrapper-title {
        font-family: $FONT_SANGBLEU_BOLD;
        font-weight: bold;
        font-size: toRem(48);
        line-height: toRem(64);

        color: $PRIMARY_DARK_BLUE_COLOR;

        margin: 0 0 toRem(8);
        padding: 0;
      }

      .wrapper-text {
        font-family: $FONT_FUTURA_BOLD;
        font-weight: bold;
        font-size: toRem(14);
        line-height: toRem(24);

        color: $PRIMARY_DARK_BLUE_COLOR;
        text-transform: uppercase;

        margin: 0;
        padding: 0;
      }
    }

    .wrapper-divider {
      background-color: $PRIMARY_BORDER_COLOR;

      width: 1px;
    }
  }

  .credits-text {
    font-family: $FONT_FUTURA_BOOK;
    font-weight: 400;
    font-size: toRem(20);
    line-height: toRem(28);

    color: $PRIMARY_DARK_GREY_COLOR;

    margin: 0;
    padding: 0;
  }
}
