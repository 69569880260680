@import 'component-library/dist/scss/alloy';
.improvement-block {
  background-color: $PRIMARY_DARK_BLUE_COLOR;
  color: $WHITE_COLOR;

  margin: 0;
  padding: 2.5rem;

  text-align: center;

  .improvement-tag {
    font-family: $FONT_FUTURA_BOLD;
    font-size: 0.8rem;
    text-transform: uppercase;

    margin: 0 0 0.5rem;
    padding: 0;
  }

  .improvement-title {
    font-family: $FONT_SANGBLEU_BOLD_ITALIC;
    font-size: 2rem;
    font-style: italic;
    font-weight: bold;

    margin: 0 0 0.75rem;
    padding: 0;
  }

  .improvement-text {
    font-family: $FONT_FUTURA_MEDIUM;

    margin: 0 0 1rem;
    padding: 0;
  }
}
