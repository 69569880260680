@import 'component-library/dist/scss/alloy';
.products-list-wrapper {
  margin: toRem(32) 0 0;

  &.disable {
    opacity: 0.5;
  }

  .product-block {
    display: flex;
    justify-content: space-between;

    + .product-block {
      margin: toRem(32) 0 0;

      &.add-border {
        border-top: 1px solid $PRIMARY_BORDER_COLOR;
        padding: toRem(32) 0 0;
      }

      @include media-breakpoint-down(sm) {
        &.add-border {
          margin: toRem(40) 0 0;
          border: none;
          padding: 0;
        }
      }
    }

    .product-img-content-wrapper {
      display: flex;

      .product-img-wrapper {
        position: relative;

        .product-rx-tag {
          position: absolute;
          top: 0;
          left: 0;

          font-family: $FONT_FUTURA_BOLD;
          font-weight: 600;
          font-size: toRem(12);
          line-height: toRem(24);

          background-color: $PRIMARY_LIGHT_BLUE_COLOR;
          color: $PRIMARY_DARK_BLUE_COLOR;

          text-transform: uppercase;

          padding: toRem(2) toRem(8);
        }

        .product-img {
          width: 100px;
          height: 100px;
          object-fit: cover;
        }
      }

      .product-content-wrapper {
        margin-left: toRem(16);

        .content-title {
          font-family: $FONT_FUTURA_BOLD;
          font-weight: 600;
          font-size: toRem(22);
          line-height: toRem(28);

          color: $PRIMARY_DARK_BLUE_COLOR;

          margin: 0 0 toRem(4);
          padding: 0;

          display: flex;
          align-items: center;

          + .content-title {
            margin-top: toRem(4);
          }

          .title-dosage-tag {
            background-color: $SECONDARY_BACKGROUND_COLOR;
            color: $PRIMARY_DARK_BLUE_COLOR;
            border-radius: toRem(4);

            font-family: $FONT_FUTURA_BOLD;
            font-weight: bold;
            font-size: toRem(14);
            line-height: toRem(24);

            margin-left: toRem(8);
            padding: toRem(2) toRem(8);
          }

          @include media-breakpoint-down(sm) {
            font-family: $FONT_FUTURA_MEDIUM;
            font-weight: 500;
            font-size: toRem(20);
          }
        }

        .content-text {
          font-family: $FONT_FUTURA_BOOK;
          font-weight: 400;
          font-size: toRem(20);
          line-height: toRem(28);

          color: $PRIMARY_DARK_BLUE_COLOR;

          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: toRem(4);

          margin: 0;
          padding: 0;

          + .content-text {
            margin-top: toRem(4);
          }

          .text-tag {
            font-family: $FONT_FUTURA_BOLD;
            font-weight: bold;
            font-size: toRem(12);
            line-height: toRem(24);

            background-color: $LIGHT_YELLOW_COLOR;
            color: $PRIMARY_DARK_BLUE_COLOR;

            text-transform: uppercase;
            border-radius: toRem(4);

            margin-left: toRem(8);
            padding: toRem(2) toRem(8);

            &.no-margin {
              margin: 0;
            }
          }

          .text-orange {
            color: $SECONDARY_ORANGE_COLOR;
          }

          .text-strike-through {
            text-decoration: line-through;
          }
        }

        .content-tag {
          background-color: $SECONDARY_LIGHT_BLUE_COLOR;
          color: $PRIMARY_DARK_BLUE_COLOR;
          border-radius: toRem(4);

          font-family: $FONT_FUTURA_BOLD;
          font-weight: bold;
          font-size: toRem(14);
          line-height: toRem(24);

          width: fit-content;

          margin: 0 0 toRem(4);
          padding: toRem(2) toRem(8);
        }
      }
    }

    .product-action-wrapper {
      .action-link {
        width: fit-content;
        text-align: left;

        font-family: $FONT_FUTURA_BOLD;
        font-weight: bold;
        font-size: toRem(15);
        line-height: toRem(17);
        letter-spacing: toRem(1);

        background-color: inherit;
        color: $PRIMARY_DARK_BLUE_COLOR;
        border-bottom: 2px solid $PRIMARY_DARK_BLUE_COLOR;

        text-transform: uppercase;

        margin: 0;
        padding: 0 0 0.1rem;
      }
    }

    @include media-breakpoint-down(sm) {
      display: grid;
      gap: toRem(16);
    }
  }
}
