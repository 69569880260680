/*
 * COLORS
 */

// Basic
$WHITE_COLOR: #fff;
$BLACK_COLOR: #000;

$RED_COLOR: red;

// Background
$PRIMARY_BACKGROUND_COLOR: #fdf9f4;
$SECONDARY_BACKGROUND_COLOR: #f5f0e9;

// Blue
$PRIMARY_LIGHT_BLUE_COLOR: #c5d8f8;
$SECONDARY_LIGHT_BLUE_COLOR: #eef4fd;

$PRIMARY_DARK_BLUE_COLOR: #09063b;

// Text
$PRIMARY_TEXT_COLOR: #252421;

// Green
$PRIMARY_GREEN_COLOR: #3b7d29;
$PRIMARY_DARK_GREEN_COLOR: #606546;

// Orange
$SECONDARY_ORANGE_COLOR: #f6460b;
$SECONDARY_ORANGE_HOVER_COLOR: #fc5903;
$SECONDARY_DARK_ORANGE_HOVER_COLOR: #de3911;

// Yellow
$LIGHT_YELLOW_COLOR: #ffedcb;

// Grey
$PRIMARY_DARK_GREY_COLOR: #686561;
$PRIMARYT_LIGHT_GREY_COLOR: #eeeeee;

// Border
$LIGHT_BANNER_BORDER_COLOR: #e8e2dd;
$PRIMARY_BORDER_COLOR: #bebdbc;
$PRIMARY_DARK_BORDER_COLOR: #707070;
$SECONDARY_BORDER_COLOR: #00000029;

$PRIMARY_CARD_BORDER_COLOR: rgba(#000000, 0.16);

/*
 * Z-INDEX
 */
$BAR_Z_INDEX: 999;

/*
 * FONTS
 */

$FONT_SANGBLEU_REGULAR: 'sangbleu-kingdom-regular', serif;
$FONT_SANGBLEU_BOLD: 'sangbleu-kingdom-bold', serif;
$FONT_SANGBLEU_BOLD_ITALIC: 'sangbleu-kingdom-bold-italic', serif;
$FONT_SANGBLEU_LIGHT: 'sangbleu-kingdom-light', serif;

$FONT_SANGBLEU_EMPIRE_BLACK: 'sangbleu-empire-black', serif;
$FONT_SANGBLEU_EMPIRE_BLACK_ITALIC: 'sangbleu-empire-black-italic', serif;

$FONT_FUTURA_BOOK: 'futura-pt-book', sans-serif;
$FONT_FUTURA_BOLD: 'futura-pt-bold', sans-serif;
$FONT_FUTURA_MEDIUM: 'futura-pt-medium', sans-serif;
$FONT_FUTURA_LIGHT: 'futura-pt-light', sans-serif;

$CARD_BOX_SHADOW: 0px 2px 7px 0px rgba(0, 0, 0, 0.12);
