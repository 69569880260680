.tp-shipping-method-block {
  .tp-shipping-method-wrapper {
    padding: 0.5rem 0 1rem;
    border-bottom: 1px solid $PRIMARY_BORDER_COLOR;

    cursor: pointer;

    &.tp-hide-border {
      @include media-breakpoint-down(md) {
        padding-bottom: 0.5rem;
        border-bottom: none;
      }
    }

    .shipping-content-header {
      display: flex;
      justify-content: space-between;

      .shipping-title {
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 500;
        font-size: 1.125rem;

        margin: 0 0 0.5rem;
        padding: 0;
      }

      .shipping-chevron {
        background-color: inherit;
        text-align: right;
        transition: 0.6s ease;
      }
    }

    .shipping-content {
      display: flex;
      justify-content: space-between;

      .shipping-text {
        font-size: 1rem;

        margin: 0;
        padding: 0;
      }

      .shipping-text-bold {
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 500;
        font-size: 1rem;

        text-transform: uppercase;

        margin: 0;
        padding: 0;
      }
    }
  }

  .tp-shipping-options-wrapper {
    display: none;

    .shipping-option {
      display: flex;
      align-items: center;

      border-radius: 0.375rem;

      margin-bottom: 1rem;

      cursor: pointer;

      .option-content {
        flex: 1;
        display: flex;
        margin: 0 0 0 0.5rem;
        justify-content: space-between;

        .option-title {
          font-size: 1rem;
          line-height: 1.625rem;

          margin: 0;
          padding: 0;
        }

        .option-price {
          font-family: $FONT_FUTURA_MEDIUM;
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.625rem;
          text-transform: uppercase;

          margin: 0;
          padding: 0;
        }
      }

      .option-outer-circle {
        width: 1.5rem;
        height: 1.5rem;
        border: 1px solid $SECONDARY_BORDER_COLOR;
        border-radius: 50%;
      }

      &.selected {
        .option-outer-circle {
          width: 1rem;
          height: 1rem;

          border: 1px solid $SECONDARY_ORANGE_COLOR;
          background-color: $SECONDARY_ORANGE_COLOR;

          box-shadow:
            0px 0px 0px 4px $WHITE_COLOR,
            0px 0px 0px 6px $SECONDARY_ORANGE_COLOR;

          position: relative;
          left: 0.25rem;
        }

        .option-content {
          position: relative;

          padding-left: 0.5rem;
        }
      }
    }
  }

  &.tp-active-shipping {
    .tp-shipping-method-wrapper {
      border-bottom: none;

      .shipping-chevron {
        transform: rotate(180deg);
      }
    }

    .tp-shipping-options-wrapper {
      display: block;

      border-bottom: 1px solid $PRIMARY_BORDER_COLOR;
    }
  }
}
