@import 'component-library/dist/scss/alloy';
.skincare-content-wrapper {
  background-color: $WHITE_COLOR;

  box-shadow: $CARD_BOX_SHADOW;

  .content-divider {
    height: 1px;
    background-color: $PRIMARY_BORDER_COLOR;
  }

  .content-inner-wrapper {
    padding: toRem(24);

    display: grid;
    gap: toRem(24);

    .content-info-block {
      display: grid;
      gap: toRem(8);

      .info-title {
        font-family: $FONT_FUTURA_BOLD;
        font-weight: bold;
        font-size: toRem(22);
        line-height: toRem(26);

        color: $PRIMARY_DARK_BLUE_COLOR;

        margin: 0;
        padding: 0;
      }

      .info-text {
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: bold;
        font-size: toRem(20);
        line-height: toRem(28);

        color: $PRIMARY_TEXT_COLOR;

        margin: 0;
        padding: 0;

        .text-discounted-price {
          color: $SECONDARY_ORANGE_COLOR;
        }

        .text-price {
          &.price-strikethrough {
            text-decoration: line-through;
          }
        }
      }

      .info-tag {
        font-family: $FONT_FUTURA_BOLD;
        font-weight: bold;
        font-size: toRem(14);
        line-height: toRem(14);

        background-color: $PRIMARY_LIGHT_BLUE_COLOR;
        color: $PRIMARY_DARK_BLUE_COLOR;

        margin: 0;
        padding: toRem(8) toRem(9);

        border-radius: toRem(4);

        justify-self: start;
        align-self: start;
        width: auto;
      }

      .info-blurb {
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 450;
        font-size: toRem(16);
        line-height: toRem(24);

        background-color: $PRIMARY_LIGHT_BLUE_COLOR;
        color: $PRIMARY_DARK_BLUE_COLOR;

        text-align: center;

        margin: 0;
        padding: toRem(4) toRem(8);

        @include media-breakpoint-down(sm) {
          border-radius: 4px;
        }
      }
    }

    .selectable-product {
      padding: 0;

      border: 1px solid $WHITE_COLOR;
      box-shadow: none;

      &.selected {
        border: 1px solid $WHITE_COLOR;
      }
    }

    @include media-breakpoint-down(sm) {
      padding: toRem(24) toRem(16);
    }
  }
}
