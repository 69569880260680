@import 'component-library/dist/scss/alloy';
.empty-state-block {
  background-color: $WHITE_COLOR;

  .empty-state-img {
    width: 100%;
  }

  .empty-state-content {
    padding: 1.5rem;

    .content-title {
      color: $PRIMARY_DARK_BLUE_COLOR;
      font-family: $FONT_FUTURA_BOLD;
      font-weight: bold;
      font-size: 1.375rem;

      margin: 0 0 0.25rem;
      padding: 0;
    }

    .content-subtitle {
      color: $PRIMARY_DARK_BLUE_COLOR;
      font-family: $FONT_FUTURA_MEDIUM;
      font-weight: 450;
      font-size: 1.25rem;

      margin: 0 0 0.25rem;
      padding: 0;
    }

    .content-list {
      padding-inline-start: 30px;
    }
  }
}
