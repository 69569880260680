@import 'component-library/dist/scss/alloy';
.dashboard-tp-testimonials-section {
  background-color: $PRIMARY_DARK_BLUE_COLOR;
  color: $WHITE_COLOR;
  padding: 2rem 0;

  border-bottom: 1px solid $PRIMARY_LIGHT_BLUE_COLOR;

  .btn-arrow {
    background-color: inherit;

    .testimonials-arrow-icon {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  .testimonials-content-wrapper {
    .testimonials-title {
      font-family: $FONT_SANGBLEU_REGULAR;
      font-size: 1.375rem;

      margin: 0 0 0.5rem;
      padding: 0;
    }

    .testimonials-quote-icon {
      margin: 0 0 1rem;
    }

    .testimonials-text {
      margin: 0 0 0.5rem;
      padding: 0;
    }

    .testimonials-author {
      margin: 0;
      padding: 0;
    }
  }

  .testimonials-pagination-wrapper {
    display: none;

    .pagination-dot {
      background-color: $WHITE_COLOR;
      width: 0.75rem;
      height: 0.75rem;
      margin: 0 0.25rem;

      border-radius: 50%;

      &.active {
        background-color: $SECONDARY_ORANGE_COLOR;
      }
    }

    @include media-breakpoint-down(md) {
      margin-top: 1rem;
      justify-content: center;
      display: flex;
    }
  }

  @include media-breakpoint-down(md) {
    border-bottom: none;
  }

  .container {
    .row {
      .col-1 {
        max-width: 100%;
      }
    }
  }
}
