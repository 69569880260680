.video-modal {
  .video-modal-block-loader {
    text-align: center;
    padding: 6rem 0;
  }

  .rs-modal-dialog {
    .rs-modal-content {
      .rs-modal-body {
        overflow-x: hidden !important;
      }
    }
  }

  .video-modal-block {
    .video-title {
      color: $PRIMARY_DARK_BLUE_COLOR;
      font-family: $FONT_SANGBLEU_REGULAR !important;
      font-weight: 400;
      font-size: 2rem;
      margin: 0 0 1rem;
      padding: 0;
    }

    .video-text {
      color: $PRIMARY_DARK_BLUE_COLOR;
      font-size: 1rem;
      line-height: 1.625rem;

      margin: 0 0 1.5rem;

      &.bold {
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 600;
      }
    }

    .video-btn-block {
      display: block;
      text-align: center;

      .btn-video,
      .btn-video-upload .rs-uploader-trigger-btn {
        display: block;
        font-family: $FONT_FUTURA_BOLD;
        font-weight: 700;
        font-size: 0.8125rem;
        letter-spacing: 0.09rem;
        padding: 0.75rem 2rem;
        text-transform: uppercase;
        transition: 0.2s ease-out all;

        border-radius: 0px !important;

        margin: 0 auto 1rem;
      }

      .btn-video-upload .rs-uploader-trigger-btn,
      .btn-video-primary {
        color: $WHITE_COLOR;
        background-color: $SECONDARY_ORANGE_COLOR;

        @include hover-focus {
          color: $WHITE_COLOR;
          background-color: $SECONDARY_DARK_ORANGE_HOVER_COLOR;
        }

        img {
          width: 1rem;
          height: 1rem;
        }
      }

      .btn-video-upload .rs-uploader-trigger-btn {
        &:after {
          content: ' a video';
        }
      }

      .btn-video-link {
        color: $SECONDARY_ORANGE_COLOR !important;
        transition: 0.2s ease-out all;
        background-color: inherit !important;
        cursor: pointer;

        padding: 0 0 0.25rem;
        border-bottom: 2px solid $SECONDARY_ORANGE_COLOR;

        @include hover-focus {
          color: $SECONDARY_ORANGE_HOVER_COLOR !important;
        }
      }
    }

    .video-webcam-wrapper {
      width: 100%;
      max-width: 100%;
      margin: 0 auto 1rem;

      .video-webcam {
        width: 100%;
      }
    }
  }

  .video-example-wrapper {
    width: 100%;

    &.sml {
      margin: 0 auto 1rem;
      width: 75%;
    }

    video {
      width: 100%;
    }
  }
}
