@import 'component-library/dist/scss/alloy';
.content-question-wrapper {
  .question-title {
    color: $PRIMARY_DARK_BLUE_COLOR;
    font-family: $FONT_SANGBLEU_REGULAR;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.375rem;

    margin: 0 0 0.5rem;
    padding: 0;

    @include media-breakpoint-down(md) {
      font-size: 1.375rem;
      line-height: 1.875rem;
    }
  }

  .doctor-name {
    font-weight: 600;
  }

  .question-text {
    color: $PRIMARY_DARK_BLUE_COLOR;
    font-family: $FONT_FUTURA_BOOK;
    font-size: 1rem;

    margin: 0 0 1rem;
    padding: 0;
  }

  .question-introduction {
    @extend .question-text;
    margin-top: 1rem;
  }

  .question-hr {
    margin: 1rem 0;
    border-top: 0.25px solid rgba(0, 0, 0, 0.16);
  }

  .question-img {
    display: block;
    width: 200px;
    max-width: 100%;
    text-align: center;

    margin: 0 auto 1rem;
  }

  &.slider-question {
    background-color: $WHITE_COLOR;
    margin-bottom: 1rem;
    padding: 1.5rem;

    .question-title {
      color: $PRIMARY_DARK_BLUE_COLOR;
      font-family: $FONT_FUTURA_BOLD;
      font-weight: 600;
      font-size: 1.375rem;
    }

    .slider-bottom-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .left-text,
      .right-text {
        color: $PRIMARY_DARK_BLUE_COLOR;
        font-size: 1.125rem;
        margin: 0;
        width: 30%;
      }

      .left-text {
        text-align: left;
      }

      .right-text {
        text-align: right;
      }
    }
  }

  &.content-markdown-wrapper {
    color: $PRIMARY_DARK_BLUE_COLOR;

    .field-checkbox {
      background-color: inherit;
      border: none;
      .checkbox-select-icon {
        width: 2rem;
        height: 2rem;
        margin: 0.4rem;
      }
    }

    p {
      margin: 0;
    }

    p + p {
      margin-top: toRem(20);
    }

    h6 {
      font-weight: normal;
      margin-top: toRem(20);
      text-align: left;
    }

    strong {
      font-family: $FONT_FUTURA_MEDIUM;
      font-weight: 450;
    }

    a {
      text-decoration: underline;
    }

    .input-field-wrapper {
      .field-checkbox {
        margin: toRem(20) 0;
        padding: 0;

        .checkbox-select-icon {
          margin: 0 toRem(8) 0 0;
        }
      }
    }
  }
}
