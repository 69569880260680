@import 'component-library/dist/scss/alloy';
.header-carousel-wrapper {
  .carousel-card-block {
    align-items: center;

    position: relative;

    .carousel-action {
      position: absolute;
      display: block;
      width: 60px;
      height: 60px;

      z-index: 10;

      background-color: $WHITE_COLOR;

      border: 1px solid $PRIMARY_DARK_BLUE_COLOR;

      &.action-left {
        left: -30px;
        top: 40%;
      }

      &.action-right {
        right: -30px;
        top: 40%;
      }

      &.disable {
        cursor: not-allowed;
        border: 1px solid rgba($PRIMARY_DARK_BLUE_COLOR, 0.3);

        .action-icon {
          opacity: 0.3;
        }
      }

      // carousel actions look a little funky on a certain width range so this helps clean that up
      @media screen and (max-width: 1240px) {
        width: 45px;
        height: 45px;

        &.action-left {
          left: -15px;
          top: 42.5%;
        }

        &.action-right {
          right: -15px;
          top: 42.5%;
        }
      }

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .carousel-cards-list {
      display: flex;

      .card-wrapper {
        display: none;

        width: 100%;

        &.active-card {
          display: block;
        }
      }
    }
  }

  .carousel-dot-block {
    display: flex;
    justify-content: center;

    margin: toRem(24) 0 toRem(20);

    .dot-icon {
      width: 10px;
      height: 10px;

      cursor: pointer;

      margin: 0 5px;

      background-color: inherit;
      border-radius: 50%;
      border: 1px solid $PRIMARY_LIGHT_BLUE_COLOR;

      &.active {
        background-color: $WHITE_COLOR;
        border: 1px solid $WHITE_COLOR;
      }
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}
