@import 'component-library/dist/scss/alloy';
.order-header-block {
  .order-header-content {
    padding: 0 0 toRem(32);
    border-bottom: 1px solid $PRIMARY_CARD_BORDER_COLOR;

    .header-content-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .content-title {
        font-family: $FONT_SANGBLEU_REGULAR;
        font-weight: 400;
        font-size: toRem(32);
        line-height: toRem(38);

        color: $PRIMARY_DARK_BLUE_COLOR;

        margin: 0 0 toRem(16);
        padding: 0;
      }

      .content-text {
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 450;
        font-size: toRem(24);
        line-height: toRem(28);

        color: $PRIMARY_DARK_BLUE_COLOR;

        margin: 0;
        padding: 0;

        .text-link {
          text-decoration: underline;

          cursor: pointer;
        }

        @include media-breakpoint-down(sm) {
          + .content-text {
            margin-top: toRem(8);
          }
        }
      }

      .chevron-btn {
        background-color: inherit;
        transition: 0.6s ease;

        margin: 0 0 toRem(16);
      }
    }
  }
}
