@import 'component-library/dist/scss/alloy';
.manage-shipping-block {
  .shipping-method-wrapper {
    display: flex;
    padding: toRem(24) 0;
    align-items: center;

    border-top: 1px solid $SECONDARY_BORDER_COLOR;
    border-bottom: 1px solid $SECONDARY_BORDER_COLOR;

    cursor: pointer;

    .shipping-title {
      flex: 1;
      font-family: $FONT_FUTURA_MEDIUM;
      font-size: 1rem;
      font-weight: 500;

      color: $PRIMARY_TEXT_COLOR;

      margin: 0;
      padding: 0;
    }

    .shipping-text {
      font-size: 1rem;
      line-height: 1.625rem;
      display: flex;
      max-width: 225px;

      color: $PRIMARY_TEXT_COLOR;

      margin: 0;
      padding: 0;

      .shipping-shortened-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      img {
        width: 1rem;
        margin-left: 0.5rem;
        transition: 0.6s ease;
      }
    }
  }

  .shipping-options-wrapper {
    display: none;

    .shipping-option {
      display: flex;
      align-items: center;

      padding: toRem(16) toRem(24);
      border: 1px solid $SECONDARY_BORDER_COLOR;
      border-radius: 0.375rem;

      margin-bottom: toRem(16);

      cursor: pointer;

      .option-content {
        flex: 1;

        .option-title {
          font-family: $FONT_FUTURA_MEDIUM;
          font-size: toRem(16);
          font-weight: 500;
          line-height: 1.625rem;

          margin: 0;
          padding: 0;
        }

        .option-price {
          font-family: $FONT_FUTURA_MEDIUM;
          font-size: 0.8125rem;
          font-weight: 500;
          line-height: 1.3125rem;
          letter-spacing: 0.0625rem;
          text-transform: uppercase;

          &.free {
            color: $PRIMARY_DARK_GREEN_COLOR;
          }

          margin: 0;
          padding: 0;
        }
      }

      .option-outer-circle {
        width: 1.5rem;
        height: 1.5rem;
        border: 1px solid $SECONDARY_BORDER_COLOR;
        border-radius: 50%;
      }

      &.selected {
        .option-outer-circle {
          width: 1rem;
          height: 1rem;

          border: 1px solid $SECONDARY_ORANGE_COLOR;
          background-color: $SECONDARY_ORANGE_COLOR;

          box-shadow:
            0px 0px 0px 4px $WHITE_COLOR,
            0px 0px 0px 6px $SECONDARY_ORANGE_COLOR;

          position: relative;
          right: 0.25rem;
        }
      }
    }
  }

  &.active-shipping {
    .shipping-method-wrapper {
      border-bottom: none;

      img {
        transform: rotate(180deg);
      }
    }

    .shipping-options-wrapper {
      display: block;
    }
  }
}
