@import 'component-library/dist/scss/alloy';
.tp-shipping-address-block {
  padding: 1rem 0 1rem;
  border-bottom: 1px solid $PRIMARY_BORDER_COLOR;

  display: flex;
  justify-content: space-between;

  cursor: pointer;

  .shipping-content {
    .shipping-title {
      font-family: $FONT_FUTURA_MEDIUM;
      font-weight: 500;
      font-size: 1.125rem;

      margin: 0 0 0.5rem;
      padding: 0;
    }

    .shipping-text {
      font-size: 1rem;

      margin: 0;
      padding: 0;
    }
  }

  .shipping-chevron {
    transform: rotate(-90deg);

    background-color: inherit;
  }
}
