@import 'component-library/dist/scss/alloy';
.total-price-text {
  font-family: $FONT_FUTURA_MEDIUM;
  font-weight: 500;
  font-size: toRem(18);

  margin: 0;
  padding: 0;

  .text-discounted-price {
    color: $SECONDARY_ORANGE_COLOR;

    margin-left: toRem(8);
  }

  .text-price {
    &.price-strikethrough {
      text-decoration: line-through;
    }
  }
}
