@import 'component-library/dist/scss/alloy';
.profile-help-block {
  text-align: center;

  .help-title {
    font-family: $FONT_FUTURA_BOLD;
    font-size: 1.375rem;
    font-weight: 600;
    color: $PRIMARY_DARK_BLUE_COLOR;
  }

  .help-text {
    font-size: 1.25rem;

    margin: 0;
    padding: 0;

    .primary-link-button {
      font-size: 1.25rem;

      &:hover {
        color: $PRIMARY_DARK_BLUE_COLOR;
        text-decoration: underline;
      }
    }
  }
}
