@import 'component-library/dist/scss/alloy';
.bundle-drawer {
  .drawer-body {
    display: grid;
    gap: toRem(20);

    .date-options-content-wrapper {
      display: grid;
      gap: toRem(24);

      border-bottom: 1px solid $PRIMARY_BORDER_COLOR;

      padding: toRem(24) 0 toRem(32);

      .content-title {
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 500;
        font-size: toRem(20);
        line-height: toRem(28);

        color: $PRIMARY_TEXT_COLOR;

        margin: 0;
        padding: 0;
      }
    }

    .products-summary-content-wrapper {
      background-color: $WHITE_COLOR;
      border: 1px solid $SECONDARY_BORDER_COLOR;

      display: grid;
      gap: toRem(24);

      padding: toRem(32) toRem(24);

      .products-list-wrapper {
        margin: 0;
      }

      .content-divider {
        height: 1px;
        background-color: $PRIMARY_BORDER_COLOR;
      }

      .summary-block {
        display: grid;
        gap: toRem(16);

        &.is-open {
          .summary-header-wrapper {
            .chevron-icon {
              transform: rotate(180deg);
            }
          }

          .summary-content-wrapper {
            display: grid;
          }
        }

        .summary-header-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;

          cursor: pointer;

          .summary-title {
            font-family: $FONT_FUTURA_MEDIUM;
            font-weight: 500;
            font-size: toRem(20);
            line-height: toRem(28);

            color: $PRIMARY_DARK_BLUE_COLOR;

            margin: 0;
            padding: 0;
          }

          .summary-text {
            font-size: toRem(20);
            line-height: toRem(26);

            color: $PRIMARY_DARK_BLUE_COLOR;

            margin: 0;
            padding: 0;

            display: flex;
            align-items: center;

            .chevron-icon {
              width: toRem(16);
              margin-left: toRem(12);
              transition: 0.6s ease;
            }
          }
        }

        .summary-content-wrapper {
          display: none;
          gap: toRem(8);

          .content-row {
            align-items: center;
            display: flex;

            .content-title {
              flex: 1;
            }

            .content-title,
            .content-text {
              margin: 0;
              padding: 0;

              color: $PRIMARY_TEXT_COLOR;

              font-size: toRem(16);
            }
          }
        }
      }
    }

    .informational-content-wrapper {
      display: grid;
      gap: toRem(8);

      border: 1px solid $PRIMARY_LIGHT_BLUE_COLOR;

      padding: toRem(16);

      .content-title {
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 500;
        font-size: toRem(20);
        line-height: toRem(28);

        color: $PRIMARY_DARK_BLUE_COLOR;

        margin: 0;
        padding: 0;
      }

      .content-text {
        font-size: toRem(20);
        line-height: toRem(26);

        color: $PRIMARY_DARK_BLUE_COLOR;

        margin: 0;
        padding: 0;
      }
    }
  }
}
