@import 'component-library/dist/scss/alloy';
.request-drawer {
  .drawer-body {
    &.dosage {
      padding-bottom: 5rem !important;
    }

    .request-content-wrapper {
      padding: 1.5rem 0;

      .content-title {
        color: $PRIMARY_DARK_BLUE_COLOR;
        font-family: $FONT_SANGBLEU_REGULAR;
        font-weight: 400;
        font-size: 2rem;
        line-height: 2.375rem;

        margin: 0 0 0.5rem;
        padding: 0;

        @include media-breakpoint-down(md) {
          font-size: 1.375rem;
          line-height: 1.875rem;
        }
      }

      .content-text {
        color: $PRIMARY_DARK_BLUE_COLOR;
        font-family: $FONT_FUTURA_BOOK;
        font-size: 1rem;

        margin: 0 0 1rem;
        padding: 0;
      }

      .input-field-wrapper {
        margin: 0 0 1rem;

        .dosage-textarea {
          @include media-breakpoint-down(md) {
            max-height: 15vh;
          }
        }
      }

      .content-consent-wrapper {
        display: flex;
        margin: 0;

        align-items: center;

        padding: 0.75rem;
        border: 1px solid $PRIMARY_LIGHT_BLUE_COLOR;

        cursor: pointer;

        .checkbox-icon {
          width: 1.5rem;
          height: 1.5rem;

          padding: 0;
          margin: 0;
        }

        .consent-text {
          color: $PRIMARY_DARK_BLUE_COLOR;
          font-family: $FONT_FUTURA_BOOK;
          font-size: 1rem;

          margin: 0 0 0 0.5rem;
        }

        .consent-link {
          @extend .consent-text;

          text-decoration: underline;
        }
      }

      .request-products-wrapper {
        .viewable-product {
          margin: 0 0 1.25rem;
        }
      }

      .request-products-buttons {
        display: flex;
        justify-content: space-between;

        .secondary-border-button {
          width: 47.5%;

          &.selected-button {
            background-color: $SECONDARY_ORANGE_COLOR;
            border-color: $SECONDARY_ORANGE_COLOR;
            color: $WHITE_COLOR;
          }

          @include media-breakpoint-down(sm) {
            font-size: toRem(12);
            padding: toRem(12) toRem(8);
          }
        }
      }
    }
  }

  .drawer-footer {
    position: fixed;
    background-color: $WHITE_COLOR;
    border-top: 1px solid $PRIMARY_BORDER_COLOR;
  }
}
