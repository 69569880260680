@import 'component-library/dist/scss/alloy';
.reschedule-wrapper {
  display: grid;
  gap: toRem(24);

  .reschedule-text {
    font-size: toRem(20);
    line-height: toRem(28);

    color: $PRIMARY_TEXT_COLOR;

    text-align: center;

    margin: 0;
    padding: 0;

    .text-link {
      background-color: inherit;
      text-decoration: underline;

      padding: 0;
    }
  }
}
