@import 'component-library/dist/scss/alloy';
.record-audio-modal {
  .modal-body {
    padding: toRem(32) toRem(48) toRem(64);

    .audio-modal-block {
      display: grid;
      gap: toRem(16);

      .modal-title {
        font-family: $FONT_FUTURA_BOLD;
        font-weight: bold;
        font-size: toRem(24);
        line-height: toRem(28);

        margin: 0;
        padding: 0;

        .text-underline {
          text-decoration: underline;
        }
      }

      .modal-text {
        font-size: toRem(18);

        margin: 0;
        padding: 0;
      }

      .modal-player-wrapper {
        max-width: 100%;

        audio {
          max-width: 100%;
        }
      }

      .modal-btns-block {
        display: flex;
        gap: toRem(16);

        justify-content: center;

        @include media-breakpoint-down(sm) {
          display: grid;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      padding: toRem(32) toRem(16) toRem(64);

      .audio-modal-block {
        .modal-btns-block {
          justify-content: normal;
        }
      }
    }
  }
}
