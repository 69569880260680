@import 'component-library/dist/scss/alloy';
.credit-card {
  margin: 0;
  padding: 0.75rem 1rem;
  width: 100%;
  background-color: $WHITE_COLOR;
  border-radius: 5px;
  border: 1px solid rgb(230, 230, 230);
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.03),
    0px 3px 6px rgba(0, 0, 0, 0.02);
  transition: all 0.4s ease-in;
  cursor: pointer;

  &.selected {
    border: 2px solid $SECONDARY_ORANGE_COLOR;
    cursor: default;
  }

  + .credit-card {
    margin-top: 1rem;
  }

  .credit-card-brand {
    margin-right: 10px;
  }

  .credit-card-last4 {
    font-family: $FONT_FUTURA_BOLD;
    font-size: 1.125rem;
    vertical-align: middle;

    margin: 0;
    padding: 0;

    &:before {
      content: '**** **** **** ';
      letter-spacing: 4px;
      color: #4f4d4d;
      font-size: 1.125rem;
      vertical-align: middle;
    }
  }

  .credit-card-expiry {
    font-size: 1rem;
    font-family: $FONT_FUTURA_BOLD;

    margin: 0;
    padding: 0;
  }

  &.american-express .credit-card-last4:before,
  &.amex .credit-card-last4:before {
    content: '**** ****** *';
    margin-right: -2px;
    vertical-align: middle;
  }

  &.diners-club .credit-card-last4:before,
  &.diners .credit-card-last4:before {
    content: '**** ****** ';
    vertical-align: middle;
  }

  &.visa {
    background: #392f5a;
    color: #eaeef2;
  }

  &.visa .credit-card-last4:before {
    color: #8999e5;
  }

  &.mastercard {
    background: #4f0cd6;
    color: #e3e8ef;
  }

  &.mastercard .credit-card-last4:before {
    color: #8a82dd;
  }

  &.american-express,
  &.amex {
    background: #1cd8b3;
    color: #f2fcfa;
  }

  &.american-express .credit-card-last4:before,
  &.amex .credit-card-last4:before {
    color: #99efe0;
  }

  &.diners,
  &.diners-club {
    background: #8a38ff;
    color: #f5efff;
  }

  &.diners .credit-card-last4:before,
  &.diners-club .credit-card-last4:before {
    color: #b284f4;
  }

  &.discover {
    background: #f16821;
    color: #fff4ef;
  }

  &.discover .credit-card-last4:before {
    color: #ffae84;
  }

  &.jcb {
    background: #cc3737;
    color: #f7e8e8;
  }

  &.jcb .credit-card-last4:before {
    color: #f28a8a;
  }

  &.unionpay {
    background: #47bfff;
    color: #fafdff;
  }

  &.unionpay .credit-card-last4:before {
    color: #99dcff;
  }
}
