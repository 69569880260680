@import 'component-library/dist/scss/alloy';
.navbar-main {
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

.nav-main {
  width: 100%;
  max-width: 100%;
  transition: all 0.2s ease-in-out;

  &.dark {
    background-color: $PRIMARY_DARK_BLUE_COLOR;
  }

  &.light {
    background-color: $WHITE_COLOR;

    .nav-link {
      color: $PRIMARY_DARK_BLUE_COLOR;
    }
  }
}

.dashboard-nav {
  display: flex;
  flex-direction: column; // cannot use `inherit` to get the `.navbar`s value
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  @include media-breakpoint-up(xl) {
    flex-direction: row;
  }

  .dashboard-nav-item {
    font-size: 0.75rem;
    font-family: $FONT_FUTURA_BOLD;
    line-height: 1.625rem;
    letter-spacing: 0.075rem;
    text-transform: uppercase;
    margin-left: 2rem;
    padding: 0 0 0.0625rem;
    transition: 0.2s ease-out all;
    border-bottom: 4px solid #fff;

    @include media-breakpoint-up(lg) {
      &.is-logout {
        margin-left: 1rem;
      }
    }

    &.selected {
      color: $PRIMARY_TEXT_COLOR;
      border-bottom: 2px solid #f6460b;
    }

    &:hover {
      transition: 0.2s ease-in all;
      color: $PRIMARY_TEXT_COLOR;
    }

    .dashboard-nav-link {
      color: $PRIMARY_DARK_BLUE_COLOR;

      &.orange {
        color: $SECONDARY_ORANGE_COLOR;
      }
    }

    @include media-breakpoint-down(lg) {
      margin: 0 auto 0 0;
      padding: 1rem 0;
      width: 100%;
      color: $PRIMARY_DARK_BLUE_COLOR;

      line-height: normal;
      letter-spacing: normal;

      border: 0;
      border-bottom: 1px solid $SECONDARY_BORDER_COLOR;

      &.selected {
        color: $PRIMARY_DARK_BLUE_COLOR;
        border-bottom: 1px solid $SECONDARY_BORDER_COLOR;
      }

      &.is-logout {
        border-bottom: none;
        margin-left: auto;
      }

      .dashboard-nav-link {
        width: 100%;

        font-family: $FONT_FUTURA_BOLD;
        font-size: 1.75rem;
        color: $PRIMARY_DARK_BLUE_COLOR;
      }
    }
  }
}

.dashboard-nav-notification {
  &:hover {
    cursor: pointer;
  }

  @include media-breakpoint-up(lg) {
    margin-left: 110px;
  }

  @include media-breakpoint-down(md) {
    margin: 0 toRem(16) 0 0;
  }

  .notification-link {
    border-radius: 50%;

    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    margin-left: 24px;
    padding-left: 1px; // weird svg export
  }

  .notification-dot {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 6px;
    height: 6px;
    background-color: $SECONDARY_ORANGE_COLOR;
    border-radius: 50%;
  }
}

@include media-breakpoint-down(lg) {
  #menu {
    display: block;
    z-index: 3000;
    transition: none;
    background: none;
    border-radius: 0;
    border: 0;
    outline: none;

    @include media-breakpoint-down(md) {
      position: inherit;
    }

    .menu-bars,
    .menu-bars:after,
    .menu-bars:before {
      display: block;
      width: 30px;
      height: 2px;
      border-radius: 2px;
      transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    }

    .menu-bars.dark,
    .menu-bars.dark:after,
    .menu-bars.dark:before {
      background: $WHITE_COLOR;
    }

    .menu-bars.light,
    .menu-bars.light:after,
    .menu-bars.light:before {
      background: $PRIMARY_DARK_BLUE_COLOR;
    }

    .menu-bars {
      &:before,
      &:after {
        content: '';
        position: absolute;
      }
      &:before {
        margin-top: -9px;
      }
      &:after {
        margin-top: 9px;
      }
    }
  }

  .navigation {
    background: $PRIMARY_DARK_BLUE_COLOR;
    position: fixed;
    top: 77px;
    left: 100%;
    height: 100%;
    width: 100%;
    display: block;
    z-index: 2000;
    text-align: left;
    color: $SECONDARY_ORANGE_COLOR;
    transition: ease 0.5s all;

    .navbar-nav {
      display: block;
      padding-top: 15px;

      .nav-item {
        display: block;

        a:not(.nav-start-assessment, .btn-link, .nav-item-dropdown-link) {
          padding: 15px 30px !important;
          text-align: left;
          font-size: toRem(20);
          line-height: normal;
          position: relative;
        }
      }
    }

    // MOBILE NAV - FULL HEIGHT
    @include media-breakpoint-down(lg) {
      background-color: $WHITE_COLOR;

      padding: 0 1rem;
    }
  }

  .openMenu {
    overflow-y: hidden;

    .navigation {
      left: 0;
    }

    #menu {
      .menu-bars {
        background: transparent;

        &:before {
          margin: 0;
          transform: rotate(45deg);
          background: $PRIMARY_DARK_BLUE_COLOR;
        }
        &:after {
          margin: 0;
          transform: rotate(-45deg);
          background: $PRIMARY_DARK_BLUE_COLOR;
        }
      }
    }
  }
}

.navbar-dark {
  background-color: $PRIMARY_DARK_BLUE_COLOR;

  .navbar-nav {
    .nav-link {
      color: $WHITE_COLOR;

      &.orange {
        color: $SECONDARY_ORANGE_COLOR;

        &:hover {
          color: #c5d8f8;
        }
      }

      @include hover-focus {
        color: $SECONDARY_ORANGE_COLOR;
      }
    }
  }
}
