@import 'component-library/dist/scss/alloy';
.ce-shipping-address-block {
  padding: 1rem 0 0;

  .input-field-wrapper,
  .mask-field-wrapper {
    margin: 0 0 1rem;

    .field-label {
      color: $PRIMARY_TEXT_COLOR;
      font-family: $FONT_FUTURA_MEDIUM;
      font-weight: 500;
      font-size: 0.9rem;
      letter-spacing: 0.0625rem;
      text-transform: uppercase;
      line-height: normal;

      margin-bottom: 0.5rem;
      padding: 0;
    }

    &.input-field-error {
      .field-input,
      .field-select {
        border: 1.5px solid red;
      }

      .field-required-label {
        font-size: 1rem;
        color: red;

        margin: 0.15rem 0 0;
        padding: 0;
      }
    }
  }

  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: toRem(16);
  }
}

.ce-shipping-info-block {
  padding: 1rem 0 0;

  &.show-shipping-method {
    padding: 1rem 0;
    border-bottom: 1px solid $PRIMARY_BORDER_COLOR;
  }

  .shipping-info-text {
    color: $PRIMARY_TEXT_COLOR;
    font-size: 1rem;

    margin: 0;
    padding: 0;
  }
}
