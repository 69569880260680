@import 'component-library/dist/scss/alloy';
.tp-checkout-payment-wrapper {
  background-color: $WHITE_COLOR;
  border: 1px solid $PRIMARY_BORDER_COLOR;
  padding: 2rem 4rem;

  margin: 0 0 1.5rem;

  .checkout-payment-title {
    font-family: $FONT_FUTURA_BOLD;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1rem;

    margin: 0;
    padding: 0 0 1rem;

    border-bottom: 1px solid $PRIMARY_BORDER_COLOR;
  }

  // Payment
  .tp-payment-block {
    border-bottom: none !important;
    padding: 1rem 0 0 !important;
  }

  @include media-breakpoint-down(xs) {
    padding: 1.5rem 1rem;

    border-left: none;
    border-right: none;
  }
}
