@import 'component-library/dist/scss/alloy';
.how-it-works-block {
  margin: 0 0 2.5rem;

  @include media-breakpoint-down(md) {
    margin-bottom: 0;
  }

  .hiw-container-wrapper {
    display: flex;

    .hiw-progress-wrapper {
      .progress-line {
        height: 100%;
        border: 1px dashed $PRIMARY_BORDER_COLOR;
        width: 1px;

        margin-left: 0.7rem;
      }

      .progress-tag {
        color: $PRIMARY_DARK_BLUE_COLOR;
        font-family: $FONT_FUTURA_BOLD;
        font-weight: 600;
        font-size: 0.75rem;

        width: 1.5rem;
        height: 1.5rem;

        text-align: center;

        background-color: $WHITE_COLOR;
        border: 1px solid $PRIMARY_DARK_BLUE_COLOR;
        border-radius: 50%;

        margin: 1.15rem 0 0;
        padding: 0;

        align-items: center;
        display: grid;
      }
    }

    .hiw-info-wrapper {
      &.info-bg {
        background-color: $WHITE_COLOR;
      }

      margin: 0 0 1rem 1rem;
      padding: 1rem;

      width: 100%;

      .info-title {
        color: $PRIMARY_DARK_BLUE_COLOR;

        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 450;
        font-size: 1.25rem;

        margin: 0 0 0.5rem;
        padding: 0;
      }

      .info-img {
        height: 4rem;

        margin: 0.5rem 0 1rem;
      }

      .info-text {
        color: $PRIMARY_DARK_GREY_COLOR;

        font-family: $FONT_FUTURA_BOOK;
        font-weight: 400;
        font-size: 1.125rem;

        margin: 0;
        padding: 0;
      }
    }
  }
}

.how-it-works-with-video {
  .expectations-video {
    padding: 1rem 0;
  }

  .how-it-works-block {
    padding: 1rem 0;
  }
}
