@import 'component-library/dist/scss/alloy';
.identity-block {
  margin: 1rem 0 0;

  border: 1px solid $PRIMARY_LIGHT_BLUE_COLOR;

  .identity-wrapper {
    color: $PRIMARY_DARK_BLUE_COLOR;

    padding: 1rem;

    border-bottom: 1px solid $PRIMARY_LIGHT_BLUE_COLOR;

    &:last-child {
      border-bottom: none;
    }

    .identity-title {
      font-family: $FONT_FUTURA_MEDIUM;
      font-weight: 500;
      font-size: 1.125rem;

      margin: 0;
      padding: 0;
    }

    .identity-text {
      font-size: 1rem;

      margin: 0;
      padding: 0;
    }

    .identity-bullets {
      padding-left: 1rem;
    }

    .identity-bullet-item {
      font-size: 1rem;
    }
  }
}

.identity-img-block {
  position: relative;

  .identity-img {
    width: 100%;
    margin-bottom: 1rem;

    &.sml {
      width: 80%;
    }
  }

  .identity-img-remove-icon {
    background-color: inherit;
    position: absolute;
    top: -20px;
    right: -20px;

    img {
      width: 40px;
      height: 40px;
    }

    @include media-breakpoint-down(md) {
      top: -5px;
      right: -5px;
    }
  }

  @include media-breakpoint-down(md) {
    .upload-id-img {
      padding: 1rem;
    }
  }
}
