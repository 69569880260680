@import 'component-library/dist/scss/alloy';
.features-block {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 2rem;

  &.dark-bg {
    background-color: $PRIMARY_LIGHT_BLUE_COLOR;
  }

  .feature-wrapper {
    text-align: center;
    justify-content: center;

    margin: 2.5rem 0 0;

    .feature-img {
      width: 2.5rem;
      height: 2.5rem;
    }

    .feature-text {
      color: $PRIMARY_DARK_BLUE_COLOR;

      font-family: $FONT_FUTURA_MEDIUM;
      font-weight: 450;
      font-size: 1rem;

      max-width: 8rem;

      margin: 0 auto;
      padding: 0;
    }

    @include media-breakpoint-down(md) {
      width: 50%;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }
}
