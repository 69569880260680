@import 'component-library/dist/scss/alloy';
.tp-total-summary-wrapper {
  padding: 0.5rem 0 0;

  .total-summary-row {
    display: flex;
    justify-content: space-between;

    margin: 0 0 0.5rem;

    .total-summary-title-bold {
      font-family: $FONT_FUTURA_MEDIUM;
      font-weight: 500;
      font-size: toRem(18);

      margin: 0;
      padding: 0;

      span {
        font-family: $FONT_FUTURA_BOOK;
        font-weight: 400;
        font-size: 1rem;
      }
    }

    .total-price-text {
      font-family: $FONT_FUTURA_MEDIUM;
      font-weight: 500;
      font-size: toRem(18);

      margin: 0;
      padding: 0;
    }

    .total-summary-title {
      margin: 0;
      padding: 0;
    }

    .total-summary-content {
      font-family: $FONT_FUTURA_MEDIUM;
      font-weight: 500;
      font-size: 1rem;

      margin: 0;
      padding: 0;
    }

    .total-summary-content-tax {
      font-family: $FONT_FUTURA_MEDIUM;
      font-weight: 500;
      font-size: 1rem;

      color: rgba($PRIMARY_TEXT_COLOR, 0.5);

      margin: 0;
      padding: 0;
    }
  }
}

.tp-total-wrapper {
  display: flex;
  justify-content: space-between;

  margin: 0 0 0.5rem;

  .total-title {
    font-family: $FONT_FUTURA_MEDIUM;
    font-weight: 500;
    font-size: 1.125rem;

    margin: 0;
    padding: 0;
  }

  .total-price-text {
    font-family: $FONT_FUTURA_MEDIUM;
    font-weight: 500;
    font-size: 1.125rem;

    margin: 0;
    padding: 0;
  }

  &.top-padding {
    padding: 0.5rem 0 0;
  }

  &.with-loader {
    padding: 1rem 0;

    .rs-loader-wrapper {
      margin: 0 auto;
    }
  }
}
