.ce-header-wrapper {
  margin: 0 0 1.5rem;

  .header-title {
    color: $PRIMARY_DARK_BLUE_COLOR;
    font-family: $FONT_SANGBLEU_REGULAR;
    font-weight: 400;
    font-size: 2rem;

    line-height: 1.1;

    margin: 0 0 1.5rem;
    padding: 0;
  }

  .header-blurb {
    color: $PRIMARY_TEXT_COLOR;
    font-size: 1.25rem;

    border: 1px solid $PRIMARY_LIGHT_BLUE_COLOR;

    margin: 0;
    padding: 0.5rem 1rem;
  }
}
