.profile-drawer,
.password-drawer {
  .profile-form-wrapper,
  .password-form-wrapper {
    padding: 1.5rem 0 0;

    .input-field-wrapper,
    .mask-field-wrapper {
      margin: 0 0 1rem;
    }
  }

  .profile-help-block {
    color: $PRIMARY_DARK_BLUE_COLOR;
    border: 1px solid $PRIMARY_DARK_BLUE_COLOR;
    text-align: center;

    margin: 2rem auto 1rem;
    padding: 1rem;

    max-width: 20rem;

    .help-title {
      font-family: $FONT_FUTURA_BOLD;
      font-size: 1.375rem;
      font-weight: 600;

      margin: 0;
      padding: 0;
    }

    .help-text {
      font-size: 1.25rem;

      .primary-link-button {
        font-size: 1.25rem;

        &:hover {
          color: $PRIMARY_DARK_BLUE_COLOR;
          text-decoration: underline;
        }
      }

      margin: 0;
      padding: 0;
    }

    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }
}
