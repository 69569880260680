@import 'component-library/dist/scss/alloy';
.tp-checkout-ship-wrapper {
  background-color: $WHITE_COLOR;
  border: 1px solid $PRIMARY_BORDER_COLOR;
  padding: 2rem 4rem;

  margin: 0 0 1.5rem;

  .checkout-ship-title {
    font-family: $FONT_FUTURA_BOLD;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1rem;

    margin: 0 0 1rem;
    padding: 0;
  }

  .checkout-ship-text {
    font-size: 1rem;

    margin: 0;
    padding: 0;

    .checkout-ship-checkmark {
      height: 1.25rem;
      width: 1.25rem;
      margin-right: 0.5rem;
      margin-bottom: 0.25rem;
    }

    &.bottom-padding {
      margin: 0;
      padding: 0 0 1rem;

      border-bottom: 1px solid $PRIMARY_BORDER_COLOR;
    }
  }

  // Shipping address
  .tp-shipping-address-block {
    padding: 1rem 0 !important;
  }

  // Shipping method
  .tp-shipping-method-wrapper {
    padding: 1rem 0 0 !important;
  }

  .tp-active-shipping {
    .tp-shipping-method-wrapper {
      padding: 1rem 0 !important;
    }
  }

  .tp-shipping-method-wrapper,
  .tp-shipping-options-wrapper {
    border-bottom: none !important;
  }

  @include media-breakpoint-down(xs) {
    padding: 1.5rem 1rem;

    border-left: none;
    border-right: none;
  }
}
