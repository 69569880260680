@import 'component-library/dist/scss/alloy';
.input-field-wrapper {
  &.input-field-error {
    .field-input,
    .field-select {
      border: 1.5px solid red;
    }

    .field-required-label {
      font-size: toRem(12);
      color: red;

      margin: toRem(8) 0 0;
      padding: 0;
    }
  }
}
