@import 'component-library/dist/scss/alloy';
.stats-block {
  background-color: $PRIMARY_LIGHT_BLUE_COLOR;
  background-image: url('../../../../shared/assets/svg/backgrounds/stats-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;

  padding: toRem(80) toRem(50) toRem(100);

  .stats-hug-text {
    font-family: $FONT_FUTURA_MEDIUM;
    font-weight: 450;
    font-size: toRem(28);
    line-height: toRem(36);

    color: $PRIMARY_DARK_BLUE_COLOR;

    margin: 0 0 toRem(20);
    padding: 0;
  }

  .stats-text {
    font-family: $FONT_SANGBLEU_BOLD;
    font-weight: bold;
    font-size: toRem(48);
    line-height: toRem(64);

    color: $PRIMARY_DARK_BLUE_COLOR;

    margin: 0;
    padding: 0;

    .text-italic {
      font-family: $FONT_SANGBLEU_EMPIRE_BLACK_ITALIC;
      font-weight: bold;
      font-style: italic;
    }
  }

  @include media-breakpoint-down(md) {
    background-image: url('../../../../shared/assets/svg/backgrounds/stats-bg-mobile.svg');

    margin: 0 0 toRem(24);
    padding: toRem(64) toRem(24);
  }
}
