@import 'component-library/dist/scss/alloy';
.heard-about-us-block {
  background-color: $WHITE_COLOR;

  margin: 0 0 1rem;
  padding: 1.5rem;

  .content-question-wrapper {
    .question-title {
      color: $PRIMARY_DARK_BLUE_COLOR;
      font-family: $FONT_FUTURA_BOLD;
      font-weight: bold;
      font-size: 1.375rem;
      margin: 0;
      padding: 0;
    }

    .question-text {
      color: $PRIMARY_TEXT_COLOR;
      font-size: 1.25rem;

      margin: 0 0 1rem;
      padding: 0;
    }

    .input-field-wrapper {
      .field-checkbox-group {
        .field-checkbox {
          border: 0 !important;
          padding: 0.2rem 0.25rem;

          .checkbox-text {
            color: $PRIMARY_TEXT_COLOR;
            font-size: 1.25rem;
          }
        }

        .field-checkbox-follow-up {
          .input-field-wrapper {
            .rs-input-group-inside {
              .rs-input-group-addon {
                color: $PRIMARY_TEXT_COLOR;
                font-size: 1.1rem;
                padding: 10px 15px;

                @include media-breakpoint-down(sm) {
                  text-wrap: wrap;
                  text-align: left;
                }
              }

              .field-input {
                padding-top: 35px;

                @include media-breakpoint-down(sm) {
                  padding-top: 50px;
                }
              }
            }
          }
        }
      }
    }
  }
}
