@import 'component-library/dist/scss/alloy';
.dashboard-treatment-plan-header-section {
  padding: 3rem 0;

  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;

  &.primary {
    background-color: $PRIMARY_LIGHT_BLUE_COLOR;
    background-image: url('../../../../assets/svg/hero-primary.svg');
  }

  &.secondary {
    background-color: $PRIMARY_BACKGROUND_COLOR;
    background-image: url('../../../../assets/svg/hero-secondary.svg');
  }

  .header-title {
    font-family: $FONT_SANGBLEU_REGULAR !important;
    font-size: toRem(38);
    font-weight: normal;
    color: $PRIMARY_DARK_BLUE_COLOR;
    line-height: 1;

    span {
      font-family: $FONT_SANGBLEU_EMPIRE_BLACK_ITALIC;
      font-weight: bold;
      font-style: italic;
      font-size: toRem(60);
    }

    @include media-breakpoint-down(md) {
      font-size: toRem(32);

      span {
        font-size: toRem(32);
      }
    }
  }

  .header-text {
    font-size: toRem(20);
    color: $PRIMARY_DARK_BLUE_COLOR;
  }

  .header-symptoms-block {
    background-color: $SECONDARY_LIGHT_BLUE_COLOR;
    border: 1px solid $PRIMARY_LIGHT_BLUE_COLOR;
    padding: toRem(16);

    .symptoms-title {
      font-size: toRem(16);
      font-family: $FONT_FUTURA_BOLD;
      font-weight: 700;
      line-height: toRem(20);
      text-transform: uppercase;
    }

    .symptoms-list {
      display: flex;
      flex-wrap: wrap;

      list-style: none;
      margin: 0;
      padding: 0;

      .symptom-item {
        margin: 0 toRem(16) toRem(8) 0;
        padding: 0;
        display: flex;
        align-items: center;

        .symptom-icon {
          width: toRem(24);
          height: toRem(24);
          margin-right: toRem(8);
        }

        .symptom-text {
          font-size: toRem(16);
        }
      }
    }

    .symptoms-link {
      font-size: toRem(16);
      text-decoration: underline;

      background-color: inherit;

      margin: 0;
      padding: 0;
    }
  }

  @include media-breakpoint-down(md) {
    padding-bottom: toRem(16);

    background-size: 85%;
    background-position: right top;

    &.primary {
      background-color: $PRIMARY_LIGHT_BLUE_COLOR;
      background-image: url('../../../../assets/svg/hero-mobile-primary.svg');
    }

    &.secondary {
      background-color: $PRIMARY_BACKGROUND_COLOR;
      background-image: url('../../../../assets/svg/hero-mobile-secondary.svg');
    }
  }
}

.dashboard-treatment-plan-section {
  background-color: $SECONDARY_LIGHT_BLUE_COLOR;
  padding: toRem(24) 0;

  .treatment-plan-container {
    display: grid;
    gap: toRem(26);
  }

  &.remove-md-top-padding {
    @include media-breakpoint-down(md) {
      padding-top: 0;
    }
  }

  &.tp-pending-approval-section {
    @include media-breakpoint-down(md) {
      padding: toRem(24) 0 0;
    }

    .tp-messaging-expiration-banner {
      background-color: $PRIMARY_DARK_BLUE_COLOR;
      color: $WHITE_COLOR;
      padding: toRem(4) toRem(16);

      margin: toRem(16) 0;
    }

    .tp-selected-treatments-section {
      margin-bottom: toRem(16);

      .selected-text {
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 600;
        font-size: toRem(20);
        letter-spacing: 0.9px;

        color: $PRIMARY_TEXT_COLOR;

        margin: 0;
        padding: 0;
      }

      .selected-info {
        color: $PRIMARY_TEXT_COLOR;
        font-size: toRem(20);

        margin: 0;
        padding: 0;
      }
    }

    .tp-order-summary-col {
      .ce-single-page-product-perks-wrapper {
        background-color: $SECONDARY_LIGHT_BLUE_COLOR;

        .product-list {
          .icon {
            background-color: #c5d8f8;
          }
        }
      }

      @include media-breakpoint-down(md) {
        .order-summary-block {
          .summary-text {
            .btn-primary {
              display: block;
            }
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {
      .tp-pending-approval-selection {
        padding: toRem(16) toRem(24);
      }
    }

    @include media-breakpoint-down(sm) {
      .container {
        max-width: 100%;
      }
    }
  }

  .checkout-col-container {
    @include media-breakpoint-down(xs) {
      padding: 0;
    }
  }

  &.extra-bottom-padding {
    padding: toRem(24) 0 toRem(96);

    @include media-breakpoint-down(md) {
      padding: toRem(24) 0;
    }
  }

  .tp-order-summary-col {
    padding: 0 toRem(16);

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  .tp-checkout-title,
  .tp-subtitle {
    color: $PRIMARY_DARK_BLUE_COLOR;
    font-family: $FONT_SANGBLEU_REGULAR !important;
    font-size: toRem(32);
    font-weight: normal;

    margin: 0 0 toRem(24);

    span {
      font-family: $FONT_SANGBLEU_EMPIRE_BLACK_ITALIC !important;
      font-weight: bold;
      font-style: italic;
    }
  }

  .tp-products-list-wrapper {
    display: grid;
    gap: toRem(16);

    .tp-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .tp-subtitle {
        font-size: toRem(22);
        margin: 0;
        padding: 0;
      }

      .tp-btn-wrapper {
        display: flex;
        gap: toRem(16);

        .tp-chevron-btn {
          background-color: inherit;
          transition: 0.6s ease;
        }
      }
    }

    .products-list {
      overflow: hidden;
      transition: all 0.3s ease;
      max-height: 0;

      display: none;

      .category-banner {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;

        background-color: $PRIMARY_LIGHT_BLUE_COLOR;
        background-image: url('../../../../assets/svg/hero-round-out.svg');
        padding: toRem(24);
        margin-bottom: toRem(16);
        border: 1px solid rgba(#000, 0.1);
        border-radius: toRem(4);

        span {
          font-family: $FONT_SANGBLEU_BOLD_ITALIC;
          font-style: italic;
          font-size: toRem(32);
          color: $PRIMARY_DARK_BLUE_COLOR;

          @include media-breakpoint-down(lg) {
            font-size: toRem(26);
          }

          @include media-breakpoint-down(sm) {
            font-size: toRem(20);
          }
        }
      }

      .btn-container {
        display: flex;
        gap: toRem(24);

        align-items: center;

        .products-text {
          font-family: $FONT_FUTURA_BOLD;
          font-weight: bold;
          font-size: toRem(15);
          line-height: toRem(17);

          color: $PRIMARY_DARK_BLUE_COLOR;

          text-transform: uppercase;

          margin: 0;
          padding: 0;

          .text-link {
            padding-bottom: toRem(4);
            border-bottom: 2px solid $PRIMARY_DARK_BLUE_COLOR;

            text-decoration: none;
          }
        }

        @include media-breakpoint-down(md) {
          display: grid;
          gap: toRem(16);

          text-align: center;
        }
      }
    }

    &.active {
      .tp-header {
        .tp-chevron-btn {
          transform: rotate(180deg);
        }
      }

      .products-list {
        max-height: max-content;

        display: grid;
        gap: toRem(16);

        .category-banner {
          background-repeat: no-repeat;
          background-size: cover;
          background-position: right;

          background-color: $PRIMARY_LIGHT_BLUE_COLOR;
          background-image: url('../../../../assets/svg/hero-round-out.svg');
          padding: toRem(24);
          margin-bottom: toRem(16);
          border: 1px solid $PRIMARY_CARD_BORDER_COLOR;
          border-radius: toRem(4);

          span {
            font-family: $FONT_SANGBLEU_BOLD_ITALIC;
            font-style: italic;
            font-size: toRem(32);
            color: $PRIMARY_DARK_BLUE_COLOR;

            @include media-breakpoint-down(lg) {
              font-size: toRem(24);
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .primary-button {
      overflow-x: none;

      width: 100%;

      margin-bottom: toRem(16);
    }
  }

  .header-state-block {
    .content-title {
      font-family: $FONT_SANGBLEU_REGULAR;
      font-weight: normal;
      font-size: toRem(32);
      line-height: toRem(38);
    }

    .content-subtitle {
      font-size: toRem(20);
      line-height: toRem(28);
    }
  }
}

.bottom-order {
  background-color: $WHITE_COLOR;
  padding: toRem(8);
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: 100;

  overflow-x: none;

  border-top: 1px solid $PRIMARY_LIGHT_BLUE_COLOR;

  .primary-button {
    font-size: toRem(16);
  }

  @include media-breakpoint-down(md) {
    text-align: center;

    .primary-button {
      width: 100%;
    }
  }
}
