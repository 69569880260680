@import 'component-library/dist/scss/alloy';
.dashboard-tp-info-section {
  background-color: $PRIMARY_LIGHT_BLUE_COLOR;
  padding: 2rem 0;

  .info-list {
    list-style: none;
    padding: 0;
    display: flex;

    justify-content: center;
    margin: 0 auto;

    .list-item {
      margin: 0 1rem;
      font-size: toRem(20);

      text-align: center;

      width: 300px;

      img {
        width: 20px;
        height: 20px;
        margin: 0 0.5rem 0.25rem 0;
      }

      p {
        line-height: 20px;
        padding: 0;
        margin: 0;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        margin: 1.5rem 0;
        display: flex;
        text-align: left;
      }
    }

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  @include media-breakpoint-down(md) {
    padding: 1rem 0;
  }
}
