@import 'component-library/dist/scss/alloy';
.av-sync-upload-wrapper {
  background-color: $WHITE_COLOR;

  border: 1px solid $PRIMARY_CARD_BORDER_COLOR;

  padding: toRem(32);

  display: grid;
  gap: toRem(24);

  @include media-breakpoint-down(sm) {
    padding: toRem(24);

    button {
      width: 100%;
    }
  }

  .upload-title {
    font-family: $FONT_FUTURA_BOLD;
    font-weight: bold;
    font-size: toRem(24);
    line-height: toRem(28);

    margin: 0;
    padding: 0;

    .text-underline {
      text-decoration: underline;
    }
  }

  .upload-text {
    font-size: toRem(18);

    margin: 0;
    padding: 0;
  }

  .file-content-block {
    background-color: inherit;
    border: 1px dashed $PRIMARY_CARD_BORDER_COLOR;
    border-radius: toRem(6);

    text-align: center;

    padding: toRem(36);

    .MuiChip-deleteIcon {
      margin: 0 10px 0 -6px;
    }
  }

  .upload-actions-block {
    display: flex;
    gap: toRem(24);

    .action-button {
      background-color: inherit;
      border: 1px dashed $PRIMARY_CARD_BORDER_COLOR;
      border-radius: toRem(6);

      padding: toRem(36);

      width: 100%;
      height: 100%;

      text-align: center;
      margin: auto;

      cursor: pointer;

      transition: 0.2s ease-out all;

      .action-icon {
        margin-right: toRem(6);
      }

      .action-text {
      }

      @include hover-focus {
        border: 1px dashed $PRIMARY_TEXT_COLOR;
      }
    }

    @include media-breakpoint-down(sm) {
      display: grid;
      gap: toRem(16);
    }
  }
}
