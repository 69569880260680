@import 'component-library/dist/scss/alloy';
.allergy-medication-content-wrapper {
  display: grid;
  gap: toRem(24);

  .allergy-medication-edit-block {
    display: grid;
    gap: toRem(16);

    .input-field-wrapper {
      margin: 0;
    }
  }

  .allergy-medication-view-block {
    background-color: $WHITE_COLOR;
    border: 1px solid $PRIMARY_BORDER_COLOR;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    padding: toRem(16);

    .view-content {
      .view-text {
        font-family: $FONT_FUTURA_BOOK;
        font-weight: normal;
        font-size: toRem(16);
        line-height: toRem(24);

        color: $PRIMARY_TEXT_COLOR;

        margin: 0;
        padding: 0;

        &.text-italic {
          font-style: italic;
        }
      }
    }

    .view-actions {
      display: flex;

      gap: toRem(16);

      .actions-divider {
        width: 1px;

        background-color: $PRIMARY_BORDER_COLOR;
      }
    }
  }

  .allergy-medication-divider {
    height: 1px;

    background-color: $PRIMARY_BORDER_COLOR;
  }

  .allergy-medication-link {
    font-family: $FONT_FUTURA_BOLD;
    font-weight: bold;
    font-size: toRem(14);
    line-height: toRem(16);

    background-color: inherit;
    color: $PRIMARY_DARK_BLUE_COLOR;

    text-transform: uppercase;

    margin: 0;
    padding: 0 0 toRem(4);

    display: flex;
    gap: toRem(4);
    align-items: center;

    .link-underline {
      border-bottom: 2px solid $PRIMARY_DARK_BLUE_COLOR;
    }
  }
}
