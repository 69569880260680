@import 'component-library/dist/scss/alloy';
.viewable-product {
  width: 100%;
  background-color: $WHITE_COLOR;
  padding: toRem(16);
  margin: 0 0 toRem(16);
  text-align: left;
  border: 1px solid $WHITE_COLOR;
  transition: 0.2s ease-out all;
  box-shadow: $CARD_BOX_SHADOW;

  position: relative;

  @include media-breakpoint-down(md) {
    padding: toRem(20);
  }

  &.highlighted-border {
    border: 1px solid $SECONDARY_ORANGE_COLOR;
  }

  .product-rx-tag {
    position: absolute;
    top: 0;
    left: 0;

    padding: toRem(2) toRem(8);
    color: $PRIMARY_DARK_BLUE_COLOR;
    background-color: $PRIMARY_LIGHT_BLUE_COLOR;

    text-transform: uppercase;
    z-index: 10;

    font-family: $FONT_FUTURA_BOLD;
    font-size: toRem(16);
  }

  .product-new-tag {
    position: absolute;
    top: 0;
    right: 0;

    padding: toRem(2) toRem(8);
    color: $WHITE_COLOR;
    background-color: $PRIMARY_DARK_GREEN_COLOR;

    border-radius: toRem(4);

    text-transform: uppercase;
    z-index: 10;

    font-family: $FONT_FUTURA_BOLD;
    font-size: toRem(16);

    @include media-breakpoint-down(md) {
      font-size: toRem(12);
    }
  }

  .product-header {
    display: flex;
    justify-content: space-between;

    margin-bottom: toRem(8);

    .header-content {
      display: flex;

      .header-img {
        width: 5.5rem;
        height: 5.5rem;

        @include media-breakpoint-down(sm) {
          width: 4rem;
          height: 4rem;
        }

        &.border-circle {
          border-radius: 50%;
        }
      }

      .header-text-wrapper {
        margin-left: toRem(16);

        .header-name-dosage {
          display: flex;
          align-items: center;
          gap: 10px;

          @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
            gap: toRem(4);
            margin-bottom: toRem(4);
          }

          .header-title {
            font-family: $FONT_FUTURA_MEDIUM;
            font-weight: 600;
            font-size: toRem(20);
            letter-spacing: 0.9px;

            color: $PRIMARY_TEXT_COLOR;

            margin: 0;
            padding: 0;

            @include media-breakpoint-down(sm) {
              font-size: toRem(16);
            }
          }

          .header-dosage {
            color: $PRIMARY_DARK_BLUE_COLOR;
            font-family: $FONT_FUTURA_BOLD;
            font-size: toRem(14);
            line-height: toRem(24);
            letter-spacing: 1.2px;

            background: $SECONDARY_BACKGROUND_COLOR;
            padding: toRem(2) toRem(8);
            border-radius: 0.25rem;
            text-transform: uppercase;

            @include media-breakpoint-down(sm) {
              font-size: toRem(11);
            }
          }
        }

        .header-blurb {
          background-color: $SECONDARY_LIGHT_BLUE_COLOR;
          color: $PRIMARY_DARK_BLUE_COLOR;

          font-family: $FONT_FUTURA_BOLD;
          font-weight: 600;
          font-size: toRem(16);

          text-transform: uppercase;

          padding: toRem(2) toRem(8);
          margin: toRem(4) 0;
        }

        .header-text {
          color: $PRIMARY_TEXT_COLOR;
          font-size: toRem(18);

          margin: 0;
          padding: 0;

          .text-discounted-price {
            color: $SECONDARY_ORANGE_COLOR;
          }

          .text-price {
            &.price-strikethrough {
              text-decoration: line-through;
            }
          }

          @include media-breakpoint-down(md) {
            font-size: toRem(16);
          }
        }
      }
    }

    .header-btn {
      background-color: inherit;
      color: $SECONDARY_ORANGE_COLOR;
      font-family: $FONT_FUTURA_BOLD;
      font-weight: 600;
      text-transform: uppercase;
      transition: 0.2s ease-out all;

      font-size: toRem(16);

      margin: 0;
      padding: 0;

      @include hover-focus {
        color: $SECONDARY_ORANGE_HOVER_COLOR;
      }
    }
  }

  .product-text {
    margin: 0;
    padding: 0;
  }

  .product-info-btn-link {
    background-color: inherit;
    color: $PRIMARY_DARK_BLUE_COLOR;

    text-decoration: underline;

    padding: 0;
    margin-top: toRem(8);
  }

  .product-action-btn {
    font-family: $FONT_FUTURA_BOLD;
    font-size: 0.8125rem;
    letter-spacing: 0.08125rem;
    text-transform: uppercase;
    opacity: 1;

    margin: toRem(16) 0 0;
    padding: 0.75rem 5rem;

    background-color: $WHITE_COLOR;
    color: $SECONDARY_ORANGE_COLOR;
    border: 1px solid $SECONDARY_BORDER_COLOR;

    @include media-breakpoint-down(sm) {
      padding: 0.75rem 2rem;
      width: 100%;
    }
  }

  .product-hug {
    background-color: $PRIMARY_DARK_BLUE_COLOR;
    color: $WHITE_COLOR;
    font-family: $FONT_FUTURA_BOLD;
    font-weight: 600;
    font-size: 0.9rem;

    text-transform: uppercase;

    margin: 0;
    padding: 0.25rem toRem(8);
  }
}
