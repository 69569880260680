@import 'component-library/dist/scss/alloy';
.ce-error-wrapper {
  padding: 0.5rem 1.5rem;
  margin-bottom: 10px;
  margin-top: 10px;

  background-color: rgba($RED_COLOR, 0.15);
  color: $PRIMARY_TEXT_COLOR;
  font-size: 1.1rem;

  border-left: 4px solid $RED_COLOR;

  .error-text {
    margin-bottom: 0;
  }

  .error-list {
    margin-bottom: 0;
  }
}
