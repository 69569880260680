@import 'component-library/dist/scss/alloy';
.referrals-header-block {
  background-color: $WHITE_COLOR;
  border: 1px solid $PRIMARY_BORDER_COLOR;

  .referrals-header-hero {
    background-color: $PRIMARY_LIGHT_BLUE_COLOR;
    background-image: url('../../assets/svg/hero.svg');
    background-position: right;
    background-repeat: no-repeat;

    padding: toRem(24) toRem(64);

    .hero-title {
      font-family: $FONT_SANGBLEU_REGULAR;
      font-weight: normal;
      font-size: toRem(32);
      line-height: toRem(38);

      color: $PRIMARY_DARK_BLUE_COLOR;

      margin: 0;
      padding: 0;

      span {
        font-family: $FONT_SANGBLEU_EMPIRE_BLACK_ITALIC;
        font-weight: bold;
        font-style: italic;
        font-size: toRem(48);
        line-height: toRem(64);
      }
    }

    @include media-breakpoint-down(sm) {
      background-image: url('../../assets/svg/hero-mobile.svg');
      background-position: bottom right;

      padding: toRem(24);

      .hero-title {
        font-size: toRem(22);
        line-height: toRem(30);

        span {
          font-size: toRem(32);
          line-height: toRem(38);
        }
      }
    }
  }

  .referrals-header-content {
    padding: toRem(40) toRem(64);

    .content-title {
      font-family: $FONT_FUTURA_BOLD;
      font-weight: bold;
      font-size: toRem(22);
      line-height: toRem(28);

      color: $PRIMARY_DARK_BLUE_COLOR;

      margin: 0 0 toRem(16);
      padding: 0;
    }

    .content-text {
      font-family: $FONT_FUTURA_BOOK;
      font-weight: normal;
      font-size: toRem(20);
      line-height: toRem(28);

      color: $PRIMARY_DARK_BLUE_COLOR;

      margin: 0 0 toRem(24);
      padding: 0;
    }

    .content-code-wrapper {
      width: 500px;
      max-width: 100%;

      text-align: center;

      margin: 0 auto;

      .code-text {
        font-family: $FONT_FUTURA_BOOK;
        font-weight: normal;
        font-size: toRem(14);
        line-height: toRem(22);

        color: $BLACK_COLOR;

        text-align: left;

        margin: 0 0 toRem(8);
        padding: 0;
      }

      .code-input {
        font-family: $FONT_FUTURA_BOOK;
        font-weight: normal;
        font-size: toRem(16);
        line-height: toRem(24);

        color: $PRIMARY_DARK_GREY_COLOR;
        border: 1px solid $PRIMARY_BORDER_COLOR;

        text-align: left;

        margin: 0 0 toRem(24);
        padding: toRem(12) toRem(14);
      }

      .primary-button {
        margin: 0 0 toRem(24);

        align-items: center;

        .button-icon {
          margin-right: toRem(5);

          width: 18px;
          height: 18px;
        }
      }

      .code-link {
        font-family: $FONT_FUTURA_BOOK;
        font-weight: normal;
        font-size: toRem(16);
        line-height: toRem(26);

        text-decoration: underline;

        color: $PRIMARY_DARK_BLUE_COLOR;

        margin: 0;
        padding: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      padding: toRem(40) toRem(24);
    }
  }
}
