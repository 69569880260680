@import 'component-library/dist/scss/alloy';
.edit-drawer {
  .drawer-header {
    border-bottom: 1px solid $PRIMARY_BORDER_COLOR;
  }

  .drawer-body {
    padding: 0 0 6rem !important;

    .edit-products-wrapper {
      display: grid;
      gap: toRem(16);

      padding: 1.5rem 1.5rem 0;

      .edit-text {
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 500;
        font-size: 1.125rem;
        color: $PRIMARY_DARK_BLUE_COLOR;

        margin: 0 0 1.5rem;
        padding: 0;
      }
    }

    .edit-divider {
      border-top: 1px solid $PRIMARY_LIGHT_BLUE_COLOR;
    }
  }

  .drawer-footer {
    bottom: 0;
    right: 0;
    z-index: 10;
    padding: 1rem 1.5rem;
    position: fixed;
    width: 100%;
    border-top: 1px solid $PRIMARY_BORDER_COLOR;

    text-align: center;

    background-color: $WHITE_COLOR;

    .primary-button {
      padding: 0.75rem 3.5rem;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }
}
