@import 'component-library/dist/scss/alloy';
.checkmark-list-block {
  display: grid;
  gap: toRem(16);

  .list-wrapper {
    display: flex;
    gap: toRem(16);

    align-items: center;

    .list-icon {
      width: 12px;
      height: 12px;
    }

    .list-text {
      font-size: toRem(18);
      line-height: toRem(26);

      color: $PRIMARY_DARK_BLUE_COLOR;

      margin: 0;
      padding: 0;
    }
  }
}
