@import 'component-library/dist/scss/alloy';
.popover-container {
  position: relative;

  .text-link {
    font-size: 0.75rem;
    font-family: $FONT_FUTURA_BOLD;
    line-height: 1.625rem;
    letter-spacing: 0.075rem;
    text-transform: uppercase;
    padding: 0;
    margin-top: 2rem;
    color: $PRIMARY_DARK_BLUE_COLOR !important;
    border-bottom: 2px solid $PRIMARY_DARK_BLUE_COLOR;
    background: none;

    &:hover {
      transition: 0.2s ease-in all;
      color: $PRIMARY_TEXT_COLOR;
    }
  }
}

.popover-content {
  position: fixed;
  background-color: $WHITE_COLOR;
  border: 1px solid $PRIMARY_BORDER_COLOR;
  padding: 32px 40px 40px 40px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 450px;
  width: max-content;
  max-height: 400px;
  margin-top: 18px;
  overflow: scroll;
  min-width: 400px;

  @include media-breakpoint-down(sm) {
    .popover-content {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      border-radius: 0;
      overflow-y: auto;
    }
  }
}
