@import 'component-library/dist/scss/alloy';
.address-drawer {
  .address-form-wrapper {
    padding: 1.5rem 0 0;

    .input-field-wrapper,
    .mask-field-wrapper {
      margin: 0 0 1rem;
    }

    .form-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: toRem(16);
    }
  }
}
