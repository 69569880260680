@import 'component-library/dist/scss/alloy';
.ce-selected-payment-block {
  padding: 1rem 0 0;

  .payment-method-wrapper {
    padding: 0 0 0.5rem;

    .payment-method-text {
      color: $PRIMARY_TEXT_COLOR;
      font-size: 1rem;

      margin: 0;
      padding: 0;
    }
  }
}
