@import 'component-library/dist/scss/alloy';
.header-info-card-block {
  .header-content-wrapper {
    border-bottom: 1px solid $PRIMARY_CARD_BORDER_COLOR;
    padding: 0 0 toRem(24);

    display: grid;
    gap: toRem(8);

    .content-block {
      display: flex;
      align-items: center;

      gap: toRem(24);

      &.with-spacing {
        justify-content: space-between;
      }

      .primary-button {
        border: 1px solid $SECONDARY_ORANGE_COLOR;
      }
    }

    @include media-breakpoint-down(md) {
      gap: toRem(12);
    }

    @include media-breakpoint-down(sm) {
      gap: toRem(16);

      .content-block {
        display: grid;
      }
    }
  }

  .header-content-wrapper,
  .info-content-wrapper {
    .content-block {
      .content-header-title {
        font-family: $FONT_SANGBLEU_REGULAR;
        font-weight: normal;
        font-size: toRem(32);
        line-height: toRem(38);

        color: $PRIMARY_DARK_BLUE_COLOR;

        margin: 0;
        padding: 0;
      }

      .content-text {
        font-size: toRem(20);
        line-height: toRem(28);

        color: $PRIMARY_TEXT_COLOR;

        margin: 0;
        padding: 0;

        .text-bold {
          font-family: $FONT_FUTURA_MEDIUM;
          font-weight: 500;
        }
      }

      .content-link {
        width: fit-content;
        text-align: left;

        font-family: $FONT_FUTURA_BOOK;
        font-weight: normal;
        font-size: toRem(20);
        line-height: toRem(28);

        background-color: inherit;
        color: $PRIMARY_TEXT_COLOR;

        text-decoration: underline;

        margin: 0;
        padding: 0;
      }
    }
  }

  .info-content-wrapper {
    padding: toRem(24) 0 0;

    display: flex;
    gap: toRem(116);

    .content-block {
      display: grid;
      gap: toRem(8);

      align-content: space-between;

      .content-combine {
        .content-text + .content-text {
          margin-top: toRem(8);
        }
      }
    }

    @include media-breakpoint-down(md) {
      display: grid;
      gap: toRem(24);
    }
  }

  .content-btns-block {
    display: flex;
    gap: toRem(16);

    @include media-breakpoint-down(sm) {
      display: grid;

      margin-top: toRem(36);

      button {
        width: 100%;
      }
    }
  }
}
