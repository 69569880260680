@import 'component-library/dist/scss/alloy';
.message-block {
  background-color: $WHITE_COLOR;

  margin: 0 0 1rem;
  padding: 1.5rem;

  .message-title {
    color: $PRIMARY_DARK_BLUE_COLOR;
    font-family: $FONT_FUTURA_BOLD;
    font-weight: bold;
    font-size: 1.375rem;

    margin: 0;
    padding: 0;
  }

  .message-text {
    color: $PRIMARY_TEXT_COLOR;
    font-size: 1.25rem;

    margin: 0 0 1rem;
    padding: 0;
  }
}
