@import 'component-library/dist/scss/alloy';
.active-product {
  width: 100%;
  background-color: $WHITE_COLOR;
  padding: 1rem;
  margin: 0;
  text-align: left;
  border: 1px solid $WHITE_COLOR;
  transition: 0.2s ease-out all;
  box-shadow: $CARD_BOX_SHADOW;

  position: relative;

  .product-rx-tag {
    position: absolute;
    top: 0;
    left: 0;

    padding: 0.125rem 0.5rem;
    color: $PRIMARY_DARK_BLUE_COLOR;
    background-color: $PRIMARY_LIGHT_BLUE_COLOR;

    text-transform: uppercase;
    z-index: 10;

    font-family: $FONT_FUTURA_BOLD;
    font-size: 1rem;
  }

  .product-header {
    display: flex;
    justify-content: space-between;

    margin-bottom: 0.5rem;

    .header-content {
      display: flex;

      .header-img {
        width: 5.5rem;
        min-width: 5.5rem;
        height: 5.5rem;

        @include media-breakpoint-down(sm) {
          width: 4rem;
          min-width: 4rem;
          height: 4rem;
        }

        &.border-circle {
          border-radius: 50%;
        }
      }

      .header-text-wrapper {
        margin-left: 1rem;

        .header-name-dosage {
          display: flex;
          align-items: center;
          gap: 10px;

          @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
            gap: 4px;
            margin-bottom: 4px;
          }

          .header-title {
            font-family: $FONT_FUTURA_MEDIUM;
            font-weight: 600;
            font-size: 1.25rem;
            letter-spacing: 0.9px;

            color: $PRIMARY_TEXT_COLOR;

            margin: 0;
            padding: 0;

            @include media-breakpoint-down(sm) {
              font-size: 1rem;
            }
          }

          .header-dosage {
            color: $PRIMARY_DARK_BLUE_COLOR;
            font-family: $FONT_FUTURA_BOLD;
            font-size: 0.875rem;
            line-height: 1.5rem;
            letter-spacing: 1.2px;

            background: $SECONDARY_BACKGROUND_COLOR;
            padding: 2px 8px 2px 8px;
            border-radius: 0.25rem;
            text-transform: uppercase;

            @include media-breakpoint-down(sm) {
              font-size: 0.7rem;
            }
          }
        }

        .header-text {
          font-size: 1.1rem;

          margin: 0;
          padding: 0;

          span {
            margin-right: 0.5rem;
          }

          .header-tag {
            background-color: $PRIMARY_DARK_GREEN_COLOR;
            color: $WHITE_COLOR;
            padding: 0.1rem 0.3rem;
            border-radius: 0.25rem;

            margin: 0;
          }

          @include media-breakpoint-down(md) {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .product-text {
    margin: 0;
    padding: 0;
  }

  .header-action {
    text-align: right;
  }

  .product-link {
    background-color: inherit;
    color: $PRIMARY_DARK_BLUE_COLOR;
    text-decoration: none;
    display: inline-flex;
    font-size: 1.1rem;

    padding: 0;

    .link-underline {
      text-decoration: underline;
    }
  }

  .product-special-blurb {
    background-color: $SECONDARY_LIGHT_BLUE_COLOR;

    margin: 1rem 0 0;
    padding: 0.5rem;
  }

  .product-hug {
    background-color: $PRIMARY_DARK_BLUE_COLOR;
    color: $WHITE_COLOR;
    font-family: $FONT_FUTURA_BOLD;
    font-weight: 600;
    font-size: 0.9rem;

    text-transform: uppercase;

    margin: 0;
    padding: 0.25rem 0.5rem;
  }

  .progress-block {
    padding: 0;
    margin: 1rem 0;

    .progress-text {
      color: $PRIMARY_DARK_BLUE_COLOR;
    }

    .progress-bar-text-wrapper {
      margin: 0;
    }
  }
}
