@import 'component-library/dist/scss/alloy';
.password-widget {
  margin-bottom: 1rem !important;

  .custom-password {
    list-style-type: none;
    padding: 0 0 0 10px;

    img {
      width: 15px;
      margin-right: 5px;
    }
  }

  .confirm-password {
    margin-top: 10px;
  }
}
