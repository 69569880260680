@import 'component-library/dist/scss/alloy';
.options-select-block {
  display: grid;
  gap: toRem(24);

  .content-option {
    display: flex;
    align-items: center;

    border-radius: 0.375rem;

    cursor: pointer;

    .option-outer-circle {
      width: toRem(24);
      height: toRem(24);
      background-color: $WHITE_COLOR;
      border: 1px solid $SECONDARY_BORDER_COLOR;
      border-radius: 50%;
    }

    .option-content {
      flex: 1;
      display: flex;
      margin: 0 0 0 toRem(8);
      justify-content: space-between;

      .option-title {
        font-family: $FONT_FUTURA_BOOK;
        font-weight: normal;
        font-size: toRem(20);
        line-height: toRem(28);

        color: $PRIMARY_TEXT_COLOR;

        margin: 0;
        padding: 0;
      }

      .option-text {
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 500;
        font-size: toRem(14);
        line-height: toRem(20);
        text-transform: uppercase;

        color: $PRIMARY_TEXT_COLOR;

        &.highlight-text {
          color: $PRIMARY_DARK_GREEN_COLOR;
        }

        margin: 0;
        padding: 0;
      }
    }

    &.selected {
      .option-outer-circle {
        width: toRem(16);
        height: toRem(16);

        border: 1px solid $SECONDARY_ORANGE_COLOR;
        background-color: $SECONDARY_ORANGE_COLOR;

        box-shadow:
          0px 0px 0px 4px $WHITE_COLOR,
          0px 0px 0px 6px $SECONDARY_ORANGE_COLOR;

        position: relative;
        left: toRem(4);
      }

      .option-content {
        position: relative;

        padding-left: toRem(8);
      }
    }
  }
}
