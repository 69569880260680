@import 'component-library/dist/scss/alloy';
.why-asking-wrapper {
  background-color: inherit;
  display: flex;
  align-items: center;
  margin: 0 0 1rem;
  padding: 0;

  img {
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
  }

  .why-asking-title {
    color: $PRIMARY_DARK_BLUE_COLOR;
    text-decoration: underline;
    font-size: 1rem;
    margin-left: 5px;
  }
}

.why-asking-content {
  font-size: 1.25rem;
  margin-top: 3rem;
}
