@import 'component-library/dist/scss/alloy';
.account-nav {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin: 0 0 toRem(40);
  list-style: none;
  gap: toRem(30);

  .account-nav-item {
    font-family: $FONT_FUTURA_MEDIUM;
    font-size: 1.175rem;
    line-height: 1.625rem;
    padding: 0 0 toRem(8);
    transition: 0.2s ease-out all;
    color: $PRIMARY_TEXT_COLOR;

    &.selected {
      border-bottom: 4px solid $SECONDARY_ORANGE_COLOR;
      opacity: 1;

      .item-link {
        color: $SECONDARY_ORANGE_COLOR;
      }
    }

    &:hover {
      transition: 0.2s ease-in all;
      color: $PRIMARY_TEXT_COLOR;
      opacity: 1;
    }
  }
}
