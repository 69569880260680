@import 'component-library/dist/scss/alloy';
.header-products-card-block {
  display: grid;
  gap: toRem(32);

  .header-content-wrapper {
    padding: 0 0 toRem(32);
    border-bottom: 1px solid $PRIMARY_CARD_BORDER_COLOR;

    .content-block {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .content-title {
        font-family: $FONT_SANGBLEU_REGULAR;
        font-weight: 400;
        font-size: toRem(32);
        line-height: toRem(38);

        color: $PRIMARY_DARK_BLUE_COLOR;

        margin: 0;
        padding: 0;

        &.disable {
          opacity: 0.5;
        }
      }

      .content-text {
        font-family: $FONT_FUTURA_BOOK;
        font-weight: normal;
        font-size: toRem(24);
        line-height: toRem(28);

        color: $PRIMARY_DARK_BLUE_COLOR;

        margin: 0;
        padding: 0;

        .text-link {
          text-decoration: underline;

          cursor: pointer;
        }

        .text-bold {
          font-family: $FONT_FUTURA_MEDIUM;
          font-weight: 450;
        }

        @include media-breakpoint-down(sm) {
          + .content-text {
            margin-top: toRem(8);
          }
        }
      }
    }

    .bundled-text-hug {
      display: inline-block;

      background-color: $SECONDARY_LIGHT_BLUE_COLOR;
      color: $PRIMARY_TEXT_COLOR;

      font-family: $FONT_FUTURA_BOLD;
      font-weight: bold;
      font-size: toRem(16);

      text-transform: uppercase;
      max-width: fit-content;
      border-radius: toRem(4);
      line-height: normal;

      margin: toRem(16) 0 0;
      padding: toRem(4) toRem(8);
    }

    .action-warning-text {
      display: inline-block;

      color: $SECONDARY_ORANGE_COLOR;

      font-family: $FONT_FUTURA_BOLD;
      font-weight: bold;
      font-size: toRem(16);

      margin: toRem(16) 0 0;
    }
  }

  .products-list-wrapper {
    margin: 0;
  }

  @include media-breakpoint-down(sm) {
    button {
      width: 100%;
    }
  }
}
