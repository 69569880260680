@import 'component-library/dist/scss/alloy';
.manage-summary-card-block {
  display: grid;
  gap: toRem(24);

  .card-title {
    font-family: $FONT_SANGBLEU_REGULAR;
    font-size: toRem(24);
    line-height: toRem(32);

    color: $PRIMARY_DARK_BLUE_COLOR;

    margin: 0;
    padding: 0;
  }

  .summary-content-wrapper {
    display: grid;
    gap: toRem(8);

    width: 40%;
    max-width: 100%;

    .content-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .content-title {
        font-size: toRem(16);
        line-height: toRem(24);

        color: $PRIMARY_TEXT_COLOR;

        margin: 0;
        padding: 0;
      }

      .content-text,
      .total-price-text {
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 450;
        font-size: toRem(16);
        line-height: toRem(24);

        color: $PRIMARY_TEXT_COLOR;

        margin: 0;
        padding: 0;
      }

      .text-bold {
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 500;
        font-size: toRem(18);
        line-height: toRem(26);
      }

      .text-grey {
        font-family: $FONT_FUTURA_BOOK;
        font-weight: normal;

        color: $PRIMARY_DARK_GREY_COLOR;
      }
    }
  }
}
