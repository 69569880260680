@import 'component-library/dist/scss/alloy';
.notification-drawer {
  .drawer-body {
    padding: 1.5rem 0;

    .content-title {
      color: $PRIMARY_DARK_BLUE_COLOR;
      font-family: $FONT_SANGBLEU_REGULAR;
      font-weight: 400;
      font-size: toRem(40);
      line-height: 2.375rem;

      margin: 0;
      padding: 1rem 0;

      @include media-breakpoint-down(md) {
        font-size: toRem(22);
        line-height: 1.875rem;
      }
    }

    .notifications-container {
      background-color: $WHITE_COLOR;
      border: 1px solid $PRIMARY_BORDER_COLOR;
      padding: 32px 40px 40px 40px;
      margin-top: 24px;

      @include media-breakpoint-down(sm) {
        border: none;
        padding: 24px;
        margin-top: 0;
      }
    }
  }
}
