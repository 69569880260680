@import 'component-library/dist/scss/alloy';
.top-blog-block {
  background-color: $PRIMARY_BACKGROUND_COLOR;
  color: $PRIMARY_TEXT_COLOR;
  padding: 1.5rem;

  display: block;

  text-decoration: none !important;

  cursor: pointer;

  @include hover-focus {
    color: $PRIMARY_TEXT_COLOR;
  }

  .blog-photo {
    width: 100%;

    margin: 0 0 1.5rem;

    &.sml-photo {
      object-fit: cover;
      height: 200px;
      max-height: 100%;
    }
  }

  .blog-tag {
    color: $SECONDARY_ORANGE_COLOR;
    font-family: $FONT_FUTURA_MEDIUM;
    font-weight: 500;
    font-size: 0.8125rem;

    text-transform: uppercase;

    margin: 0 0 1rem;
    padding: 0;
  }

  .blog-title {
    font-family: $FONT_SANGBLEU_REGULAR;
    font-size: 1.375rem;

    margin: 0 0 0.5rem;
    padding: 0;
  }

  .blog-author {
    font-size: 1.25rem;

    margin: 0 0 1.5rem;
    padding: 0;
  }

  .secondary-border-button {
    width: 100%;
  }
}
