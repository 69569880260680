@import 'component-library/dist/scss/alloy';
.dashboard-home-header-section {
  padding: 3rem 0;

  background-color: $PRIMARY_DARK_BLUE_COLOR;

  .header-title {
    font-family: $FONT_SANGBLEU_EMPIRE_BLACK_ITALIC;
    font-size: toRem(60);
    font-weight: bold;
    font-style: italic;

    color: $WHITE_COLOR;
    line-height: toRem(60);

    margin: 0 0 toRem(16);
  }

  .header-text {
    font-size: toRem(20);
    color: $WHITE_COLOR;

    line-height: toRem(28);
  }
}
