@import 'component-library/dist/scss/alloy';
.order-content-block {
  .order-content {
    overflow: hidden;
    transition: all 0.3s ease;
    max-height: 0;

    .content-header {
      display: block;
      padding-bottom: toRem(16);
      border-bottom: 1px solid rgb(#000, 0.16);

      .header-title {
        font-family: $FONT_FUTURA_MEDIUM;
        font-weight: 500;
        font-size: toRem(18);
        line-height: toRem(26);

        color: $PRIMARY_TEXT_COLOR;

        margin: 0;
        padding: 0;
      }

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .content-details {
      padding-top: toRem(32);

      .content-wrapper {
        .content-title {
          font-family: $FONT_FUTURA_MEDIUM;
          font-weight: 500;
          font-size: toRem(18);
          line-height: toRem(26);

          color: $PRIMARY_TEXT_COLOR;

          margin: 0;
          padding: 0;

          &.hide-large {
            display: none;

            @include media-breakpoint-down(sm) {
              display: block;
            }
          }

          @include media-breakpoint-down(sm) {
            margin-bottom: toRem(16);
          }
        }

        .content-text {
          font-family: $FONT_FUTURA_BOOK;
          font-weight: normal;
          font-size: toRem(16);
          line-height: toRem(26);

          color: $PRIMARY_TEXT_COLOR;

          margin: 0;
          padding: 0;

          &.text-bold {
            font-family: $FONT_FUTURA_MEDIUM;
            font-weight: 500;
          }

          &.list-text {
            display: flex;
            justify-content: space-between;
          }

          &.text-link {
            font-family: $FONT_FUTURA_MEDIUM;
            font-weight: 450;
            text-decoration: underline;
            font-size: toRem(18);

            background-color: inherit;

            cursor: pointer;
          }

          .text-price-wrapper {
            .text-discounted-price {
              color: $SECONDARY_ORANGE_COLOR;

              margin-left: toRem(8);
            }

            .text-price {
              &.price-strikethrough {
                text-decoration: line-through;
              }
            }
          }
        }

        .content-text-bold {
          color: $PRIMARY_TEXT_COLOR;

          margin: 0;
          padding: 0;
        }

        &.list-wrapper {
          display: flex;
          gap: toRem(72);

          @include media-breakpoint-down(sm) {
            display: block;

            .content-text-wrapper {
              margin-bottom: toRem(24);
            }
          }
        }

        @include media-breakpoint-down(sm) {
          margin-bottom: toRem(24);
        }
      }

      @include media-breakpoint-down(sm) {
        padding-top: toRem(18);
      }
    }
  }
}
