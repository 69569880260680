@import 'component-library/dist/scss/alloy';
.ce-payment-wrapper {
  background-color: $WHITE_COLOR;
  border: 1px solid $PRIMARY_BORDER_COLOR;
  padding: 2rem 4rem;

  margin: 0 0 1.5rem;

  .ce-payment-billing-block {
    margin: toRem(24) 0 0;
    padding: toRem(24) 0 0;
    border-top: 1px solid $PRIMARY_BORDER_COLOR;
  }

  @include media-breakpoint-down(xs) {
    padding: 1.5rem 1rem;

    border-left: none;
    border-right: none;
  }
}
